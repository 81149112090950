import _ from 'lodash'

export default {
  computed: {
    label: function () {
      return this.specification.label;
    },
    reference: function () {
      return this.specification.reference;
    },
    helpText: function () {
      return this.specification.help_text;
    },
    hasHelpText: function () {
      return !_.isEmpty(this.helpText);
    },
    isRequired: function () {
      return !!this.specification.required;
    }
  }
}