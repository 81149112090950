<template>
  <div id="drawing-settings-panel" v-show="!addingMode">

    <div class="widget-box">
      <div class="widget-title" v-on:click="openFilters">
        <h5 class="toggle-header">
          Filters
          <i :class="['icon', filtersUnrolled ? 'icon-chevron-down' : 'icon-chevron-up']" title="Click to toggle"></i>
        </h5>
      </div>
      <div id="drawing-filters" :class="['widget-content', 'collapsible', 'filters-toggle-target', filtersUnrolled ? 'expanded' : '']" @transitionend="transitionend">
        <drawing-filters
          :solutionsFieldList="solutionsFieldList"
          :solutionsFilter="solutionsFilter"
          :assignedToList="assignedToList"
          @update:showSolutions="val => $emit('update:showSolutions', val)"
          @update:filter="val => updateInspectionFilters(val)"
        />
      </div>
    </div>

    <div class="widget-box">
      <div class="widget-title" v-on:click="openBalloonList">
        <h5 class="toggle-header">
          Find Text Balloons
          <i :class="['icon', balloonListUnrolled ? 'icon-chevron-down' : 'icon-chevron-up']" title="Click to toggle"></i>
        </h5>
      </div>
        <div :class="['widget-content', 'collapsible', 'balloon-list-toggle-target', balloonListUnrolled ? 'expanded' : '']" @transitionend="transitionend">
          <drawing-balloon-list
            :textBalloons="textBalloons"
            :currentSelectedAnnotation="currentSelectedAnnotation"
            @update:balloonSelected="val => $emit('update:balloonSelected', val)"
            @update:findOrigin="val => $emit('update:findOrigin', val)"
          />
        </div>
    </div>

    <div class="widget-box">
      <div class="widget-title"  v-on:click="openDrawingSettings">
        <h5 class="toggle-header">
          Drawing Settings
          <i :class="['icon', drawingSettingsUnrolled ? 'icon-chevron-down' : 'icon-chevron-up']" title="Click to toggle"></i>
        </h5>
      </div>
      <div :class="['widget-content', 'collapsible', 'settings-toggle-target', drawingSettingsUnrolled ? 'expanded' : '']" @transitionend="transitionend">
        <drawing-settings
          :drawingSettings="drawingSettings"
          :colorSchemes="colorSchemes"
          :saveSettingsResult="saveSettingsResult"
          :noSave="!allowSaveSettings"
          @updateSettings="val => $emit('updateSettings', val)"
          @saveSettings="val => $emit('saveSettings', val)"
          @update:filter="val => updateTypeFilters(val)"
        />
      </div>
    </div>

  </div>
</template>

<script>
  import _ from 'lodash'
  import DrawingFilters from "./drawing_filters"
  import DrawingSettings from "./drawing_settings"
  import DrawingBalloonList from "./drawing_balloon_list"

  export default {
    components: {
      DrawingBalloonList,
      DrawingSettings,
      DrawingFilters,
    },
    props: {
      showAnnotations: Array,
      textBalloons: Array,
      currentSelectedAnnotation: String,
      addingMode: Boolean,
      solutionsFieldList: Object,
      solutionsFilter: Array,
      assignedToList: Array,
      drawingSettings: Object,
      colorSchemes: Object,
      readOnly: Boolean,
      allowSaveSettings: Boolean,
      saveSettingsResult: Boolean,
    },
    data: function () {
      return {
        balloonListUnrolled: false,
        drawingSettingsUnrolled: false,
        filtersUnrolled: true,

        inspectionFilters: () => true,
        typeFilters: () => true,
      };
    },
    watch: {
      filtersUnrolled: function(val) {
        let vm = this;
        _.forEach(document.getElementsByClassName('filters-toggle-target'), (el) => vm.toggle(el, val))
      },
      balloonListUnrolled: function(val) {
        let vm = this;
        _.forEach(document.getElementsByClassName('balloon-list-toggle-target'), (el) => vm.toggle(el, val))
      },
      drawingSettingsUnrolled: function(val) {
        let vm = this;
        _.forEach(document.getElementsByClassName('settings-toggle-target'), (el) => vm.toggle(el, val))
      },
    },
    methods: {
      openFilters: function() {
        this.balloonListUnrolled = false;
        this.drawingSettingsUnrolled = false;
        this.filtersUnrolled = !this.filtersUnrolled
      },
      openBalloonList: function() {
        this.filtersUnrolled = false;
        this.drawingSettingsUnrolled = false;
        this.balloonListUnrolled = !this.balloonListUnrolled
      },
      openDrawingSettings: function() {
        this.filtersUnrolled = false;
        this.balloonListUnrolled = false;
        this.drawingSettingsUnrolled = !this.drawingSettingsUnrolled
      },
      updateInspectionFilters: function(val) {
        this.inspectionFilters = val;
        let predicate = _.overEvery(this.typeFilters, val);
        this.$emit('update:filter', predicate)
      },
      updateTypeFilters: function (val) {
        this.typeFilters = val;
        let predicate = _.overEvery(val, this.inspectionFilters);
        this.$emit('update:filter', predicate)
      },
      toggle: function(el, state) {
        el.style.height=el.scrollHeight+'px';
        if (!state) {
          setTimeout(()=> {el.style.height='';}, 1); // timeout needed to start the collapse transition
        }
      },
      transitionend: function(evt) {
        // remove the height setting to allow browser to dynamically update it for resize etc
        evt.target.style.height='';
      },
    }
  }
</script>

<style scoped lang="scss">
  .toggle-header {
    width: 100%; 
    box-sizing: border-box; 
    display: flex; 
    justify-content: space-between
  }
  .widget-content {
    padding-bottom:0; 
    padding-top:0;
  }
  .widget-content > * {
    padding-bottom:12px; 
  }
  .widget-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
  .collapsible {
    height:0;
    overflow-y: hidden;
    transition: all 0.5s ease-in-out;
  }
  .expanded {
    height: auto;
  }
</style>