<template>
  <fieldset class="guidance-field" v-show="!isRow">
    <div v-show="guidanceVisible" v-if="styleAvailable" class="guidance-heading" v-bind:style="{backgroundColor: guidanceBackground}">
      <legend class="guidance-legend" v-bind:style="{color: guidanceColor}"><a v-on:click="toggle" v-bind:style="{color: guidanceColor}" v-if="isRollUpStart"><i  :class="['icon', iconClass]" title="Click to toggle"></i></a> {{ label }}</legend>
      <p class="help-block"v-bind:style="{color: guidanceColor}" v-if="hasHelpText">{{ helpText }}</p>
    </div>
    <div v-show="guidanceVisible" v-else class="guidance-heading" >
      <legend class="guidance-legend" ><a v-on:click="toggle" v-if="isRollUpStart"><i  :class="['icon', iconClass]" title="Click to toggle"></i></a> {{ label }}</legend>
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
    </div>
  </fieldset>
</template>

<script>
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldVisibilityMixin from '../../mixins/field_visibility_mixin'
  import { EventBus } from "../../lib/event_bus";

  export default {
    mixins: [fieldMixin, fieldLabelMixin, fieldVisibilityMixin],
    data: function () {
      let initialRolledUp = this.guidanceRollupState();
      return {
        styleObject: {},
        rolledUp: initialRolledUp
      }
    },
    computed: {
      styleAvailable: function() {
        if (!!this.specification.style) {
          this.styleObject = this.styleObjectToUse();
        };
        return !!this.specification.style;
      },
      guidanceColor: function() {
        return this.styleObject['color'];
      },
      guidanceBackground: function() {
        return this.styleObject['background-color'];
      },
      isRollUp: function() {
        return !!this.specification.rollup;
      },
      isRollUpStart: function() {
        return (this.isRollUp && this.specification.rollup == 'start')
      },
      isRollUpStop: function() {
        return (this.isRollUp && this.specification.rollup == 'stop')
      },
      guidanceType: function () {
        if (!!this.specification.rollup) {
          return this.specification.rollup;
        } else {
          return 'normal';
        }
      },
      selfControl: function () {
        return (this.isRollUp && (this.reference == this.guidanceFieldReference()));
      },
      isVisible: function () {
        // A guidance field cannot hide itself when rolled up.
        let localVisible = !this.isHidden;
        if (!this.isRollUp) {
          // It is a field likw any other
          localVisible = localVisible && this.visibleUnderRollup;
        }
        return localVisible;
      },
      guidanceVisible: function () {
        let test = this.guidanceHidden;
        return this.isVisible ; // && !this.selfControl;
      },
      iconClass: function () {
        return this.rolledUp ? 'icon-chevron-right' : 'icon-chevron-down'
      },
      guidanceHidden: function () {
        if (this.isRollUpStart && ((this.controlShowHide == 'hide') || (this.controlShowHide == 'show'))) {
          let eventData = { 'rollup': !this.rolledUp, 'groupShowHide': this.controlShowHide };
          EventBus.$emit(this.underRollup, eventData); 
        } ;
        return this.controlShowHide;
      }
    },
    methods: {
      toggle: function (e) {
        e.preventDefault();

        this.rolledUp = !this.rolledUp;
        let eventData = { 'rollup': !this.rolledUp, 'groupShowHide': this.groupShowHide }
        EventBus.$emit(this.underRollup, eventData);
      },
      guidanceRollupState: function () {
        let collapsedState = false;
        if (!!this.specification.rollup) {
          if (!!this.specification.style) {
            let localStyle = this.styleObjectToUse();
            collapsedState = (localStyle['collapsed'] == 'true') ? true : false ;
          }
        }
        return collapsedState;
      },
      styleObjectToUse: function () {
        var newStyleObject = {};
        if (!!this.specification.style) {
          newStyleObject = this.styleStringToObject(this.specification.style);
        } 
        return newStyleObject;
      },
      styleStringToObject: function (inputString) {
        var workingArray = _.split(_.replace(inputString,/\s/g,''), ",");
        var outputObject = {};
        _.forEach(workingArray,function(o,i) { workingArray[i] = _.split(o,':')});
        _.forEach(workingArray,function(o,i) { outputObject[o[0]]=o[1] }) ;
        return outputObject;
      }
    },
    created: function () {
      let vm = this;
      if (!!!this.specification.rollup && !!vm.underRollup){
        // If normal guidance can be under a rollup
        EventBus.$on(vm.underRollup, (eventData) => {
          vm.visibleUnderRollup = eventData['rollup'];
          let thisShowHide = eventData['groupShowHide'];
          if (!!thisShowHide) {
            vm.groupShowHide = thisShowHide;
          }
        });
      };
      if (!!vm.controlledField){
        EventBus.$on(vm.controlEvent, (ShowHideData) => {
          let thisShowHide = ShowHideData[vm.reference];
          if (!!thisShowHide) {
            vm.controlShowHide = thisShowHide;
          }
        });
      };
    }
  }
</script>

<style scoped lang="scss">
.guidance-heading {
  margin-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  width: 620px;
  padding-left: 0.5em;  
}
.guidance-legend {
  border-bottom: 0px;  
  margin-bottom: 5px;
  line-height: 35px;
  font-size: 20px;
}
</style>