import _ from 'lodash'

const type = 'date';
const DateFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  function parse(value) {
    let output = null;
    let messages = [];

    let parsedDate = Date.parse(value);

    if (_.isNaN(parsedDate)) {
      messages.push("Value must be a valid date");
    } else {
      let date = new Date(parsedDate);
      output = {
        type: type,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    }
  
    return {
      data: output,
      messages: messages,
    };
  };

  return {
    type,
    reference: spec.reference,
    parse: parse,
  }
}

export const DateField = {
  type,
  create: (specification) => DateFieldImpl(specification),
}
