import * as base from "./shape"

export const Arrow = (annotation) => {
  var shape = base.Shape(annotation);
  var utils = base.ShapeUtils();

  var annotation_data = annotation.annotation_data;

  var start = { x: 0, y: 0, data: annotation_data.origin };
  var end = { x: 0, y: 0, data: annotation_data.end_point };

  var head_tip;
  var head_end_1;
  var head_end_2;

  var line_width;

  var hot_spot_radius;

  function init(layoutParams) {
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    start.x = annotation_data.origin.x * overallSize.width - offset.x;
    start.y = annotation_data.origin.y * overallSize.height - offset.y;
    end.x = annotation_data.end_point.x * overallSize.width - offset.x;
    end.y = annotation_data.end_point.y * overallSize.height - offset.y;

    line_width = utils.strokeWidth(annotation_data.stroke_scale, overallSize.height);

    let dx = start.x - end.x;
    let dy = start.y - end.y;
    let line_length = Math.sqrt( dx **2 + dy **2);

    // assuming the head forms a right-angle
    let origin_shift = 1.4142 * line_width / 2;
    let change_scale = origin_shift / line_length;

    let start_offset_x = start.x + (end.x - start.x) * change_scale;
    let start_offset_y = start.y + (end.y - start.y) * change_scale;

    dx = (start_offset_x - end.x) / 4;
    dy = (start_offset_y - end.y) / 4;

    head_tip = {x: start_offset_x, y: start_offset_y}
    head_end_1 = {x: head_tip.x - dx - dy, y: head_tip.y + dx - dy};
    head_end_2 = {x: head_tip.x - dx + dy, y: head_tip.y - dx - dy};
  }

  function draw(ctx, layoutParams, selectedIndices) {
    init(layoutParams);

    ctx.setGlobalAlpha(utils.strokeTransparency(annotation_data.stroke_opacity));
    ctx.setStrokeColor(annotation_data.stroke_color);
    ctx.setStrokeWidth(line_width);
    ctx.setLineJoin('round')
    ctx.setLineCap('round')

    // Arrow body
    ctx.beginPath();
    ctx.moveTo(head_tip.x, head_tip.y);
    ctx.lineTo(end.x, end.y);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(head_end_1.x, head_end_1.y);
    ctx.lineTo(head_tip.x, head_tip.y);
    ctx.lineTo(head_end_2.x, head_end_2.y);
    ctx.stroke();

    hot_spot_radius = line_width / 2;
    if (selectedIndices.length > 0) {
      hot_spot_radius = utils.hotSpotRadius(layoutParams.overallSize().height);
      utils.highlightPoints(ctx, utils.vectorIndiciesToPoints(selectedIndices, start, end), hot_spot_radius);
      utils.drawAnchors(ctx, utils.vectorAnchorsFromSelectedIndicies(selectedIndices, start, end), hot_spot_radius);
    }        
  }

  function findPoints(x, y) {
    let selected = [];
    if (utils.isOnHotSpot(x, y, start, hot_spot_radius)) {
      selected.push(0);
    } else if (utils.isOnHotSpot(x, y, end, hot_spot_radius)) {
      selected.push(1);
    }
    else if (isHere(x, y)) {
      selected.push(0);
      selected.push(1);
    }
    return selected;

    function isHere(x, y) {
      return utils.isOnPoint(x, y, head_tip, line_width)
      || utils.isOnPoint(x, y, end, line_width)
      || utils.isOnLine(x, y, head_tip, end, line_width)
      || utils.isOnPoint(x, y, head_end_1, line_width)
      || utils.isOnPoint(x, y, head_end_2, line_width)
      || utils.isOnLine(x, y, head_tip, head_end_1, line_width)
      || utils.isOnLine(x, y, head_tip, head_end_2, line_width);
    };
  }
  function allSelectablePoints() {
    return [0, 1];
  }




  var foundPoints = [];
  var selectedPoints = [];
  var currentX = -1;
  var currentY = -1
    
  shape.draw = function(context, layoutParams, drawingOptions, highlight) {
    const ctx = base.DrawingContext(context);
    let points = !highlight 
        ? []
        : shape.isSelected() ? selectedPoints
        : allSelectablePoints();
    draw (ctx, layoutParams, points);
  }
  shape.selectHere = function(x, y) {
    if (currentX != x || currentY != y) {
      shape.isHere(x, y);
    }
    selectedPoints = foundPoints;  
  }
  shape.selectAll = function() {
    selectedPoints = allSelectablePoints();
  }
  shape.unselect = function() {
    selectedPoints = [];
  }
  shape.isSelected = function() {
    return selectedPoints.length > 0;
  }

  shape.isHere = function(x, y) {
    currentX = x;
    currentY = y;
    foundPoints = findPoints(x, y);
    return foundPoints && foundPoints.length > 0;
  }

  shape.move = function(x, y, layoutParams) {
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    return utils.move(x + offset.x, y + offset.y, utils.vectorIndiciesToPoints(selectedPoints, start, end), overallSize);
  }

  return shape;
}
