<template>
  <div class="selection-field control-group" v-if="isRow">
    <div class="controls" :title="label" v-show="isTableCellVisible">
      <p v-if="isReadOnly || noSelectableValue"  class="inspection-table-field">&nbsp;{{ stringValue }} </p>
      <select v-else v-model="selections" :multiple="specification.multiple" v-bind:class="[isValid ? '' : 'field-error', 'no-select inspection-table-field', {dirty: isDirty}]">
        <option :value="option.key" v-for="option in options()">{{ option.value }}</option>
      </select>
    </div>
    <div class="controls" :title="label" v-show="!isTableCellVisible">
      &nbsp;
    </div>
  </div>
  <div class="selection-field control-group" v-show="isVisible && !noSelectableValue" v-else>
    <label v-bind:for="formId" class="control-label">{{ label }}<span v-if="isRequired">*</span></label>
    <div class="controls">
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="showCriteria || showMandatoryText">
        <span v-if="showCriteria">{{ criteriaText }}</span>
        <span v-if="showMandatoryText">This field is mandatory.</span>
      </p>
      <p v-if="isReadOnly">&nbsp;{{ stringValue }} </p>
      <select v-else v-model="selections" :multiple="specification.multiple" :class="['no-select span6', {dirty: isDirty}]">
        <option :value="option.key" v-for="option in options()">{{ option.value }}</option>
      </select>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldVisibilityMixin from '../../mixins/field_visibility_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'
  import { EventBus } from '../../lib/event_bus'
  import { SelectionField } from '../../lib/fields/_fields.js'

  export default {
    mixins: [fieldMixin, fieldLabelMixin, fieldVisibilityMixin, fieldValidationMixin],
    inject: ['sourceLists'],
    data: function () {
      let initialSelections = [];
      let initialData = [];

      if (!_.isNil(this.data)) {
        initialData = this.data.selections
        initialSelections = _.map(initialData, _.property('key'));
        if (!this.specification.multiple) {
          initialSelections = initialSelections[0]
        }
      }

      let field = SelectionField.create(this.specification).initialize(this.sourceLists, initialData);
      let initialFullOptions = field.getOptions();

      return {
        initialData: initialData,
        initialSelections: initialSelections,
        selections: initialSelections,
        originalOptions: initialFullOptions,
        field: field,
      }
    },
    watch: {
      specification: function (spec) {
        this.field = SelectionField.create(spec).initialize(this.sourceLists, this.initialData);
      },
      data: function (newValue) {
        if (!_.isNil(this.data)) {
          let newSelections = _.map(newValue.selections, _.property('key'));
          if (this.specification.multiple ? !_.isEqual(this.selections, newSelections) : this.selections != newSelections[0]) {
            this.selections = this.specification.multiple ? newSelections : newSelections[0];
          }        
        } else {
          if (_.isEmpty(newValue)) { 
            this.selections = this.specification.multiple ? [] : null;
          }
        }
      },
      selections: function (newSelections) {
        this.setSelection(newSelections);
      }
    },
    computed: {
      noSelectableValue: function () {
        return _.isEmpty(this.originalOptions)
      },
      stringValue: function () {
        let vm = this;
        if (_.isNil(vm.data)) {
          return "";
        } else {
          return _.chain(_.castArray(vm.data.selections)).map(_.property('value')).join(', ')
        }
      },
      hasOptions: function () {
         return !_.isEmpty(this.originalOptions)
      },
      isRequired: function () {
        return this.hasOptions && !_.isNil(this.specification.minimum_selections) && (this.specification.minimum_selections > 0);
      },
      isPresent: function () {
        return !_.isEmpty(this.selections)
      },
      minSelections: function () {
        return !_.isNil(this.specification.minimum_selections) ? this.specification.minimum_selections : 0 ;
      },
      maxSelections: function () {
        return !_.isNil(this.specification.maximum_selections) ? this.specification.maximum_selections : 0 ;
      },
      instanceCount: function () {
        return !_.isEmpty(this.data) ? this.data.selections.length : 0 ;
      },
      hasCriteria: function () {
        return (this.minSelections != 0 || this.maxSelections != 0);
      },
      isDirty: function () {
        return !(_.isEqual(this.initialSelections, this.selections) || this.checkBothEmpty(this.initialSelections, this.selections));
      }
    },
    methods: {
      checkBothEmpty: function (initial, current) {
        return ((_.isEmpty(initial) || initial === '' || _.isUndefined(initial)) && (_.isEmpty(current) || current === '' || _.isUndefined(current)))
      },
      checkCurrentCriteria: function () {
        let result = this.field.checkCriteria(this.selections);
        this.criteriaValid = result.isValid;
        this.criteriaText = result.messages.length === 0 ? ""
            : result.messages[0] + ".";
      },
      options: function () {
        return (this.isMulti() ? this.originalOptions : _.concat(this.emptyObject(),this.originalOptions));
      },
      isMulti: function () {
        return this.specification.multiple;
      },
      emptyObject: function () {
        return { key: "", value: "" };
      },
      setSelection: function (newSelections) {
        let selectedKeys = _.castArray(newSelections);

        this.currentValidity = this.assessValidity();

        let newFieldValue = this.field.parse(newSelections).data;

        this.$emit('update:data', newFieldValue);
        EventBus.$emit(this.parentReference + '-' + 'showHideResults', this.evaluateRules(selectedKeys));
      },
      evaluateRule: function (thisRule, selections) {
        let test = _.toUpper(thisRule.test);
        let searchVals = thisRule.keys;
        let fields = thisRule['field-references'];
        let emptySelection = (_.isEmpty(selections)) || (_.filter(selections, _.isEmpty).length >= 1);
        let matched = false;
        if (test == 'OR') {
          if (_.isEmpty(searchVals) && emptySelection) {
            matched = true;
          } else {
            _.forEach(searchVals, function(thisValue) {
              if (thisValue == "") {
                if (emptySelection) {
                  matched = true
                }
              } else { 
                if (_.includes(selections, thisValue)) {
                  // If any matched
                  matched = true
                }
              }
            })            
          }

        } 
        if (test == 'AND') {
          // Treat as AND - all have to match
          let misMatch = ''; // first time
          _.forEach(searchVals, function(thisValue) {
            if (!_.includes(selections, thisValue)) {
              misMatch = thisValue;
            }
          })
          matched = !!!misMatch;
        }
        return matched;
      },
      evaluateRules: function (selectedKeys) {
        // go through each rule, evaluate it. 
        // IF a rule matches addfields with behaviour to match list, 
        // else add fields to mistmath list
        let matched = {};
        let mismatched = {};
        let vm = this;
        if (!!vm.specification['field-control']) {
          // we have fields to control
          let rules = vm.specification['field-control'];
          _.forEach(rules, function(thisRule){
            let behaviour = _.toLower(thisRule.behaviour);
            if (vm.evaluateRule(thisRule, selectedKeys)) {
              // add matched fields
              _.forEach(thisRule['field-references'], function(thisFieldName) {
                if (!!!matched[thisFieldName]) {
                  // if fieldname alrady matched, do not alter entry
                  matched[thisFieldName] = behaviour;
                }
              });
            } else {
              _.forEach(thisRule['field-references'], function(thisFieldName) {
                mismatched[thisFieldName] = (behaviour == 'show' ? 'hide' : 'show');
              });
            };
          });
          // merge data into one Hash
          _.forEach(mismatched, function(entry, key) { if (!!!matched[key]) {matched[key] = entry}})
        }
        return matched;
      }      
    },
    created: function () {
      let vm = this;
      if (!!vm.underRollup){
        EventBus.$on(vm.underRollup, (eventData) => {
          vm.visibleUnderRollup = eventData['rollup'];
          let thisShowHide = eventData['groupShowHide'];
          if (!!thisShowHide) {
            vm.groupShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
      if (!!vm.controlledField){
        EventBus.$on(vm.controlEvent, (ShowHideData) => {
          let thisShowHide = ShowHideData[vm.reference];
          if (!!thisShowHide) {
            vm.controlShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
    }
  }
</script>

<style scoped lang="scss">
  .inspection-table-field {
    width: 95%;
    padding-right: 0px;
    margin-bottom: 0px;
  }
  .control-group {
    margin-bottom: 0px;  
  }
</style>