<template>
  <div class="sequence-form">
    <div class="row-fluid">
      <div class="span12">
        <div class="control-group" > 
          <label class="control-label"><b>Sequence: </b>{{ seqName }}</label>
          <div class="controls">
            <label class="control-label sequence-label">Sequence enabled</label>
            <input type="checkbox" v-model="enableSequence" :disabled="readOnly">
          </div>
          <transition name="slide-fade">
            <div v-show="enableSequence">
              <div class="controls">
                <label class="control-label sequence-label">Number padding</label>
                <select v-model="selectedPadding" :disabled="readOnly"> 
                  <option v-for="option in paddingOptions" v-bind:value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="controls">
                <label class="control-label sequence-label">Sequence unique within ...</label>
                <select v-model="selectedScope" :disabled="readOnly"> 
                  <option v-for="option in scopeOptions" v-bind:value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="alert alert-error" v-show="wpNoUsers">
                Cannot set up workpackage unique sequences until Assessors chosen.
              </div>
              <div class="controls" v-show="usersInList">
                <table class="table input_fields_wrap table-bordered sequence-entry">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th title='Keep it short!'>Prefix</th>
                      <th title='A number between 0 and 10000000'>Start Value</th>
                      <th>Example</th>
                    </tr>
                  </thead>
                  <tbody>
                    <sequence-user
                      :read-only="readOnly"
                      :sequence-padding="integerPadding"
                      :index="index"
                      :user-range="workingSequence.user_ranges[index]"
                      @update:userrange="val => updateRange(index, val)"
                      v-for="(user, index) in userList" :key="index">
                    </sequence-user>
                  </tbody>
                </table>    
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import SequenceUser from './sequence_user.vue'

  const NUMBERPADDING = ['1','2','3','4','5','6','7','8']
  const SCOPEOPTIONS = ['inspection','workpackage']
  
  export default {
    components: {
      SequenceUser
    },
    props: {
      sequence: Object,
      readOnly: Boolean,
      index: Number,
      userList: Array
    },
    data: function () {
      let initialSequence = _.cloneDeep(this.sequence);
      let initialPadding = initialSequence.padding;
      let initialScope = initialSequence.scope;
      let initialEnabled = !!initialSequence.enabled;
      initialSequence.enabled = initialEnabled;
      return {
        test: initialSequence,
        paddingOptions: NUMBERPADDING,
        scopeOptions: SCOPEOPTIONS,
        selectedPadding: initialPadding,
        selectedScope: initialScope,
        workingSequence: initialSequence,
        enableSequence: initialEnabled
      }
    },
    watch: {
      selectedPadding: function (newPadding) {
        this.setPadding(newPadding);
      },
      selectedScope: function (newScope) {
        this.setScope(newScope);
      },
      enableSequence: function (enableState) {
        this.setEnabled(enableState);
      }
    },
    computed: {
      seqName: function () {
        return this.sequence.name;
      },
      usersInList: function () {
        return !(_.isNil(this.userList) || _.isEmpty(this.userList))
      },
      wpNoUsers: function () {
        return !this.usersInList && this.scopeWP();
      },
      integerPadding: function () {
        return _.toInteger(this.selectedPadding);
      }
    },
    methods: {
      padNumber: function (number, padding) {
        return _.padStart(number.toString(), padding, '0')
      },
      defaultUserRange: function (userID) {
        return { "user_id": userID, "user_name": this.getUserNameFromList(userID), "start": 1, "prefix": "" };
      },
      scopeWP: function () {
        return (this.selectedScope == this.scopeOptions[1])
      },
      setPadding: function (newPadding) {
        this.workingSequence.padding = _.toInteger(newPadding);
        this.emitDataUpdate();
      },
      setScope: function (newScope) {
        this.workingSequence.scope = newScope;
        this.emitDataUpdate();
      },
      setEnabled: function (enableState) {
        this.workingSequence.enabled = enableState;
        this.emitDataUpdate();
      },
      getUserNameFromList: function (userID) {
        var userEntry = [];
        userEntry =  _.find(this.userList, function (user) { return user[0] == userID; });
        return userEntry[1];
      },
      updateRange: function (index, instance) {
        this.workingSequence.user_ranges.splice(index, 1, instance);
        //this.$set(this.instances, index, instance)
        this.emitDataUpdate()
      },
      emitDataUpdate: function () {
        this.$emit('update:sequence', this.workingSequence)
      }      
    }
  }
</script>

<style scoped lang="scss">
  .sequence-label {
    padding-top: 5px;
    padding-right: 5px;
  }
  .sequence-entry input {
    box-sizing: border-box;
    height: 30px;
    width: 100%;
  }
  .sequence-entry td {
    box-sizing: border-box;
    padding: 0px;
    padding-top: 5px;
    padding-left: 5px;
  }
  .slide-fade-enter-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }  
</style>