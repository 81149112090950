import _ from 'lodash'

export default {
  props: {
    showValidation: {
      type: Boolean,
      default: function () { return false; }
    },
    validateNow: {
      type: String,
      default: function () {return '';}
    }
  },
  data: function () {
    return {
      currentValidity: 'valid',
      criteriaValid: true,
      criteriaText: '',
      mandatoryText: ''
    }
  },
  watch: {
    validateNow: function () {
      this.assessValidity();
    }
  },
  computed: {
    isPresentIfRequired: function () {
      return this.isRequired ? this.isPresent : true;
    },
    meetsCriteria: function () {
      return this.hasCriteria ? !this.hasUnmetCriteria : true;
    },
    isPresent: function () {
      return true;
    },
    isRequired: function () {
      return !!this.specification.required;
    },
    hasCriteria: function () {
      return false;
    },
    isValid: function () {
      return this.currentValidity == 'valid';
    },
    showCriteria: function () {
      return !this.isReadOnly && !this.meetsCriteria && !this.isHidden;
    },
    showMandatoryText: function () {
      return this.showValidation && !this.isValid && !this.isPresentIfRequired && !this.isHidden;
    },
    hasUnmetCriteria: function () {
      let localForceCheck = this.showValidation;
      return !this.criteriaValid;
    }
  },
  methods: {
    assessValidity: function () {
      let localValid = 'valid';
      this.checkCurrentCriteria();
      
      if (!this.isHidden && !this.isReadOnly) {
        localValid = (this.isPresentIfRequired && this.meetsCriteria) ? 'valid' : 'invalid';
      }
      if (localValid != this.currentValidity) {
        this.updateValidity(localValid);
      }
      this.currentValidity = localValid;
      return localValid;
    },
    checkCurrentCriteria: function () {
      this.criteriaValid = true;
    },
    updateValidity: function (newValidity) {
      let vm = this;
      let dataToSend = {};
      dataToSend[vm.reference] = newValidity;
      vm.$emit('update:errors', dataToSend);
    }
  }
}

