import axios from 'axios'


export const FileUploader = (tokenUrl, confirmUrl) => {

  function upload(assetBlob, fileName, progressCallback) {
    return new Promise(async (resolve, reject) => {
      if (!assetBlob || !fileName) {
        reject('No asset to upload!');
        return;
      }
  
      try {
        let tokenResponse = await axios.get(tokenUrl);
  
        // make the FormData packet for the submission to S3
        const fdpost = new FormData();
        fdpost.append('utf8', '✓');
        fdpost.append('key',tokenResponse.data.key.replace('${filename}',fileName)); //subst name of file
        fdpost.append('AWSAccessKeyID',tokenResponse.data.aws_access_key_id);
        fdpost.append('acl',tokenResponse.data.acl);
        fdpost.append('success_action_status',tokenResponse.data.success_action_status);
        fdpost.append('policy',tokenResponse.data.policy);
        fdpost.append('signature',tokenResponse.data.signature);
        fdpost.append('Content-Type',assetBlob.type);
        fdpost.append('file',assetBlob,fileName);
  
        try {
          let uploadResponse = await axios.post(tokenResponse.data.url,fdpost, {
            onUploadProgress: uploadEvent => {
              if (progressCallback) {
                progressCallback(Math.round(uploadEvent.loaded / uploadEvent.total * 100))
              }
            }
          });
  
          // update record in Hub
          var oParser = new DOMParser;
          var oDom = oParser.parseFromString(uploadResponse.data, "application/xml");
          var assetKey = oDom.getElementsByTagName("Key")[0].innerHTML;
  
          try {
            await axios.put(confirmUrl, { key: assetKey } );
            resolve();
          } catch (error) {
            reject('Problem updating the Hub: ' + error.message);
          }
        } catch (error) {
          reject('Problem storing the image: ' + error.message);
        }
      } catch (error) {
        reject('Problem getting permission to upload: ' + error.message);
      }
    });
  }

  return {
    upload: upload,
  };
}
