import _ from 'lodash'

let fieldToString = function (fieldValue, keyforSelection) {
  if (!fieldValue) {
    return '';
  }

  switch (fieldValue.type) {
    case "text": return fieldValue.value;
    case "integer": return fieldValue.value;
    case "decimal": return fieldValue.value;
      return _.has(fieldValue, 'value') ? fieldValue.value : '';
    case "selection":
      var keyOrValue = keyforSelection ? 'key' : 'value';
      return _.chain(fieldValue.selections).map(_.property(keyOrValue)).join(', ').value(); 
    case "date": 
       return _.has(fieldValue, 'day') ? makeDate(fieldValue) : '';
    case "time": 
       return _.has(fieldValue, 'hour') ? makeTime(fieldValue) : '';
    default:
      return '';
  }
}

let makeDate = function (fieldValue) {
  // NB zero based array - add dummy zeroth entry
  var monthNames = ["",
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  return fieldValue.day + '-' + monthNames[fieldValue.month] + '-' + fieldValue.year;
}

let padNumber= function (n) {
  return (n < 10) ? ("0" + n) : n;
}

let makeTime = function (fieldValue) {
  return padNumber(fieldValue.hour) + ':' + padNumber(fieldValue.minute);
}

export default {
  computed: {
    interpolatedLabel: function() {
      return this.interpolatedText(this.labelTemplateStr, this.data);
    },
    interpolatedPrefix: function() {
      return this.interpolatedText(this.specification.prefix, this.data, true);
    },
    interpolatedSuffix: function() {
      return this.interpolatedText(this.specification.suffix, this.data, true);
    },
  },
  methods: {
    interpolatedText: function(textTemplate, thisData, noEmptyFields) {
      let missing = false;
      let result = _.replace(textTemplate, /%{([\s\S]+?)}/g, function (match, expr) {
        var keyField = false;
        var valueExpr = expr;
        if (_.endsWith(expr,':key')) {
          keyField = true;
          valueExpr = _.replace(expr,':key','');
        }
        let val = fieldToString(thisData[valueExpr.trim()], keyField);
        if (noEmptyFields && !val) {
          missing = true;
        }
        return val;
      });
      return missing ? "" : result;
    }
  }
}