<template>
    <div id="asset-map">
        <div id="map-asset-container">

          <div id="asset-filters">
          <div>
            <select v-model="currentAssetType" id="currentassettype">
              <option :disabled="loading" :value="option" v-for="option in assetTypes">{{ assetName(option) }}</option>
            </select>
            <h2 v-bind:class="[loading ? 'showLoading' : 'hideLoading']">Loading assets&hellip;</h2>
          </div>
          <asset-map-filter @update:filter="newFilter => { filter = newFilter; this.updateFilter() }"></asset-map-filter>
          </div>

          <div id="map-view"></div>

        </div>
        <div id="asset-selections">
          <asset-map-selection 
            :create-import-path="createImportPath" 
            :features="selectedFeatures.features" 
            :work-packages="workPackagesForAssetType(currentAssetType)"
            :asset-type="currentAssetType"
            @deselect="deselectFeatures"
            @planned="planFeatures"
            @import:success="val => importSuccess(val)"
            @import:failure="val => importFailure(val)">
          </asset-map-selection>
        </div>

        <div id="lost-assets">
        <asset-map-lostassets :features="lostFeatures[currentAssetType].features" :asset-type="currentAssetType"></asset-map-lostassets>
        </div> 
    </div>
</template>

<script>
  import mapboxgl from '!mapbox-gl'
  import MapboxDraw from '@mapbox/mapbox-gl-draw'
  import _ from 'lodash'
  import pointsWithinPolygon from '@turf/points-within-polygon'

  import MapControls from '../lib/map_controls'
  import AssetMapFilter from './asset_map_filter.vue'
  import AssetMapSelection from './asset_map_selection.vue'
  import AssetMapLostassets from './asset_map_lostassets.vue'
  import MapMixin from '../mixins/map_mixin'

  import normalIconSrc from '../assets/wall-normal.png'
  import dueIconSrc from '../assets/wall-due.png'
  import monitoredIconSrc from '../assets/wall-monitored.png'
  import overdueIconSrc from '../assets/wall-overdue.png'
  import inProgressIconSrc from '../assets/wall-in-progress.png'
  import plannedIconSrc from '../assets/wall-planned.png'

  const drawOptions = {
    controls: {
      polygon: true,
      trash: true
    },
    displayControlsDefault: false
  }

  const fixedOptions = {
    container: 'map-view'
  }

  let attachIcon = function (map, iconName, iconSrc) {
    map.loadImage(iconSrc, (error, image) => {
        if (error) {
          console.error("Failed to load icon '${iconName}' from '${iconSrc}'")
        } else {
            map.addImage(iconName, image)
        }
    })
  }

  export default {
    mixins: [MapMixin],
    components: {
      AssetMapFilter,
      AssetMapSelection,
      AssetMapLostassets
    },
    mounted: function () {
      let vm = this;

      let defaultOptions = {
        center: [174.761352, -36.8484657],
        zoom: 11,
        style: 'mapbox://styles/mapbox/bright-v9?optimize=true'
      };

      // Initialize map
      mapboxgl.accessToken = vm.mapboxToken;
      let computedOptions = Object.assign({}, defaultOptions, fixedOptions)
      let map = new mapboxgl.Map(computedOptions);

      attachIcon(map, 'normal', normalIconSrc)
      attachIcon(map, 'due', dueIconSrc)
      attachIcon(map, 'monitored', monitoredIconSrc)
      attachIcon(map, 'overdue', overdueIconSrc)
      attachIcon(map, 'in_progress', inProgressIconSrc)
      attachIcon(map, 'planned', plannedIconSrc)


      // Add drawing controls to map
      let draw = new MapboxDraw(drawOptions)
      map.addControl(draw)


      let controls = new MapControls()
      map.addControl(controls)
      controls.$on('selectInPolygon', function () {
        vm.updateSelected(vm.currentAssetType)
      })
      controls.$on('zoomToVisible', function () {
        vm.zoomToFeatures()
      })

      // disable map rotation using right click + drag
      map.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      map.touchZoomRotate.disableRotation();

      // Capture if the map has initialized
      map.on('load', function () {
        vm.mapLoaded = true

      })

      vm.loadAssetData(map, this.currentAssetType);

      vm.map = map
      vm.draw = draw
    },
    destroyed: function () {
      let vm = this;
      vm.map.removeControl(vm.draw)
    },
    props: {
      organisationId: String,
      availableWorkPackages: Object,
      rammDataPath: String,
      createImportPath: String,
      mapboxToken: String,
      assetTypes: Array
    },
    data: function () {
      let initialAsset = _.isEmpty(this.assetTypes) ? '' : this.assetTypes[0];
      let initialData = _.cloneDeep(this.emptyData(this.assetTypes));
      let initialFeatureData = _.cloneDeep(this.emptyData(this.assetTypes));
      let initialLostFeatures = _.cloneDeep(this.emptyData(this.assetTypes));
      let initialSelections = this.emptySelections(this.assetTypes);
      return {
        mapLoaded: false,
        loading: true,
        features: initialFeatureData,
        lostFeatures: initialLostFeatures,
        selectedIds: initialSelections,
        originalData: initialData,
        currentAssetType: initialAsset,
        filter: function (features) {
          return features;
        }
      }
    },
    watch: {
      currentAssetType: function (newAssetType) {
        let vm = this;
        if (_.isEmpty(vm.originalData[newAssetType])) {
          vm.loadAssetData(vm.map, newAssetType);
        } else {
          vm.updateFilter();
          vm.bindData(vm.map, vm.features[newAssetType]);
        }
      },
      selectedCurrentAssetIds: function () {
        this.updateFilter()
        this.$nextTick(this.updateSelectedMapDisplay)
      },
      'currentFeatures.features': function (newFeatures) {
        let vm = this

        let source = vm.map.getSource('assetFeatures')
        if (source) {
          source.setData(vm.currentFeatures)
        }
      }
    },
    computed: {
      currentFeatures: function () {
        return this.features[this.currentAssetType];
      },
      selectedCurrentAssetIds: function () {
        return this.selectedIds[this.currentAssetType];
      },
      selectedFeatures: function () {
        let vm = this;
        let selectedIds = vm.selectedIds[vm.currentAssetType];

        let selectedFeatures = _.filter(((vm.originalData[vm.currentAssetType] || {}).features || []), function (feature) {
            return _.includes(selectedIds, feature.properties.ramm_entity_id)
        })

        return {
            type: "FeatureCollection",
            features: selectedFeatures
        }
      },
      currentAssetSource: function () {
        return this.currentAssetType;
      },
      currentAssetLayer: function () {
        return this.currentAssetType;
      },
      currentSelectedAssetSource: function () {
        return 'selected_' + this.currentAssetType;
      },
      currentSelectedAssetLayer: function () {
        return 'selected_' + this.currentAssetType;
      }
    },
    methods: {
      workPackagesForAssetType: function (asset_type) {
        return this.availableWorkPackages[asset_type]
      },
      emptyData: function (asset_array) {
        let vm = this;
        let result = {};
        _.forEach(asset_array, function(asset_type) { result[asset_type] = {} })
        return result;
      },
      emptySelections: function (asset_array) {
        let vm = this;
        let result = {};
        _.forEach(asset_array, function(asset_type) { result[asset_type] = [] })
        return result;
      },
      loadAssetData: function (map, asset_type = null) {
        let vm = this;
        let local_asset_type = _.isNil(asset_type) ? this.assetTypes[0] : asset_type;

        let dataPath = vm.rammDataPath + '?asset_type=' + local_asset_type;


        vm.loading = true
        vm.$http.get(dataPath).then(response => {
          let bindDataCallback = () => {
            vm.originalData[local_asset_type] = vm.featureCollectionWithGoodLocations(response.data);
            vm.lostFeatures[local_asset_type] = vm.featureCollectionWithBadLocations(response.data);
            vm.features[local_asset_type] = _.cloneDeep(vm.originalData[local_asset_type]);
            //vm.features = _.cloneDeep(vm.originalData);

            vm.updateFilter();

            vm.bindData(map, vm.features[local_asset_type]);
            vm.loading = false;
          }

          if (vm.mapLoaded) {
            bindDataCallback()
          } else {
            map.on('load', bindDataCallback)
          }
        }, response => {
          console.log('error', response)
        })
      },
      featureCollectionWithGoodLocations: function (featureCollection) {
        let vm = this;
        return {
            type: "FeatureCollection",
            features: _.filter(((featureCollection || {}).features || []), function (feature) {
              return !(vm.featureNotInNZ(feature));
            })
        }
      },
      featureCollectionWithBadLocations: function (featureCollection) {
        let vm = this;
        return {
            type: "FeatureCollection",
            features: _.filter(((featureCollection || {}).features || []), function (feature) {
              return (vm.featureNotInNZ(feature));
            })
        }
      },
      featureNotInNZ: function (feature) {
        return _.isNil(feature.properties.northing) || _.isNil(feature.properties.easting) || !_.inRange(feature.geometry.coordinates[1], -32, -53) || !(_.inRange(feature.geometry.coordinates[0], 163, 180) || _.inRange(feature.geometry.coordinates[0], -175, -180)) ;
      },
      updateSelectedMapDisplay: function () {
        let vm = this;

        let source = vm.map.getSource('selectedFeatures');
        if (source) {
          source.setData(vm.selectedFeatures)
        }
      },
      bindData: function (map, data) {
        let vm = this;

        // Create a popup, but don't add it to the map yet.
        var popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        let source = map.getSource('assetFeatures');

        if (source) { 
          if (map.getLayer('assetLayer')) map.removeLayer('assetLayer');
          map.removeSource('assetFeatures');
        };
        map.addSource('assetFeatures', {
          type: 'geojson',
          data: data
        })

        source = map.getSource('selectedFeatures');

        if (source) { 
          if (map.getLayer('selectedLayer')) map.removeLayer('selectedLayer');
          map.removeSource('selectedFeatures') ;
        };
        map.addSource('selectedFeatures', {
          type: 'geojson',
          data: vm.selectedFeatures
        })

        map.addLayer({
          id: 'assetLayer',
          type: 'symbol',
          source: 'assetFeatures',
          layout: {
            "icon-image": "{status}",
            "icon-padding": 0,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          },
        })

        map.addLayer({
          id: 'selectedLayer',
          type: 'symbol',
          source: 'selectedFeatures',
          layout: {
            "icon-image": "due",
            "icon-padding": 0,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          }
        })

        map.on('click', 'assetLayer', function (e) {
          vm.selectFeatures(e.features)
        })

        map.on('mouseenter', 'assetLayer', function (e) {
          map.getCanvas().style.cursor = 'pointer';
          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = vm.popupText(e.features[0].properties); 

          // Ensure that if the map is zoomed out such that multiple 
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates)
              .setHTML(description)
              .addTo(map);
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'assetLayer', function () {
          map.getCanvas().style.cursor = '';
          popup.remove();
        });

        map.on('click', 'selectedLayer', function (e) {
          vm.deselectFeatures(e.features)
        })

        map.on('mouseenter', 'selectedLayer', function (e) {
          map.getCanvas().style.cursor = 'pointer';
          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = vm.popupText(e.features[0].properties); 

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates)
              .setHTML(description)
              .addTo(map);
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'selectedLayer', function () {
          map.getCanvas().style.cursor = '';
          popup.remove();          
        });

        this.zoomToFeatures()
      },
      popupText: function (asset) {
        let vm = this;
        let asset_type = vm.cleanType(asset.asset_type);
        let asset_id = asset.id;
        let common_name = asset.common_name;
        let asset_detail_type = asset.asset_detail_type;
        let inspect_due_date = asset.inspect_due_date;

        let description = asset_type + "&nbsp;" + asset_id + "</br>" + common_name + "</br>";
        description += ((asset_detail_type == "null") ? '' : (asset_detail_type + "</br>"));
        return (description += vm.dateShort(inspect_due_date));

      },
      selectFeatures: function (features) {
        let vm = this;
        let selectableFeatures = _.filter(features, function (feature) {
          return !feature.properties.in_progress && !feature.properties.planned
        })

        let selectedIds = _.map(selectableFeatures, function(feature) {
          return feature.properties.ramm_entity_id
        })

        this.selectedIds[vm.currentAssetType] = _.uniq(_.concat(vm.selectedIds[vm.currentAssetType], selectedIds))
      },
      deselectFeatures: function (features) {
        let vm = this;
        let selectedIds = _.map(features, function(feature) {
          return feature.properties.ramm_entity_id
        })

        this.selectedIds[vm.currentAssetType] = _.reject(vm.selectedIds[vm.currentAssetType], function (id) {
          return _.includes(selectedIds, id)
        })
      },
      planFeatures: function (features) {
        let vm = this;
        _.forEach(features, function (feature) { feature.properties.planned = true; feature.properties.status = 'planned' })
      },
      zoomToFeatures: function() {
        this.zoomToTheseFeatures(this.currentFeatures);
      },
      zoomToTheseFeatures: function(featureCollection) {
        let bounds = new mapboxgl.LngLatBounds()
        let zoomFeatures = featureCollection.features

        if (!_.isEmpty(zoomFeatures)) {
          _.each(zoomFeatures, function(feature) {
            let point = feature.geometry.coordinates
            // TODO: Work out a better filter for bogus data... maybe there is something in turf.js
            if (point[1] < 90 && point[1] > -90) {
              bounds.extend(point)
            }
          })

          this.map.fitBounds(bounds, {padding: 50})          
        }
      },
      featuresInPolygon: function (asset_type) {
        return pointsWithinPolygon(this.features[asset_type], this.draw.getAll())
      },
      updateSelected: function (asset_type) {
        this.selectFeatures(this.featuresInPolygon(asset_type).features)
        this.draw.deleteAll()
      },
      updateFilter: function () {
        let vm = this

        let selectedPredicate = feature => {
          return !_.includes(vm.selectedIds[vm.currentAssetType], feature.properties.ramm_entity_id)
        }

        let predicate = _.overEvery(selectedPredicate, this.filter);
        this.features[vm.currentAssetType].features = _.filter((vm.originalData[vm.currentAssetType] || {}).features, predicate);
      },
      importSuccess: function (val) {
        console.log('success')
      },
      importFailure: function (val) {
        console.log(val)
      }
    }
  }
</script>

<style scoped lang="scss">
    #map-view {
        width: auto;
        height: 700px;
    }
    #asset-filters {
        width: 450px;
        float: left;
        margin-right: 8px;
    }
    #asset-selections {
        float: none;
        display: inline-block;
    }
    #lost-assets {
        float: none;
        display: inline-block;
    }
    .mapboxgl-popup {
        max-width: 400px;
        font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
    .showLoading {
      display: inline
    } 
    .hideLoading {
      display: none
    }
</style>
