<template>
  <div>
    <h3 title="Not displayed on the map">{{ assetName(assetType) }} with faulty Northing and Eastings</h3>
    <table class="lost-assets-table">
      <tr>
        <th>id</th>
        <th>type</th>
        <th>road</th>
        <th>status</th>
        <th>Current inspection due</th>
        <th>Traffic Mgmt</th>
      </tr>
      <tr v-for="(feature, index) in features">
        <td>{{ cleanType(feature.properties.asset_type) }} {{ feature.properties.id }} </td>
        <td>{{ feature.properties.asset_detail_type }}</td>
        <td>{{ feature.properties.road_name}}</td>
        <td>{{ feature.properties.status }}</td>
        <td>{{ dateLong(feature.properties.inspect_due_date) }}</td>
        <td>{{ feature.properties.traffic_management_level }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import _ from 'lodash'
  import MapMixin from '../mixins/map_mixin'

  export default {
    mixins: [MapMixin],
    props: [
      'features',
      'assetType'
    ],
    data: function () {
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  .import-data-section {
    margin-top: 10px;
    margin-bottom: 2px;
  }
  .lost-assets-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px black;
  }
  .lost-assets-table th:nth-child(1) {
    width: 10%;
  }
  .lost-assets-table th:nth-child(2) {
    width: 10%;
  }
  .lost-assets-table th:nth-child(3) {
    width: 20%;
  }
  .lost-assets-table th:nth-child(4) {
    width: 15%;
  }
  .lost-assets-table th:nth-child(5) {
    width: 15%;
  }
  .lost-assets-table th:nth-child(6) {
    width: 15%;
  }
  .lost-assets-table th:nth-child(7) {
    width: 15%;
  }
  th {
    padding-left: 20px;
    padding-right: 20px;
    color: #AAAAAA;
    background-color: #333333;
  }  
  td {
    text-align: center;
  }
  .lost-assets-table tr:nth-child(odd) {
    background-color: #d3dbde;
  }

  .lost-assets-table tr:nth-child(even) {
    background-color: none;
  }
</style>