<template>
  <div>
    <div v-if="!versionOnly">
      <label for="edit-version-name" class="control-label">Drawing Name<span>* </span></label>
      <div class="controls">
        <input id="edit-version-name" type="text" v-model="newName" v-on:keydown.enter.prevent="">
      </div>
    </div>
    <version-editor
      v-if="newForm || versionOnly"
      :version.sync="version"
      :isNew="newForm"
      :uploadAssets="uploadAssets"
      @uploadResult="val => { if (val) submitForm() }"
      @haveNewAsset="val => haveNewVersionAsset = val"
    />
      <div class="form-controls">
        <div class="btn-group">
          <button class="btn btn-primary" :disabled="!saveAllowed" :title="saveButtonPrompt" v-on:click.prevent="onSave">{{saveButtonText}}</button>
        </div>
        <a class="btn" :href="cancelUrl()">Cancel</a>
      </div>
    <input type="submit" ref="submit_form" style="display:none" />
  </div>
</template>

<script>

import version_editor from './drawing_editor_sections/drawing_version_editor.vue'

export default {
  components: {
    versionEditor: version_editor
  },
  props: {
    assets: Array,
    version: Object,
    drawingName: String,
    newForm: Boolean,
    versionOnly: Boolean
  },
  data: function () {
    return {
      newName: this.drawingName,
      uploadAssets: false,
      haveNewVersionAsset: false,
    }
  },
  watch: {
    newName: function (newDrawingName) {
      this.$emit('update:drawingName', newDrawingName)
    },
  },
  computed: {
    hasPhoto: function () {
      return !this.newForm || this.haveNewVersionAsset;
    },
    hasName: function () {
      return this.newName != null && this.newName.trim() != "";
    },
    saveAllowed: function() {
      return this.hasPhoto && this.hasName;
    },
    saveButtonText: function () {
      return (this.newForm ? "Create New " : "Save ") 
        + (this.versionOnly ? "Version" : "Drawing");
    },
    saveButtonPrompt: function () {
      return !this.hasPhoto ? "You must load a drawing first"
        : !this.hasName ? "Name the drawing first"
        : this.newForm ? "Create new drawing"
        : "Update version"
    },
  },
  methods: {
    onSave: function() {
      if (this.newForm) {
        this.uploadAssets = true;
        this.$nextTick(() => this.uploadAssets = false);
      } else {
        this.submitForm();
      }
    },
    submitForm: function() {
      if (this.version != null) {
        if (_.isEmpty(this.version['original_asset_id']) || _.isEmpty(this.version['aspect_ratio'] + "")) {
          alert('Error uploading file - please try again.');
          return;
        } else {
          if (this.newForm && !this.versionOnly) {
            this.assets.push(this.version);
            this.$emit("update:assets", this.assets);
          }
        }
      }
      let submit_button = this.$refs.submit_form;
      this.$nextTick(() => submit_button.click());
    },
    cancelUrl: function() {
      return this.newForm && !this.versionOnly
        ? window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/drawings'))
        : window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'));
    },
  }
}
</script>

<style scoped lang="scss">
  .form-controls {
    margin-top: 20px;
    margin-left: 201px;
  }
</style>