import { Balloon, MergeBalloon, defaultTextScale } from "./shapes/balloon"
import { Arrow } from "./shapes/arrow"
import { Line } from "./shapes/line"
import { Square } from "./shapes/square"
import { Circle } from "./shapes/circle"
import { Rectangle } from "./shapes/rectangle"
import { FreeStyle } from "./shapes/freestyle"
import { Shape } from "./shapes/shape"


export class DrawingOptions {

  static get default() {
    return {
      balloonOptions: {
        textScale: defaultTextScale,
        textHeightOverride: 0,
        drawBigPicturePointers: false,
        fillColorOverride: '#FFFFFF',
        textColorOverride: '#000000',
        drawHeadlessArrows: true,
        drawBorder: true,
      },
      backgroundNaturalHeight: 0,
    }
  }

  balloonOptions = DrawingOptions.default.balloonOptions;
};

export const AnnotationDefaults = () => {
  return {
    balloonTextScale: defaultTextScale,
    balloonTextColor: '#000000',
    balloonFillColor: '#FFFFFF',
    shapeStrokeColor: '#0000FF',
    shapeStrokeOpacity: 255,
    shapeStrokeScale: 20,
    shapeFillOpacity: 0,
  }
};

export const AnnotationTypes = () => {
  return {
    mergeBalloon: 'merge-balloon',
    balloon: 'balloon',
    arrow: 'arrow',
    line: 'line',
    circle: 'circle',
    square: 'square',
    rectangle: 'rectangle',
    freeStyle: 'free-style',
  }
};

export const LayoutParams = () => {
  var overallWidth = 0;
  var overallHeight = 0;
  var offsetX = 0;
  var offsetY = 0;

  function overallSize() {
    return {width: overallWidth, height: overallHeight};
  }
  function viewOffset() {
    return {x: offsetX, y: offsetY};
  }
  function setOverallSize(width, height) {
    overallWidth = width;
    overallHeight = height;
  }
  function setViewOffset(x, y) {
    offsetX = x;
    offsetY = y;
  }

  return {
    overallSize,
    viewOffset,
    setOverallSize,
    setViewOffset,
  }
}

export const ShapeFactory = () => {
  function makeShapes(annotations, immutable) {
    let shapes = [];
    if (annotations) {

      let balloons = [];
      let freeStyles = [];
      let otherShapes = [];
      let AnnotationType = AnnotationTypes();

      let last = annotations.length;
      for (let index = 0; index < last; index++) {
        let annotation = annotations[index];
        let shape = constructor(annotation, immutable);

        switch (annotation.annotation_type) {
          case AnnotationType.freeStyle:
            freeStyles.push(shape);
            break;
            case AnnotationType.balloon:
            case AnnotationType.mergeBalloon:
                balloons.push(shape);
            break;
          default:
            otherShapes.push(shape);
        }
      }

      shapes = freeStyles.concat(otherShapes, balloons);
    }

    return shapes;
  };

  function constructor(annotation, immutable) {
    let AnnotationType = AnnotationTypes();
    switch (annotation.annotation_type) {
      case AnnotationType.mergeBalloon:
        return MergeBalloon(annotation);
      case AnnotationType.balloon:
        return Balloon(annotation, immutable);
      case AnnotationType.arrow:
        return Arrow(annotation);
      case AnnotationType.line:
        return Line(annotation);
      case AnnotationType.square:
        return Square(annotation);
      case AnnotationType.circle:
        return Circle(annotation);
      case AnnotationType.rectangle:
        return Rectangle(annotation);
      case AnnotationType.freeStyle:
        return FreeStyle(annotation);
      default:
        return Shape(annotation);
    }
  };

  return { makeShapes: makeShapes }
}
