<template>
  <div>
    <canvas ref="magnifierGlass" width="300" height="300"
      v-show="showGlass"
      class="img-magnifier-glass"
      @mousemove="moveMagnifier"
      @touchmove="moveMagnifier">
    </canvas>
  </div>
</template>

<script>
export default {
  props: {
    subject: HTMLElement,
    imageSource: String,
    zoom: Number,
    redraw:Boolean,
    crosshairColor: String
  },
  data: function () {
    return {
      currentImage: this.subject,
      glass: null,
      subjectX: 0,
      subjectY: 0,
      showGlass: false
    }
  },
  watch: {
    subject: function (img) {
      if (this.currentImage != null) {
        this.currentImage.removeEventListener("mousemove", this.moveMagnifier);
        this.currentImage.removeEventListener("touchmove", this.moveMagnifier);
        this.currentImage.removeEventListener("mouseleave", this.mouseleave);
      }
      img.addEventListener("mousemove", this.moveMagnifier);
      img.addEventListener("touchmove", this.moveMagnifier);
      img.addEventListener("mouseleave", this.mouseleave);

      this.currentImage = img;
      this.setImage();
    },
    imageSource: function () {
      this.setImage();
    },
    zoom: function () {
      this.resizeImage();
      this.drawGlass();
    },
    redraw: function (redraw) {
      if (redraw) {
        this.setImage();
      }
    },
  },
  mounted() {
    this.glass = this.$refs.magnifierGlass;
    this.glass.style.backgroundRepeat = "no-repeat";
  },
  methods: {
    setImage: function () {
      if (this.imageSource != null) {
        this.glass.style.backgroundImage = "url('" + this.imageSource + "')";

        this.resizeImage();
        this.calculatePosition(null);
        this.drawGlass();
      }
    },
    resizeImage: function () {
      let rect = this.subject.getBoundingClientRect();
      this.glass.style.backgroundSize = (rect.width * this.zoom) + "px " + (rect.height * this.zoom) + "px";
    },
    mouseleave: function (e) {
      this.showGlass = false;
    },
    moveMagnifier: function (e) {
      this.showGlass = true;
      if (this.isVisible()) {
        // prevent any other actions that may occur when moving over the image
        e.preventDefault();
        this.calculatePosition(e || window.event);
        this.drawGlass();
      }
    },
    isVisible: function () {
      return this.glass.offsetHeight > 0;
    },
    calculatePosition: function (e) {
      let rect = this.subject.getBoundingClientRect();
      if (e != null) {
        // set current location based on event
        this.subjectX = e.pageX - rect.left - window.pageXOffset;
        this.subjectY = e.pageY - rect.top - window.pageYOffset;
      }
      // make sure the current location is stil inside the subject area
      this.subjectX = Math.max(0, Math.min(this.subjectX, rect.width));
      this.subjectY = Math.max(0, Math.min(this.subjectY, rect.height));
    },
    drawGlass: function () {
      let glassHalfWidth = this.glass.offsetWidth / 2;
      let glassHalfHeight = this.glass.offsetHeight / 2;
      let bw = 3; // glass border width - copied from style

      // set the position of the magnifier glass
      this.glass.style.left = (this.subjectX) + "px";
      this.glass.style.top = (this.subjectY) + "px";
      // display what the magnifier glass "sees"
      this.glass.style.backgroundPosition = 
        (((this.subjectX * this.zoom) - glassHalfWidth + bw) * -1) + "px " +
        (((this.subjectY * this.zoom) - glassHalfHeight + bw) * -1) + "px";

      let context = this.glass.getContext("2d");

      // let sx = ((this.subjectX * this.zoom) - glassHalfWidth + bw);
      // let sy = ((this.subjectY * this.zoom) - glassHalfHeight + bw);
      // let dx = 0

      // context.drawImage(this.canvas, sx, sy, glassHalfWidth * 2, glassHalfHeight * 2, );



// some options:
// one: draw the part of the background canvas every time cursor moves - this might be laggy though
// two: omposite two images in background - is this possible?
// three: dynamically draw the existing crosshairs when cursor moves over that area

// four: alter cursor to X and/or border colour when over old point
// other thoughts: show current coord figures for both and also where the mouse is right now



      context.clearRect(0, 0, this.glass.width, this.glass.height);
      drawCrosshairs(context, this.glass.width, this.glass.height, this.crosshairColor);

      function drawCrosshairs(context, width, height, color) {
        context.lineWidth = 3;
        context.strokeStyle = color;
        context.beginPath();
        context.moveTo(0, height / 2);
        context.lineTo(width, height / 2);
        context.stroke();
        context.beginPath();
        context.moveTo(width / 2, 0);
        context.lineTo(width / 2, height);
        context.stroke();
      };

    }
  }
}
</script>

<style scoped>
  .img-magnifier-glass {
    position: absolute;
    border: 3px solid #000;
    border-radius: 50%;
    /*Set the size of the magnifier glass:*/
    width: 100px;
    height: 100px;
    z-index: 2000; /* make sure it's on top */
    pointer-events: none; /* allow click-through */
    transform: translate(-50%, -50%);
  }
</style>
