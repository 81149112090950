<template>
  <div>
    <div class="filter-container">
      <label for="textslider">Scale balloon text:</label>
      <span  class="slider">
        <input type="range" min="1" max="100" step="1" :value="textScale" @input="onTextScaleChanged" id="textslider"/> 
        <label for="textslider">{{ textScale }}</label>
      </span>
    </div>
    <div class="filter-container">
      <label for="balloon-colors">Balloon colour:</label>
      <select v-model="selectedBalloonColor" id="balloon-colors" class="stretch-width">
        <option :value="key" v-for="(option, key) in balloonColors" :key="key">{{ option.label }}</option>
      </select>
    </div>
    <div class="filter-container choice-option-list">
      <input type="checkbox" id="balloon-arrows" class="choice-checkbox" v-model="drawBalloonArrows"/>
      <label for="balloon-arrows">Show balloon arrows</label>
    </div>
    <div class="filter-container">
      <label for="textslider">Merge balloons within:</label>
      <span  class="slider">
        <input type="range" min="0" max="5" step="0.1" :value="mergeRadius" @input="onMergeRadiusChanged" id="textslider"/> 
        <label style="white-space: nowrap" for="textslider">{{ mergeRadiusDisplay }}</label>
      </span>
    </div>
    <div class="filter-container" v-if="!noTypes">
      <p>Annotations to show:</p>
      <ul class="choice-option-list">
        <li v-for="annotType in allChoices" :key="annotType.key">
          <input type="checkbox" v-bind:id="'option-' + annotType.key" v-bind:value="annotType.key" v-model="annotationChoices" class="choice-checkbox">
          <label v-bind:for="'option-' + annotType.key">{{annotType.label}}</label>
        </li>
      </ul>
    </div>
    <div v-if="!noSave">
      <button class="btn btn-mini btn-primary dropdown-toggle" title="Save these drawing settings for the work package" @click="handleSaveSettings">
        Save Settings
      </button>
      <span v-show="saveSettingsFailed" class="alert alert-error alert-small">Not Saved</span>
      <span v-show="saveSettingsSucceeded" class="alert alert-success alert-small">Saved</span>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { AnnotationTypes } from "../../lib/annotations/annotation"
  import DrawingFilters from "./drawing_filters"
  import DrawingBalloonList from "./drawing_balloon_list"
  var annotationType = AnnotationTypes();

  const ANNOTATION_TYPES = [
    {
        key: annotationType.balloon,
        label: 'Text Balloon'
    },{
        key: annotationType.arrow,
        label: 'Arrow'
    },{
        key: annotationType.line,
        label: 'Line'
    },{
        key: annotationType.square,
        label: 'Square'
    },{
        key: annotationType.rectangle,
        label: 'Rectangle'
    },{
        key: annotationType.circle,
        label: 'Circle'
    },{
        key: annotationType.freeStyle,
        label: 'Freestyle'
    }]

  export default {
    components: {
      DrawingBalloonList,
      DrawingFilters,
    },
    props: {
      drawingSettings: Object,
      colorSchemes: Object,
      saveSettingsResult: Boolean,
      noTypes: Boolean,
      noSave: Boolean,
    },
    data: function () {
      // order of the merge is important if we are to have the values sorted as we want in the list
      let colors = _.merge({ _original: { label: "Original" }}, this.colorSchemes);
      let initialBalloonColor = this.drawingSettings?.balloon?.color_scheme ?? "_original";
      let initialTextScale = this.drawingSettings?.balloon?.text_scale ?? 20;
      let initialDrawArrows = this.drawingSettings?.balloon?.draw_arrows ?? false;
      let initialMergeRadius = this.drawingSettings?.balloon?.merge_radius ?? 0;
      let initialTypes = this.drawingSettings?.show_types 
          ?? _.map(ANNOTATION_TYPES, (type) => type.key)
      return {
        annotationChoices: initialTypes,
        allChoices: ANNOTATION_TYPES,

        textScale: initialTextScale,
        balloonColors: colors,
        selectedBalloonColor: initialBalloonColor,
        drawBalloonArrows: initialDrawArrows,

        mergeRadius: initialMergeRadius,
      };
    },
    watch: {
      selectedBalloonColor: function (newValue) {
        if (newValue != this.drawingSettings?.balloon?.color_scheme) {
          this.updateSettings();
        }
      },
      drawBalloonArrows: function (newValue) {
        if (newValue != this.drawingSettings?.balloon?.draw_arrows) {
          this.updateSettings();
        }
      },
      textScale: function (newValue) {
        if (newValue != this.drawingSettings?.balloon?.text_scale) {
          this.updateSettings();
        }
      },
      mergeRadius: function (newValue) {
        if (newValue != this.drawingSettings?.balloon?.merge_radius) {
          this.updateSettings();
        }
      },
      annotationChoices: function (newValue) {
        if (_.difference(newValue, this.drawingSettings?.balloon?.show_types) > 0) {
          this.updateSettings();
          this.updateFilter();
        }
      },
      'drawingSettings.balloon.text_scale': function(val) {
        this.textScale = val;
      },
      'drawingSettings.balloon.color_scheme': function(val) {
        this.selectedBalloonColor = val;
      },
      'drawingSettings.balloon.draw_arrows': function(val) {
        this.drawBalloonArrows = val;
      },
      'drawingSettings.balloon.merge_radius': function(val) {
        this.mergeRadius = val;
      },
      'drawingSettings.show_types': function(val) {
        this.annotationChoices = val;
      },
    },
    computed: {
      saveSettingsSucceeded: function() {
        return this.saveSettingsResult === true;
      },
      saveSettingsFailed: function() {
        return this.saveSettingsResult === false;
      },
      mergeRadiusDisplay: function() {
        return this.mergeRadius.toFixed(1);
      },
    },
    mounted() {
      this.updateFilter();
    },
    methods: {
      onTextScaleChanged: function (evt) {
        this.textScale = evt.target.valueAsNumber;
      },
      onMergeRadiusChanged: function (evt) {
        this.mergeRadius = evt.target.valueAsNumber;
      },
      updateFilter: function () {
        let vm = this;

        let annotationTypePredicate = (annotation) => {
          return _.includes(vm.annotationChoices,annotation.annotation_type);
        }
        this.$emit('update:filter', annotationTypePredicate)
      },
      updateSettings: function() {
        this.$emit("updateSettings", this.composeNewSettings());
      },
      handleSaveSettings: function() {
        this.$emit("saveSettings", this.composeNewSettings());
      },
      composeNewSettings: function() {
        let clone = _.cloneDeep(this.drawingSettings);
        if (!clone) clone = {};
        if (!clone.balloon) clone.balloon = {};
        clone.balloon.text_scale = this.textScale;
        clone.balloon.draw_arrows = this.drawBalloonArrows;
        clone.balloon.merge_radius = this.mergeRadius;
        clone.balloon.color_scheme = this.selectedBalloonColor;
        clone.show_types = this.annotationChoices;
        return clone;
      }
    }
  }
</script>

<style scoped lang="scss">
  .choice-option-list {
    list-style-type: none;
    margin-left: 0px;
    margin-bottom: 0px;
    label {
      display: inline-block;
    }
  }
  .choice-checkbox {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .filter-container {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: -6px;
    margin-right: -6px;
    padding:6px;
    background-color:#eee;
  }
  .alert-small {
    margin-bottom: 0px;
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .stretch-width {
    width: stretch;    
  }
  .slider {
    display: flex;
    align-items: flex-start;
    column-gap: 4px;
  }

</style>