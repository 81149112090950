import * as base from "./shape"

export const Rectangle = (annotation) => {
  var shape = base.Shape(annotation);
  var utils = base.ShapeUtils();

  var annotation_data = annotation.annotation_data;

  var start = { x: 0, y: 0, data: annotation_data.origin };
  var end = { x: 0, y: 0, data: annotation_data.end_point };

  var corners;
  var length;
  var width;

  var vector;

  // // for tracking changes in canvas size in order to calculate the width correctly
  // var canvasWidth = 0;
  // var canvasHeight = 0;

  var line_width;

  var hot_spot_radius;

  function init(layoutParams) {
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    start.x = annotation_data.origin.x * overallSize.width - offset.x;
    start.y = annotation_data.origin.y * overallSize.height - offset.y;
    end.x = annotation_data.end_point.x * overallSize.width - offset.x;
    end.y = annotation_data.end_point.y * overallSize.height - offset.y;

    line_width = utils.strokeWidth(annotation_data.stroke_scale, overallSize.height);

    let dx = (end.x - start.x);
    let dy = (end.y - start.y);
    length = Math.sqrt(dx ** 2 + dy ** 2);

    // this makes the assumption that we don't zoom and move before redrawing
    // it assumes we are doing one or the other
    // if (!width || overallSize.width != canvasWidth || overallSize.height != canvasHeight) {
      width = length / annotation_data.aspect_ratio;
  // } else {
  //       updateAspectRatio();
  //   }
    // canvasWidth = overallSize.width;
    // canvasHeight = overallSize.height;

    corners = [];

    let dxw = dy / annotation_data.aspect_ratio;
    let dyw = dx / annotation_data.aspect_ratio;

    corners.push(start);
    corners.push(end);
    corners.push({x: end.x + dxw, y: end.y - dyw});
    corners.push({x: start.x + dxw, y: start.y - dyw});
  }

  function updateAspectRatio() {
    annotation_data.aspect_ratio = length / width;
  }

  function draw(ctx, layoutParams, selectedIndices) {
    init(layoutParams);

    ctx.setGlobalAlpha(utils.strokeTransparency(annotation_data.stroke_opacity));
    ctx.setStrokeColor(annotation_data.stroke_color);
    ctx.setStrokeWidth(line_width);
    ctx.setLineJoin('round')
    ctx.setLineCap('round')

    ctx.beginPath();

    makePath(ctx, corners);
    ctx.stroke();

    if (annotation_data.fill_opacity) {
      let origin = corners[0];
      let end = corners[1];
      let dx = end.x - origin.x;
      let dy = end.y - origin.y;
      let l = Math.sqrt(dx * dx + dy * dy);
      let ratio = (line_width / 2) / l;

      let ddx = dx * ratio;
      let ddy = dy * ratio;

      let fillCorners = [];
      fillCorners.push({x: corners[0].x + ddx + ddy, y: corners[0].y - ddx + ddy});
      fillCorners.push({x: corners[1].x - ddx + ddy, y: corners[1].y - ddx - ddy});
      fillCorners.push({x: corners[2].x - ddx - ddy, y: corners[2].y + ddx - ddy});
      fillCorners.push({x: corners[3].x + ddx - ddy, y: corners[3].y + ddx + ddy});

      ctx.setGlobalAlpha(utils.strokeTransparency(annotation_data.fill_opacity));
      ctx.setFillColor(annotation_data.fill_color ? annotation_data.fill_color : annotation_data.stroke_color);
      ctx.beginPath();
      makePath(ctx, fillCorners);
      ctx.fill();
    }
    
    hot_spot_radius = line_width / 2;
    if (selectedIndices.length > 0) {
      hot_spot_radius = utils.hotSpotRadius(layoutParams.overallSize().height);

      let RED = "#FF0000";
      let WHITE = "#FFFFFF";

      if (_.includes(selectedIndices, 0)) {
        if (_.includes(selectedIndices, 3)) {
          utils.highlightPoint(ctx, corners[0], RED, hot_spot_radius);
          utils.drawAnchor(ctx, corners[2], WHITE, hot_spot_radius);
        } else {
          utils.highlightVectorPoint(ctx, corners[0], hot_spot_radius);
          if (!_.includes(selectedIndices, 1)) {
            utils.drawVectorAnchor(ctx, corners[1], hot_spot_radius);
          }
        }
      }
      if (_.includes(selectedIndices, 1)) {
        if (_.includes(selectedIndices, 2)) {
          utils.highlightPoint(ctx, corners[1], RED, hot_spot_radius);
          utils.drawAnchor(ctx, corners[3], WHITE, hot_spot_radius);
        } else {
          utils.highlightVectorPoint(ctx, corners[1], hot_spot_radius);
          if (!_.includes(selectedIndices, 0)) {
            utils.drawVectorAnchor(ctx, corners[0], hot_spot_radius);
          }
        }
      }
      if (_.includes(selectedIndices, 2)) {
        utils.highlightPoint(ctx, corners[2], RED, hot_spot_radius);
        utils.drawVectorAnchor(ctx, corners[0], hot_spot_radius);
      }
      if (_.includes(selectedIndices, 3)) {
        utils.highlightPoint(ctx, corners[3], RED, hot_spot_radius);
        utils.drawVectorAnchor(ctx, corners[1], hot_spot_radius);
      }
    }
    
    function makePath(ctx, vertexes) {
      ctx.moveTo(vertexes[0].x, vertexes[0].y);
      _.forEach(_.tail(vertexes), function(point) {
        ctx.lineTo(point.x, point.y);
      });
      ctx.lineTo(vertexes[0].x, vertexes[0].y);
    }
  }

  function findPoints(x, y) {
    let selected = [];
    if (!corners || corners.length == 0 || !line_width) return selected;

    if (utils.isOnHotSpot(x, y, corners[0], hot_spot_radius)) {
      selected.push(0);
      vector = null; // reset 'cos next time we need this we'll need to recalculate it
    } else if (utils.isOnHotSpot(x, y, corners[1], hot_spot_radius)) {
      selected.push(1);
      vector = null; // reset 'cos next time we need this we'll need to recalculate it
    } else if (utils.isOnHotSpot(x, y, corners[2], hot_spot_radius)) {
      selected.push(2);
    } else if (utils.isOnHotSpot(x, y, corners[3], hot_spot_radius)) {
      selected.push(3);
    } else if (utils.isOnLine(x, y, corners[2], corners[3], line_width)) {
      selected.push(2);
      selected.push(3);
    } else if (utils.isOnLine(x, y, corners[1], corners[2], line_width)) {
      selected.push(1);
      selected.push(2);
    } else if (utils.isOnLine(x, y, corners[0], corners[3], line_width)) {
      selected.push(0);
      selected.push(3);
    } else if (isHere(x, y)) {
      selected.push(0);
      selected.push(1);
    }
    return selected;

    function isHere(x, y) {
      if (annotation_data.fill_opacity && annotation_data.fill_opacity > 0
          && utils.isBetweenPoints(x, y, corners[0], corners[1])
          && utils.isBetweenPoints(x, y, corners[1], corners[2])) {
            return true;
      }
      for (let index = 0; index < corners.length - 1; index++) {
        if (utils.isOnPoint(x, y, corners[index], line_width)
          || utils.isOnLine(x, y, corners[index], corners[index + 1], line_width)) {
          return true;
        }
      }
      return utils.isOnPoint(x, y, corners[corners.length - 1], line_width)
        || utils.isOnLine(x, y, corners[0], corners[corners.length - 1], line_width);
    };
  }

  function move(x, y, layoutParams, selectedIndices) {
    if (_.includes(selectedIndices, 2) && _.includes(selectedIndices, 3)) {
      let resizeOk = canResize(x, y, [2, 3]);
      if (resizeOk) {
        changeWidth(x, y);
      }
      return {x_ok: resizeOk, y_ok: resizeOk};
    } else if (_.includes(selectedIndices, 1) && _.includes(selectedIndices, 2) || _.includes(selectedIndices, 0) && _.includes(selectedIndices, 3)) {
      let resizeOk = canResize(x, y, _.includes(selectedIndices, 1) ? [1, 2] : [0, 3]);
      if (resizeOk) {
        changeLength(x, y);
      }
      return {x_ok: resizeOk, y_ok: resizeOk};
    } else if (_.includes(selectedIndices, 2) || _.includes(selectedIndices, 3)) {
      let resizeOk = canResize(x, y, _.includes(selectedIndices, 2) ? [1, 2, 3] : [0, 2, 3]);
      if (resizeOk) {
        moveCorner(x, y);
      }
      return {x_ok: resizeOk, y_ok: resizeOk};
    }
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    let result = utils.move(x + offset.x, y + offset.y, utils.vectorIndiciesToPoints(selectedIndices, start, end), overallSize);
    return result;

    function moveCorner(x, y) {
      changeLength(x, y);
      changeWidth(x, y);
    };

    function changeWidth(x, y) {
      let origin = corners[0];
      let widthPoint = corners[3];

      let dx = origin.x - widthPoint.x - x;
      let dy = origin.y - widthPoint.y - y;
      width = Math.sqrt(dx * dx + dy * dy);

      updateAspectRatio();
    };

    function changeLength(x, y) {
      let endSelected = _.includes(selectedIndices, 2);
      let origin = endSelected ? corners[0] : corners[1];
      let widthPoint = endSelected ? corners[1] : corners[0];

      let dxm = origin.x - widthPoint.x - x;
      let dym = origin.y - widthPoint.y - y;
      let l = Math.max(2, Math.sqrt(dxm **2 + dym **2));
      length = l;

      if (!vector) {
        vector = { x: corners[0].x - corners[1].x, y: corners[0].y - corners[1].y };
      }

      let vectorLength = Math.sqrt(vector.x **2 + vector.y **2)
      let dx = vector.x * (length / vectorLength);
      let dy = vector.y * (length / vectorLength);

      if (endSelected) {
          end.x = corners[0].x - dx;
          end.y = corners[0].y - dy;
      } else {
          start.x = corners[1].x + dx;
          start.y = corners[1].y + dy;
      }

      updateAspectRatio();

      let drawingOffset = layoutParams.viewOffset();
      let overallSize = layoutParams.overallSize();

      annotation_data.origin.x = ((start.x + drawingOffset.x) / overallSize.width).toFixed(8);
      annotation_data.origin.y = ((start.y + drawingOffset.y) / overallSize.height).toFixed(8);
      annotation_data.end_point.x = ((end.x + drawingOffset.x) / overallSize.width).toFixed(8);
      annotation_data.end_point.y = ((end.y + drawingOffset.y) / overallSize.height).toFixed(8);
    };

    function canResize(x, y, selectedIndices) {
      let selectedPoints = utils.vectorIndiciesToPoints(selectedIndices, start, end);
      if (_.includes(selectedIndices, 2)) {
        selectedPoints.push(corners[2]);
      }
      if (_.includes(selectedIndices, 3)) {
        selectedPoints.push(corners[3]);
      }
      let resizeOk = true;
      let last = selectedPoints.length;
      let overallSize = layoutParams.overallSize();
      for (let index = 0; index < last; index++) {
        let point = selectedPoints[index];
        let new_x = point.x + x;
        let new_y = point.y + y;
        if (new_x < 0 || new_x > overallSize.width) {
          resizeOk = false;
        }
        if (new_y < 0 || new_y > overallSize.height) {
          resizeOk = false;
        }
      }
      return resizeOk;
    }
  }
  function allSelectablePoints() {
    return [0, 1];
  }




  var foundPoints = [];
  var selectedPoints = [];
  var currentX = -1;
  var currentY = -1
    
  shape.draw = function(context, layoutParams, drawingOptions, highlight) {
    const ctx = base.DrawingContext(context);
    let points = !highlight 
        ? []
        : shape.isSelected() ? selectedPoints
        : allSelectablePoints();
    draw (ctx, layoutParams, points);
  }
  shape.selectHere = function(x, y) {
    if (currentX != x || currentY != y) {
      shape.isHere(x, y);
    }
    selectedPoints = foundPoints;  
  }
  shape.selectAll = function() {
    selectedPoints = allSelectablePoints();
  }
  shape.unselect = function() {
    selectedPoints = [];
  }
  shape.isSelected = function() {
    return selectedPoints.length > 0;
  }

  shape.isHere = function(x, y) {
    currentX = x;
    currentY = y;
    foundPoints = findPoints(x, y);
    return foundPoints && foundPoints.length > 0;
  }

  shape.move = function(x, y, layoutParams, moveOptions) {
    return move(x, y, layoutParams, selectedPoints, moveOptions);
  }

  return shape;
}