<template>
  <div class="drawing-addnew">
    <div class="widget-box">
      <div class="widget-title">
        <h5>Add to Drawing</h5>
        <i v-bind:class="'material-icons mode-style ' + modeStyle" v-bind:title="modeText" @click="toggleMode" :disabled="!canAddNew">power_settings_new</i>
      </div>
      <transition name="slide-fade">
        <div class="widget-content" v-show="addingMode">
          <div v-for="(field, index) in fields">
            <div v-if="isText(field)">
              <label v-bind:for="field['reference']">{{ field['label'] }}<span v-if="fieldRequired(field)">*</span></label>
              <input v-model="fields[index]['value']" type="text" v-bind:list="field['list_name']" v-bind:id="field['reference']" v-bind:name="field['reference']" class="form-field" autocomplete="off">
              <datalist v-if="fieldHasList(field)" v-bind:id="field['list_name']">
                <option :value="option[1]" v-for="option in field['options']" />
              </datalist>
            </div>
            <div v-if="isSelection(field) && fieldHasOptions(field)">
              <label v-bind:for="field['reference']">{{ field['label'] }}<span v-if="fieldRequired(field)">*</span></label>
              <select v-model="fields[index]['value']" v-bind:id="field['reference']" v-bind:name="field['reference']" class="drawing-select">
                <option :value="option[0]" v-for="option in field['options']" >{{ option[1] }}</option>
              </select>
            </div>
          </div>
          <div class="add-mode-help" v-show="!allFieldsFilledIn">Fill in the fields above</div>
          <div class="add-mode-help" v-show="allFieldsFilledIn">Click on drawing then save</div>
          <button :disabled="!canSave" @click.prevent="createInspection" v-bind:class="saveStyle + ' btn-small save-button'">Save Inspection</button>
          <button @click.prevent="resetFormAndValues" class="btn-warning btn-small save-button" title="clear fields in the form above">Clear</button>
          <div v-show="showWarning"><br><div class="alert alert-error alert-small">Not Saved</div></div><div v-show="showSuccess"><br><div class="alert alert-success alert-small">Saved</div></div>
          <div v-show="haveDrafts">
            <hr>
            <h5 title="Click to add above">Draft inspections to add</h5>
            <div class="balloon-list">
              <div class="balloon-text" v-for="insp in draftInspections" v-bind:id="insp.uuid" v-on:click="addToForm(insp.uuid)">
                <i class="material-icons annotation-unstyled annotation-draft">lens</i>
                <span>
                  {{ insp.name }}
                </span>
                <div class="buttons btn-group link-out">
                  <a v-bind:href="inspectionUrl(insp.id)" class="btn btn-primary btn-mini link-out" v-bind:target="insp.id" title="Click to open inspection">
                    <i class="icon-share icon-white"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import generateUUID from 'uuid/v4'

  const FIELDTYPES = ['text','selection']

  export default {
    components: {
    },
    props: {
      canAddNew: Boolean,
      solution: Object,
      addMode: false,
      newPhotoField: Object,
      saveInProgress: Boolean,
      saveInspResult: String,
      addInspections: Array,
      currentSolutionKey: String
    },
    data: function () {
      let initialFields = this.validFields();
      let initialID = this.solution['id'];
      let initialSaving = this.saveInProgress;
      let initialMode = this.addMode && this.canAddNew;
      let initialInspections = this.addInspections;
      return {
        fields: initialFields,
        templateID: initialID,
        saving: initialSaving,
        addingMode: initialMode,
        nextAnnotationAssetUUID: null,
        nextAnnotationUUID: null,
        nextInspectionUUID: null,
        draftInspections: initialInspections,
        draftInspection: null,
        addingDraft: false
      };
    },
    watch: {
      saveInProgress: function (newValue) {
        if (!newValue && this.saveSuccess) {
          this.resetFormAndValues();
        }
        this.saving = newValue;
      },
      balloonText: function (newValue) {
        this.$emit('update:newBalloonText', newValue);
      },
      addInspections: function (newValue) {
        this.draftInspections = this.addInspections;
      },
      currentSolutionKey: function (newValue) {
        this.fields = _.cloneDeep(this.validFields());
        this.templateID = this.solution['id'];
      }
    },
    computed: {
      // fields: function () {
      //   return this.validFields();
      // },
      haveDrafts: function () {
        return !_.isEmpty(this.addInspections);
      },
      havePhoto: function () {
        return !_.isEmpty(this.newPhotoField);
      },
      saveSuccess: function () {
        return (this.saveInspResult != "Failed") ;
      },
      showWarning: function () {
        return (this.saveInspResult == "Failed") ;
      },
      showSuccess: function () {
        return (this.saveInspResult == "Success") ;
      },
      allFieldsFilledIn: function () {
        return _.every(this.fields, function (field) { 
          return ((field['required'] || ((field['minimum_selections'] > 0 ) && (field['options'].length > 0) )) ? !_.isEmpty(field['value']) : true )
        });
      },
      canSave: function () {
        return this.allFieldsFilledIn && this.havePhoto && this.saveNotInProgress && (!_.isEmpty(this.validFields()));
      },
      saveNotInProgress: function () {
        return !this.saving;
      },
      saveStyle: function () {
        switch (this.canSave) {
          case true: return 'btn-primary btn-small save-button';
          default: return 'btn btn-small save-button';
        }
      },
      modeStyle: function () {
        switch (this.addingMode) {
          case true: return 'mode-active btn btn-success btn-mini';
          default: return 'mode-inactive btn btn-primary btn-mini';
        }
      },
      modeText: function () {
        let result = "";
        if (this.canAddNew) {
          result = this.addingMode ? "Click to exit Add Mode" : "Click to enter Add Mode";
        } else {
          result = "Nothing can be added.";
        }
        return result;
      },
      balloonText: function () {
        return (_.isEmpty(this.fields) || _.isEmpty(this.fields[0]['value'])) ? '' : this.fields[0]['value'];
      }
    },
    mounted() {
    },
    methods: {
      addToForm(uuid) {
        // Add values into fields where the reference matches
        let vm = this;
        vm.draftInspection = _.find(vm.draftInspections, function (thisInspection) {
          return thisInspection.uuid == uuid;
        });
        if (!_.isNil(vm.draftInspection)) { 
          let localFields = _.cloneDeep(vm.fields);
          let testValue = null;
          _.forEach(localFields, function (field) {
            testValue = null;
            if (!_.isNil(vm.draftInspection['data'][field.reference])) {
              switch (field.type) {
                case "text":
                  field.value = vm.draftInspection['data'][field.reference]['value'];
                  break;
                case "selection":
                  testValue = vm.draftInspection['data'][field.reference];
                  if (!_.isNil(testValue['selections']) && !_.isNil(testValue['selections'][0])) {
                    field.value = testValue['selections'][0]['key'];
                  } else {
                    field.value = null;
                  }
                  break;
              }
            } else {
              field.value = null;
            }
          })
          vm.addingDraft = true;
          vm.fields = _.cloneDeep(localFields);
        }
      },
      toggleMode: function () {
        this.addingMode = !this.addingMode && this.canAddNew;
        if (this.addingMode) {
          this.resetFormAndValues()
        } else {
          this.resetForm();
        }
        this.$emit('update:modeChange', {addingMode: this.addingMode})
      },
      fieldHasList: function (field) {
        return (!_.isNull(field['list_name']));
      },
      fieldRequired: function (field) {
        return (field['required'] || field['minimum_selections'] > 0 );
      },
      resetFormAndValues: function () {
        this.nextInspectionUUID = generateUUID();
        this.nextAnnotationAssetUUID = generateUUID();
        this.nextAnnotationUUID = generateUUID();
        this.addingDraft = false;
        this.$emit('update:nextUUIDs', {annotationAssetId: this.nextAnnotationAssetUUID, annotationId: this.nextAnnotationUUID});
        this.resetForm()
      },
      resetForm: function () {
        let localFields = _.cloneDeep(this.fields);
        _.forEach(localFields, function (field) {
          delete field['value'];
        });
        this.fields = _.cloneDeep(localFields)
      },
      validFields: function () {
        return _.filter(this.solution['fields'], function(field) {
          return (_.includes(FIELDTYPES, field['type']));
        });
      },
      isText: function (field) {
        return field['type']=='text';
      },
      isSelection: function (field) {
        return field['type']=='selection';
      },
      fieldHasOptions: function (field) {
        return !_.isEmpty(field['options']);
      },
      createInspection: function () {
        let vm = this;

        let dataSection = {};
        let inspectionData = {};

        _.forEach(vm.fields, function (field) {
          if (!_.isNil(field['value'])) {
            if (field.type == "text") {
              dataSection[field['reference']] = { 
                "type": "text", "value": field['value']
              }
            }
            if (field.type == "selection") {
              dataSection[field['reference']] = { 
                "type": "selection", "selections": [
                  {"key": field['value'], "value": vm.valueForKey(field['options'],field['value'])}
                ]
              }
            }
          }
        })

        inspectionData = {"assessment_template_id": this.templateID, "data": dataSection}

        let dataToPost = {
          uuid: this.addingDraft ? this.draftInspection.uuid : this.nextInspectionUUID,
          inspection: inspectionData
        }
        this.$emit('update:saveData', dataToPost);
      },
      valueForKey: function (checkArray, key) {
        let result = _.find(checkArray, function (element) {
          return element[0] == key;
        });
        return result[1];
      },
      inspectionUrl: function (inspection_id) {
        return "/o/" + this.organisationId() + "/inspections/" + inspection_id
      },
      organisationId: function () {
        const organisationIdRegex = /^\/o\/(\d+)\//g;
        let matches = organisationIdRegex.exec(window.location.pathname)

        if (matches) {
          return matches[1]
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .choice-option-list {
    list-style-type: none;
  }
  .choice-option-list label {
    display: inline-block;
  }
  .choice-checkbox {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .form-field {
    width: -webkit-fill-available;
  }
  .simple-options label {
    display: inline-block;
  }
  .drawing-select {
    width: -webkit-fill-available;    
  }
  .mode-inactive {
    color: #FFFFFF;
    float: right;
    margin-top: 5px;
    margin-right: 5px;
  }
  .mode-active {
    color: #FFFFFF;
    float: right;    
    margin-top: 5px;
    margin-right: 5px;
  }
  .mode-style {
    font-size: 14px;
    font-weight: bold;
  }
  .add-mode-help{
    width: -webkit-fill-available;
    background-color: #ffff78;
  }
  .save-button {
    border-top-width: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom-width: 1px;
  }
  .alert-small {
    margin-bottom: 0px;
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .balloon-text {
    cursor: pointer;
  }
  .balloon-text .link-out {
    float: right;
    border-top-width: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom-width: 0px;
  }
  .annotation-unstyled {
    font-size: unset;
  }
  .annotation-draft {
    color: #b9b8b8;
  }
  .balloon-list {
    max-height: 400px;
    overflow-y: scroll;
  }
  div.balloon-list>div:nth-of-type(odd) {
    background: #e0e0e0;
  }
  .slide-fade-enter-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
  }
</style>