
import _ from 'lodash'
import generateUUID from 'uuid/v4'

export const NewInstance = (values) => {
  return {
    id: generateUUID(),
    type: 'feature_instance',
    data: values,
  };
}
