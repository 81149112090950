<template>
  <div class="mapboxgl-ctrl-group mapboxgl-ctrl">
    <button class="mapboxgl-ctrl-zoom-in" v-on:click="zoomIn" type="button" title="Zoom in" aria-label="Zoom in" aria-disabled="false">
      <span class="mapboxgl-ctrl-icon" aria-hidden="true"></span>
    </button>
    <button class="mapboxgl-ctrl-zoom-out" v-on:click="zoomOut" type="button" title="Zoom out" aria-label="Zoom out" aria-disabled="false">
      <span class="mapboxgl-ctrl-icon" aria-hidden="true"></span>
    </button>
    <button class="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_combine" v-on:click="selectInPolygon" title="Select in polygon"></button>
    <button class="mapboxgl-ctrl-shrink" v-on:click="zoomToVisible" title="Zoom to visible">
      <span class="mapboxgl-ctrl-icon" aria-hidden="true"></span>
    </button>
  </div>
</template>

<script>
  export default {
    props: ['map', 'control'],
    methods: {
      zoomIn: function () {
        this.$emit('zoomIn')
        this.map.zoomIn()
      },
      zoomOut: function () {
        this.$emit('zoomOut')
        this.map.zoomOut()
      },
      selectInPolygon: function () {
        this.$emit('selectInPolygon')
      },
      zoomToVisible: function () {
        this.$emit('zoomToVisible')
      }
    }
  }
</script>

<style scoped lang="scss">
</style>