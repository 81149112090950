<template>
  <div class="inclusion-section" v-if="inclusionsAvailable">

    <div class="widget-box nopadding" id="accordion">
      <div class="widget-title">
        <h5>
          <span class="accordion-button" data-toggle="collapse" data-target="#inclusion_files" aria-expanded="true" aria-controls="inclusion_files" title="Click to show / hide section">
            <i class="caret accordion-caret"></i>Inclusions
          </span>
        </h5>
      </div>
      <div class="widget-content">
        <div v-if="hasInclusions || !readOnly" class="show in collapse" id="inclusion_files">
          <inclusion-row
          :data="inclusions[inclusion.reference]"
          :schema="inclusion"
          @update:data="val => rowUpdate(inclusion, val)"
          @clear:data="() => rowRemoval(inclusion)"
          :read-only="readOnly"
          v-for="inclusion in inclusionsSchema" :key="inclusion.reference">
          </inclusion-row>
        </div>
        <div v-else class="show in collapse" id="inclusion_files">
          No inclusions currently added.
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import _ from 'lodash'
  import InclusionRow from './inclusion_row.vue'

  export default {
    components: {
      InclusionRow
    },
    props: {
      inclusionsSchema: Array,
      inclusions: Object,
      readOnly: Boolean
    },
    computed: {
      inclusionsAvailable: function () {
        return !!this.inclusionsSchema && (this.inclusionsSchema.length > 0) 
      },
      hasInclusions: function () {
        return !_.isEmpty(this.inclusions);
      }
    },
    methods: {
      rowUpdate: function (inclusion, value) {
        let reference = inclusion.reference
        let inclusions = _.cloneDeep(this.inclusions)
        inclusions[reference] = value

        this.$emit('update:inclusions', inclusions)
      },
      rowRemoval: function (inclusion) {
        let reference = inclusion.reference
        let inclusions = _.cloneDeep(this.inclusions)
        delete inclusions[reference]

        this.$emit('update:inclusions', inclusions)
      }
    }
  }
</script>

<style scoped lang="scss">
.divider {
  width: 620px;
}

.inclusion-section {
  width: 620px;
}
</style>