import _ from 'lodash'

export default {
  props: {
    underRollup: String,
    groupVisible: Boolean,
    groupState: String,
    controlEvent: String,
    controlState: String,
    parentReference: String
  },
  data: function () {
    let initialVisibility = this.groupVisible;
    let initialControlHide = this.controlState;
    let initialGroupState = this.groupState;
    return {
      visibleUnderRollup: initialVisibility,
      controlShowHide: initialControlHide,
      groupShowHide: initialGroupState
    }
  },
  computed: {
    defaultHidden: function () {
      return !!this.specification.hidden;
    },
    groupControlled: function () {
      return !!this.underRollup;
    },
    controlledField: function () {
      return !!this.controlEvent;
    },
    isVisible: function () {
      // This field is displayed to the user if it is not hidden or covered up (roll up)
      return !this.isHidden && this.visibleUnderRollup;
    },
    isTableCellVisible: function () {
      // This field is displayed to the user if it is not hidden
      return !this.isHidden;
    },
    isHidden: function () {
      // This field is displayed to the user if it is not hidden or covered up (roll up)
      let result = false;
      if (!!this.groupShowHide) {
        result = (this.groupShowHide == 'hide') ;
      } ;
      if (!!this.controlShowHide && !result) {
        // If not group hiding, but have specific show hide
        result = (this.controlShowHide == 'hide') ;
      } ;
      if (this.defaultHidden && !(this.controlShowHide == 'show')) {
        // if not hidden by now apply the default
        result = this.defaultHidden ;
      } ;
      return result;
    }
  },
  methods: {
    guidanceFieldReference: function () {
      let vm = this;
      let result = ''
      if (!_.isEmpty(vm.underRollup)) {
        result = _.last(_.split(vm.underRollup,'-'));
      }
      return result;
    }
  }
}