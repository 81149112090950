import * as base from "./shape"
import _ from 'lodash'

export const Square = (annotation) => {
  var shape = base.Shape(annotation);
  var utils = base.ShapeUtils();

  var annotation_data = annotation.annotation_data;

  var start = { x: 0, y: 0, data: annotation_data.origin };
  var end = { x: 0, y: 0, data: annotation_data.end_point };

  var corners;
  var line_width;

  var hot_spot_radius;

  function init(layoutParams) {
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    start.x = annotation_data.origin.x * overallSize.width - offset.x;
    start.y = annotation_data.origin.y * overallSize.height - offset.y;
    end.x = annotation_data.end_point.x * overallSize.width - offset.x;
    end.y = annotation_data.end_point.y * overallSize.height - offset.y;

    line_width = utils.strokeWidth(annotation_data.stroke_scale, overallSize.height);

    corners = calculateCorners(start, end);
  }

  function calculateCorners(startPoint, endPoint) {
    let cornersOut = [];

    let dx = endPoint.x - startPoint.x;
    let dy = endPoint.y - startPoint.y;

    let t_x = startPoint.x - (dy / 2);
    let t_y = startPoint.y + (dx / 2);
    cornersOut.push({x: t_x, y: t_y});

    let p_x = t_x;
    let p_y = t_y;

    t_x = p_x + dx;
    t_y = p_y + dy;
    cornersOut.push({x: t_x, y: t_y});

    p_x = t_x;
    p_y = t_y;

    t_x = p_x + dy;
    t_y = p_y - dx;
    cornersOut.push({x: t_x, y: t_y});

    p_x = t_x;
    p_y = t_y;

    t_x = p_x - dx;
    t_y = p_y - dy;
    cornersOut.push({x: t_x, y: t_y});

    return cornersOut;
  }

  function draw(ctx, layoutParams, selectedIndices) {
    init(layoutParams);

    ctx.setGlobalAlpha(utils.strokeTransparency(annotation_data.stroke_opacity));
    ctx.setStrokeColor(annotation_data.stroke_color);
    ctx.setStrokeWidth(line_width);
    ctx.setLineJoin('round')
    ctx.setLineCap('round')

    ctx.beginPath();

    makePath(ctx, corners);
    ctx.stroke();

    if (annotation_data.fill_opacity) {
      let dx = end.x - start.x;
      let dy = end.y - start.y;
      let width = Math.sqrt(dx*dx + dy*dy);
      let ratio = (line_width / 2) / width;
      let ddx = dx * ratio;
      let ddy = dy * ratio;
      let fillStart = {x: start.x + ddx, y: start.y + ddy};
      let fillEnd = {x: end.x - ddx, y: end.y - ddy};
      let fillCorners = calculateCorners(fillStart, fillEnd);
      ctx.setGlobalAlpha(utils.strokeTransparency(annotation_data.fill_opacity));
      ctx.setFillColor(annotation_data.fill_color ? annotation_data.fill_color : annotation_data.stroke_color);
      ctx.beginPath();
      makePath(ctx, fillCorners);
      ctx.fill();
    }
    
    hot_spot_radius = line_width / 2;
    if (selectedIndices.length > 0) {
      hot_spot_radius = utils.hotSpotRadius(layoutParams.overallSize().height);
      utils.highlightPoints(ctx, utils.vectorIndiciesToPoints(selectedIndices, start, end), hot_spot_radius);
      utils.drawAnchors(ctx, utils.vectorAnchorsFromSelectedIndicies(selectedIndices, start, end), hot_spot_radius);
    }        
  }

  function makePath(ctx, vertexes) {
    ctx.moveTo(vertexes[0].x, vertexes[0].y);
    _.forEach(_.tail(vertexes), function(point) {
      ctx.lineTo(point.x, point.y);
    });
    ctx.lineTo(vertexes[0].x, vertexes[0].y);
  }

  function findPoints(x, y) {
    let selected = [];
    if (!corners || corners.length == 0 || !line_width) return selected;

    if (utils.isOnHotSpot(x, y, start, hot_spot_radius)) {
      selected.push(0);
    } else if (utils.isOnHotSpot(x, y, end, hot_spot_radius)) {
      selected.push(1);
    }
    else if (isHere(x, y)) {
      selected.push(0);
      selected.push(1);
    }
    return selected;

    function isHere(x, y) {
      if (annotation_data.fill_opacity && annotation_data.fill_opacity > 0
          && utils.isBetweenPoints(x, y, corners[0], corners[1])
          && utils.isBetweenPoints(x, y, corners[1], corners[2])) {
            return true;
      }
      for (let index = 0; index < corners.length - 1; index++) {
        if (utils.isOnPoint(x, y, corners[index], line_width)
          || utils.isOnLine(x, y, corners[index], corners[index + 1], line_width)) {
          return true;
        }
      }
      return utils.isOnPoint(x, y, corners[corners.length - 1], line_width)
        || utils.isOnLine(x, y, corners[0], corners[corners.length - 1], line_width);
    };
  }
  function allSelectablePoints() {
    return [0, 1];
  }

  


  var foundPoints = [];
  var selectedPoints = [];
  var currentX = -1;
  var currentY = -1
    
  shape.draw = function(context, layoutParams, drawingOptions, highlight) {
    const ctx = base.DrawingContext(context);
    let points = !highlight 
        ? []
        : shape.isSelected() ? selectedPoints
        : allSelectablePoints();
    draw (ctx, layoutParams, points);
  }
  shape.selectHere = function(x, y) {
    if (currentX != x || currentY != y) {
      shape.isHere(x, y);
    }
    selectedPoints = foundPoints;  
  }
  shape.selectAll = function() {
    selectedPoints = allSelectablePoints();
  }
  shape.unselect = function() {
    selectedPoints = [];
  }
  shape.isSelected = function() {
    return selectedPoints.length > 0;
  }

  shape.isHere = function(x, y) {
    currentX = x;
    currentY = y;
    foundPoints = findPoints(x, y);
    return foundPoints && foundPoints.length > 0;
  }

  shape.move = function(x, y, layoutParams) {
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    return utils.move(x + offset.x, y + offset.y, utils.vectorIndiciesToPoints(selectedPoints, start, end), overallSize);
  }

  return shape;
}
