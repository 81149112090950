<template>
  <div class="balloon-list-container">
    <select v-model="findBalloonOrigin" class ="stretch-width">
      <option v-bind:value="true">Find balloon point</option>
      <option v-bind:value="false">Find balloon text</option>
    </select>
    <input type="text" class ="stretch-width" placeholder="Search by balloon text" v-model="balloonText">
    <div id="balloon-list">
      <div class="balloon-text" v-show="noTextBalloons">
        <span>There are no text-balloons to show</span>
      </div>
      <div class="balloon-text" v-show="noMatches">
        <span>No matching text-balloons to show</span>
      </div>
      <div class="balloon-list-item balloon-text" :class="{current_annotation: currentSelectedAnnotation == anno.uuid}" v-for="anno in balloonList" v-bind:id="anno.uuid" :key="anno.uuid" v-on:click="highlight(anno.uuid)">
        <div>
          <i v-bind:class="'material-icons annotation-unstyled ' + stateClass(anno.inspection_state)" v-bind:title="anno.inspection_state">{{ stateIcon(anno.inspection_state) }}</i>
          <span title="Click to toggle highlight on drawing">
            {{anno.annotation_data.full_text}}
          </span>
        </div>
        <div class="buttons btn-group link-out" @click.stop="selectedAnnotation = anno.uuid">
          <a v-bind:href="inspectionUrl(anno)" class="btn btn-primary btn-mini link-out" v-bind:target="anno.inspection_id" title="Click to open inspection">
            <i class="icon-share icon-white"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import _ from 'lodash'
  export default {
    components: {
    },
    props: {
      textBalloons: Array,
      currentSelectedAnnotation: String,
    },
    data: function () {
      // order of the merge is important if we are to have the values sorted as we want in the list
      let initialBalloons = this.textBalloons;
      let initialSelected = this.currentSelectedAnnotation;
      return {
        balloonList: initialBalloons,
        balloonText: "",
        selectedAnnotation: initialSelected,
        findBalloonOrigin: true,
      };
    },
    watch: {
      currentSelectedAnnotation: function (newValue) {
        if (newValue != "" && newValue != this.selectedAnnotation) {
          this.scrollTo(newValue);
        }
        this.selectedAnnotation = newValue;
      },
      selectedAnnotation: function (newValue) {
        this.$emit('update:balloonSelected', newValue)
      },
      findBalloonOrigin: function (newValue) {
        this.$emit('update:findOrigin', newValue)
      },
      textBalloons: function (newValue) {
        this.balloonList = this.filterBalloons();
      },
      balloonText: _.debounce(function (newValue) {
          let vm = this;
          vm.balloonList = this.filterBalloons();
      },150),
    },
    computed: {
      noTextBalloons: function () {
        return _.isEmpty(this.textBalloons);
      },
      noMatches: function () {
        return _.isEmpty(this.balloonList) && (!this.noTextBalloons);
      },
    },
    mounted() {
    },
    methods: {
      stateClass: function (state) {
        switch (state) {
          case 'active': return 'annotation-active';
          case 'completed':
          case 'reporting':
          case 'finished': return 'annotation-finished';
          default: return 'annotation-draft';
        }
      },
      stateIcon: function(state) {
        switch (state) {
          case 'active': return 'loop';
          case 'completed':
          case 'reporting':
          case 'finished': return 'check_circle';
          default: return 'lens';
        }
      },
      filterBalloons: function () {
        let vm = this;

        let matchValue = vm.balloonText.toLowerCase();

        let textPredicate = (balloon) => {
          return (balloon.annotation_data.full_text.toLowerCase().search(matchValue) != -1)
        }

        let predicate = _.overEvery(textPredicate)
        return _.filter(vm.textBalloons, predicate);
      },
      highlight: function (uuid) {
        if (this.selectedAnnotation == uuid) {
          this.selectedAnnotation = ""
        } else {
          this.selectedAnnotation = uuid;
        }
      },
      scrollTo: function (uuid) {
        var balloonListElement = document.getElementById(uuid);
        if (balloonListElement) {
          var topPos = balloonListElement.offsetTop - balloonListElement.parentNode.offsetTop;
          document.getElementById('balloon-list').scrollTo({top: topPos, behavior: 'smooth'});
        }
      },
      inspectionUrl: function (annotation) {
        let inspection_id = annotation.inspection_id;
        let linked_feature = annotation.linked_feature;
        let fragment = _.isEmpty(linked_feature) ? "" :  "#" + this.featureUUIDFromPath(linked_feature);
        return "/o/" + this.organisationId() + "/inspections/" + inspection_id + fragment;
      },
      organisationId: function () {
        const organisationIdRegex = /^\/o\/(\d+)\//g;
        let matches = organisationIdRegex.exec(window.location.pathname)

        if (matches) {
          return matches[1]
        }
      },
      featureUUIDFromPath(path) {
        let localPathSections = path.split('/');
        if (localPathSections.length > 1) {
          // trim to last piece
          localPathSections = localPathSections[localPathSections.length - 1].split(":");
        } else {
          localPathSections = localPathSections[0].split(":");
        }
        return localPathSections[1];
      },
    }
  }
</script>


<style scoped lang="scss">
  .balloon-list-container {
    padding-top: 12px;
  }
  .stretch-width {
    width: stretch;
  }
  #balloon-list {
    max-height: 400px;
    overflow-y: scroll;

    .balloon-list-item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      
      .link-out {
        float: right;
        border-top-width: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        border-bottom-width: 0px;
      }
      .annotation-unstyled {
        font-size: unset;
      }
      .annotation-finished {
        color: #4CAF50;
      }
      .annotation-draft {
        color: #b9b8b8;
      }
      .annotation-active {
        color: #4CAF50;
      }
    }
    .balloon-list-item:nth-of-type(odd) {
      background: #e0e0e0;
    }
    .current_annotation {
      background-color:#FFFF00 !important;
    }
  }

</style>