import _ from 'lodash'

const type = 'location';
const LocationFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  function parse(stringValue) {
    let output = null;
    let messages = [];
    if (!_.isEmpty(stringValue?.trim())) {
      let matches = stringValue?.match(/^\s*(-?\d+(?:\.\d+)?)\s*,\s*(-?\d+(?:\.\d+)?)\s*$/);
      if (_.isArray(matches)) {
        // assumes lat,long order
        let lat = parseFloat(matches[1]);
        let long = parseFloat(matches[2]);
        let latMessage = !_.isNumber(lat) ? "Latitude must be a decimal"
            : -90 > lat ? "Latitude must be larger than or equal to -90"
            : 90 < lat ? "Latitude must be smaller than or equal to 90"
            : null;
        let longMessage = !_.isNumber(long) ? "Longitude must be a decimal"
            : -180 > long ? "Longitude must be larger than or equal to -180"
            : 180 < long ? "Longitude must be smaller than or equal to 180"
            : null;
        if (latMessage) {
          messages.push(latMessage);
        }
        if (longMessage) {
          messages.push(longMessage);
        }
        if (_.isEmpty(messages)) {
          output = {
            type: type,
            latitude: lat,
            longitude: long,
          };
        }
      } else {
        messages.push("Value must be two comma separated decimals: Lat, Long");
      }
    }
    return {
      data: output,
      messages: messages,
    };
  };

  return {
    type,
    reference: spec.reference,
    parse: parse,
  };
}

export const LocationField = {
  type,
  create: (specification) => LocationFieldImpl(specification),
}