
import { FileUploader } from './file_uploader'
import generateUUID from 'uuid/v4'

export const MembershipAssetUploader = () => {

  function uploadSignature(organisationId, membershipId, signatureId, file, progressCallback) {
    if (!(file instanceof File) || file.type != 'image/jpeg') {
      return;
    }

    const assetId = signatureId ?? generateUUID();

    const baseUrl = '/o/' + organisationId + '/members/' + membershipId + '/assets/' + assetId;
    const tokenUrl = baseUrl + '/upload_token';
    const confirmUrl = baseUrl + '/update_signature';
  
    const fileUploader = FileUploader(tokenUrl, confirmUrl);

    return new Promise((resolve, reject) => {
      fileUploader.upload(file, 'signature_' + membershipId + '.jpg', progressCallback)
        .then(resolve(assetId))
        .catch(reject());

    });
  }

  return {
    uploadSignature
  }
}