<template>
  <tr class="input-rows sequence-user">
    <td class="control-group"><span>{{ rangeUser }}</span></td>
    <td class="control-group"><input type="text" v-model="prefixValue" maxlength="10" :disabled="readOnly"></td>
    <td class="control-group"><input type="number" v-model="startValue" :min=0 :max=10000000 :disabled="readOnly"></td>
    <td class="control-group">{{ exampleSeq }}</td>
  </tr>
</template>

<script>
  import _ from 'lodash'

  export default {
    props: {
      readOnly: Boolean,
      sequencePadding: Number,
      index: Number,
      userRange: { type: Object, required: true }
    },
    data: function () {
      var initialRange = _.cloneDeep(this.userRange);
      var initialPrefix = initialRange.prefix;
      var initialStart = initialRange.start;
      return {
        userRangeData: initialRange,
        prefixValue: initialPrefix,
        startValue: initialStart
      }
    },
    watch: {
      prefixValue: function (newValue) {
        this.setPrefix(newValue);
      },
      startValue: function (newValue) {
        this.setStart(newValue);
      }
    },
    computed: {
      exampleSeq: function () {
        return this.userPrefix + this.padNumber(this.userStart, this.sequencePadding);
      },
      rangeUser: function () {
        return this.userRangeData.user_name;
      },
      userId: function () {
        return this.userRangeData.user_id
      },
      userStart: function () {
        return this.userRangeData.start;
      },
      userPrefix: function () {
        return this.userRangeData.prefix;
      }
    },
    methods: {
      setPrefix: function (newPrefix) {
        this.userRangeData.prefix = newPrefix;
        this.emitDataUpdate();
      },
      setStart: function (newStart) {
        this.userRangeData.start = !_.isInteger(_.parseInt(newStart)) ? 1 : _.parseInt(newStart);
        this.emitDataUpdate();
      },
      padNumber: function (number, padding) {
        return _.padStart(number.toString(), padding, '0')
      },
      emitDataUpdate: function () {
        this.$emit('update:userrange', this.userRangeData)
      }      
    }
  }
</script>

<style scoped lang="scss">
  .sequence-label {
    padding-top: 5px;
    padding-right: 5px;
  }
  .sequence-entry input {
    box-sizing: border-box;
    height: 30px;
    width: 100%;
  }
  .sequence-entry td {
    box-sizing: border-box;
    padding: 0px;
    padding-top: 5px;
    padding-left: 5px;
  }
</style>