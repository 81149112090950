import _ from 'lodash'

export default {
  props: {
    specification: {
      type: Object,
      required: true
    },
    solutionType: String,
    data: {
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: function () { return true; }
    },
    isRow: Boolean,
    isNewForm: Boolean,
    drawingSets: Array,
    prefillData: {
      type: Object,
      default: function () { return {}; }
    },
    notifyFieldData: Object,
  },
  created: function () {
    this.formId = _.uniqueId('field_')
  },
  computed: {
    isReadOnly: function () {
      return this.readOnly || !!this.specification.read_only;
    }
  }
}