<template>
  <div class="list-editor">
    <div class="widget-box span8">
      <div class="widget-title">
        <h5>List Items - {{ listLabel }}</h5>
        <span title="Show list items not in use and reorder list." class="show-item-list">
        <input type="checkbox" id="check_show_unused" class="choice-checkbox" v-model="showUnused"/>
        <label for="check_show_unused">Show hidden</label>
        </span>
      </div>
      <div class="alert alert-warning small-alert" v-show="listHasHelp">{{ listHelp }}</div>
      <div class="item-buttons">
        <span v-show="useAlternate" class="label label-info use-alternate" title="Some reports show different text to the specific text shown.">Alternative Report Text Available</span>
        <input type="text" id="use_separator" :disabled="!useAlternate" v-show="useAlternate" maxlength="5" class="sep-text" style="width:  50px;" v-model="separator"/>
        <label for="use_separator" class="sep-text-label" v-show="useAlternate" title="How list items are separated:
^p is a paragraph marker in Word.
^t is a tab in Word.
Frequently a comma and a space.">Text separator ({{ quotedSeparator }})</label>
        <div class="btn-group">
          <button class="btn" v-on:click.prevent="addAll" title="This will show all items and use all list items">Show all items</button>
          <button class="btn" v-on:click.prevent="removeAll" title="This will hide all items and make the list empty">Hide all items</button>
        </div>
      </div>
      <div class="widget-content">
        <div id="list-item" ref="listItem">
            <draggable 
              class="list-group"
              tag="ul"
              v-model="options"
              v-bind="dragOptions"
              handle=".handle"
              >
              <li class="list-group-item" v-bind:class="{'hidden': isHidden(element), unused: !element.use }" v-for="(element, idx) in options" :key="element.key">
                <div>
                <div>
                  <i class="material-icons annotation-unstyled handle">dehaze</i>
                  <span class="text">{{ element.value }} </span>
                  <input type="checkbox" class="form-control" v-model="element.use" v-on:change="emitList" :title="useListCaption(element.use)"/>
                  <span class="material-icons annotation-unstyled trash-entry" v-show="isNewItem(element)" title="Delete new item" @click="removeAt(idx)">delete_outline</span><br>
                <div v-show="useAlternate">
                  <textarea class="form-control alternate-text" v-model="element.alternate" v-on:change="emitList"/>
                </div>
                </div>
                </div>
              </li>
            </draggable>
        </div>
        <div class="btn-group list-group-item">
          <input type="text" class="form-control-left" v-on:keydown.enter.prevent="addNewItem()" v-model="newItemText"/>
          <button class="btn btn-secondary" @click.prevent="addNewItem(); return false" :disabled="!allowAdd">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import _ from 'lodash'
  export default {
      components: {
        draggable
      },
      props: {
      list: {
        type: Object,
        default: function () { return {}; }
      },
      originalList: {
        type: Object,
        default: function () { return {}; }
      },
      listLabel: String
    },
    data: function () {
      let initialUseAlternate = false;
      let initialList = _.cloneDeep(this.list);
      if (!_.isNil(initialList['use_alternate'])) {
        initialUseAlternate = initialList['use_alternate'];
      };
      let initialSeparator = ", ";
      if (!_.isNil(initialList['separator'])) {
        initialSeparator = initialList['separator'];
      };
      initialList['separator'] = initialSeparator;
      initialList['use_alternate'] = initialUseAlternate;

      return {
        showUnused: false,
        newItemText: "",
        drag: false,
        listsChanged: false,
        listInUse: initialList,
        useAlternate: initialUseAlternate,
        separator: initialSeparator
      }
    },
    watch: {
      useAlternate: function(value) {
        this.listInUse.use_alternate = value;
        this.emitList();
      },
      separator: function(value) {
        this.listInUse.separator = value;
        this.emitList();        
      },
      value: _.debounce(function (newValue) {
          this.setNewValue(newValue)
      },150)
    },
    computed: {
      options: {
        get() {
          return this.listInUse.items;
        },
        set(value) {
          this.listInUse.items = value;
          this.emitList();
        }
      },
      listHasHelp: function () {
        return !_.isEmpty(this.listInUse['help']);
      },
      listHelp: function () {
        return this.listHasHelp ? this.listInUse['help'] : "";
      },
      dragOptions: function() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      },
      allowAdd: function() {
        return !_.isEmpty(this.newItemText.trim());
      },
      quotedSeparator: function() {
        return '"' + this.separator + '"';
      }
    },
    methods: {
      changeAll: function(changeFunc) {
        _.forEach(this.listInUse.items, changeFunc);       
        this.emitList();
      },
      addAll: function() {
        this.changeAll(function(o) {
          o.use = true;
        });
      },
      removeAll: function() {
        this.changeAll(function(o) {
          o.use = false;
        });
      },
      emitList: function () {
        this.$emit('update:list', this.listInUse);
      },
      isHidden: function (element) {
        return !(this.showUnused || element.use);
      },
      useListCaption: function (useListItem) {
        return useListItem ? "Untick to hide this value." : "Tick to use this value.";
      },
      addNewItem: function () {
        // use the text and generate a list item
        let vm = this;
        let listItem = { key: vm.nextKey(), value: vm.newItemText, use: true, alternate: null, new: true } ;
        vm.useItem(listItem) ;
        vm.newItemText = "";
        setTimeout( () => {
          vm.scrollItemList();
        },100);
      },
      nextKey: function () {
        let vm = this;
        return (_.max(_.map(vm.listInUse.items, function (thisItem) { return _.isNaN(parseInt(thisItem.key)) ? 0 : parseInt(thisItem.key) })) + 1).toString() ;
      },
      removeAt(idx) {
        this.listInUse.items.splice(idx, 1);
        this.emitList()
      },      
      useItem: function (thisItem) {
       this.listInUse.items.push(thisItem);
       this.emitList()
      },
      isNewItem: function (thisItem) {
        let vm = this;
        return _.isNil(_.find(vm.originalList.items,{'key': thisItem.key}));
      },
      scrollItemList: function () {
        let container = this.$refs.listItem;
        if (!_.isNil(container)) {
          container.scrollTop = container.scrollHeight;
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .currentlist {
    background-color:#FFFF00 !important;
  }
  #list-item {
    border: #cdcdcd 1px solid;
    min-height: 200px;
    max-height: 400px;
    overflow-y: scroll;
  }
  .list-text { 
    padding-left: 4px;
    cursor: pointer;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group {
    min-height: 20px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .list-group-item {
    cursor: pointer;
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #eee;
    border: 1px solid rgba(0,0,0,.125);
    overflow: auto;

    &.unused {
      opacity: 0.5;
    }

    &.hidden {
      display: none;
    }
  }
  .list-group-item i {
    cursor: move;
  }
  .handle {
    float: left;
    padding-top: 4px;
    padding-bottom: 8px;
    margin-right: 8px
  }
  .trash-entry {
    margin-top: 0px;
    float: right;
    font-size: 20px;
  }
  .form-control {
    margin-top: 0px;
    float: right;
  }
  .form-control-left {
    margin-top: 0px;
    float: left;
  }
  .show-item-list {
    list-style-type: none;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 7px;
    margin-right: 5px;
    float: right;
  }
  .show-item-list label {
    display: inline-block;
  }
  .choice-checkbox {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .item-buttons {
    text-align: right;
    margin-top: 6px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .alternate-text {
    max-width: 100%;
    min-width: 100%;
    float: none;
    resize: vertical;
    min-height: 22px;
  }
  .use-alternate {
    float: left;
    margin-top: 5px;
  }
  .use-alternate-checkbox {
    margin-top: 7px;
  }
  .sep-text {
    float: left;
    margin-left: 20px;
  }
  .sep-text-label {
    float: left;
    margin-top: 5px;
    margin-left: 12px;
    white-space: pre;
  }
  .small-alert {
    margin-bottom: 5px;
  }

</style>