<template>
  <div class="photo-frame">
    <div class="photo-container">
      <a v-if="hasPhoto" :href="link"><img class="photo" v-if="hasPhoto" :src="photoUrl"></a>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      assetId: String,
      link: String
    },
    data: function () {
      return {
        currentAsset: this.assetId
      }
    },
    computed: {
      hasPhoto: function () {
        return this.assetId !== '';
      },
      photoUrl: function () {
        return this.assetUrl(this.assetId)
      },
    },
    methods: {
      assetUrl: function (assetId) {
        return '/o/' + this.organisationId() + '/assets/' + (assetId)
      },
      organisationId: function () {
        const organisationIdRegex = /^\/o\/(\d+)\//g;
        let matches = organisationIdRegex.exec(window.location.pathname)

        if (matches) {
          return matches[1]
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .photo-container {
    position: relative;
    width: 620px;
    max-width:100%; 
  }
  .inspection-photo {
    position: absolute;
    top: 0;
    left: 0;
  }
  .photo-frame {
    .photo {
      width: 100%;
    }
    & {
      margin-bottom: 20px;
    }
  }

</style>
