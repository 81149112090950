<template>
  <div id="snackbar">Some text some message..</div>
</template>


<script>
  export default {
    props: {
      message: String,
    },
    watch: {
      message: function(val) {
        if (val) this.snackbarMessage(val);
      }
    },
    methods: {
      snackbarMessage(message) {
        var snackbar = document.getElementById("snackbar");
        snackbar.textContent = message;
        snackbar.classList.add("show");
        setTimeout(() => snackbar.classList.remove("show"), 2900);
      }
    }
  }
</script>

<style>
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
</style>
