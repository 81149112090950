<template>
  <div>
    <h3>Selected {{ assetName(assetType) }} Assets</h3>
    <table class="selected-assets-table">
      <tr>
        <th>id</th>
        <th>type</th>
        <th>road</th>
        <th>status</th>
        <th>Current inspection due</th>
        <th>Traffic Mgmt</th>
        <th><button v-on:click.prevent="deselectAll">Remove All</button></th>
      </tr>
      <tr v-for="(feature, index) in features">
        <td v-bind:class="noInspectionForFeature(feature) ? 'alert-error' : ''" v-bind:title="noInspectionForFeature(feature) ? 'This asset cannot be scheduled.' : ''">
        {{ cleanType(feature.properties.asset_type) }} {{ feature.properties.id }} </td>
        <td>{{ feature.properties.asset_detail_type }}</td>
        <td>{{ feature.properties.road_name}}</td>
        <td>{{ feature.properties.status }}</td>
        <td>{{ dateLong(feature.properties.inspect_due_date) }}</td>
        <td>{{ feature.properties.traffic_management_level }}</td>
        <td><button v-on:click.prevent="deselect(index)">Remove</button></td>
      </tr>
    </table>

    <select v-model="selectedWorkPackage" class="import-data-section">
      <option :value="null">Please select a work package</option>
      <option v-for="workPackage in workPackages" :value="workPackage">{{workPackage.name}}</option>
    </select>
    <button :disabled="importDisabled" v-on:click.prevent="dispatchImport()" class="import-data-section">Import</button>
    <div class="alert alert-success small-alert" v-if="importing">Importing...</div>
    <div class="alert alert-error small-alert" v-if="showImportError">{{ importErrorText }}</div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import MapMixin from '../mixins/map_mixin'

  export default {
    mixins: [MapMixin],
    props: [
      'features',
      'workPackages',
      'assetType',
      'createImportPath'
    ],
    data: function () {
      return {
        importing: false,
        selectedWorkPackage: null,
        showImportError: false,
        importErrorText: ''
      }
    },

    watch: {
      assetType: function (newAssetType) {
        this.showImportError = false;
        this.importErrorText = '';
      }
    },
    computed: {
      importDisabled: function () {
        return this.importing || _.isNil(this.selectedWorkPackage) || this.validInspections.length < 1
      },
      validInspections: function () {
        let vm = this; 
        return _.filter(vm.features, function(feature) { return !vm.noInspectionForFeature(feature) })
      }
    },
    methods: {
      noInspectionForFeature: function (feature) {
        return _.isNil(feature.properties.insp_activity_id);
      },
      deselect: function (index) {
        this.$emit('deselect', [this.features[index]])
      },
      deselectAll: function () {
        this.$emit('deselect', this.features)
      },
      plannedFeatures: function (planned_features) {
        this.$emit('planned', planned_features)
      },
      dispatchImport: function() {
        let vm = this;

        if (_.isNil(vm.selectedWorkPackage)) {
          alert("Please select a work package")
          return
        }

        let postData = {
          work_package_id: vm.selectedWorkPackage.id,
          asset_type: vm.assetType,
          ramm_assets_data: _.map(vm.validInspections, function (feature) {
            return { ramm_asset_id: feature.properties.id, insp_activity_id: feature.properties.insp_activity_id }
          })
        }

        vm.importing = true;

        vm.$http.post(vm.createImportPath, postData).then(function (response) {
          if (!_.isEmpty(response.body['job_ids'])) {
            vm.importing = false;
            vm.showImportError = false;
            vm.plannedFeatures(vm.validInspections);
            vm.deselectAll();
            vm.$emit('import:success', response)            
          } else {
            if (_.isNil(response.body['error'])) {
              vm.importErrorText = 'There were no import jobs submitted.'
            } else {
              vm.importErrorText = response.body['error'];
            }
            vm.importing = false;
            vm.showImportError = true;
            vm.$emit('import:failure', vm.importErrorText);
          }
        }, function (response) {
          vm.importing = false;
          vm.importErrorText = response['statusText'];
          vm.showImportError = true;
          vm.$emit('import:failure', response.body);
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .import-data-section {
    margin-top: 10px;
    margin-bottom: 2px;
  }
  .selected-assets-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px black;
  }
  .selected-assets-table th:nth-child(1) {
    width: 10%;
  }
  .selected-assets-table th:nth-child(2) {
    width: 10%;
  }
  .selected-assets-table th:nth-child(3) {
    width: 20%;
  }
  .selected-assets-table th:nth-child(4) {
    width: 15%;
  }
  .selected-assets-table th:nth-child(5) {
    width: 15%;
  }
  .selected-assets-table th:nth-child(6) {
    width: 15%;
  }
  .selected-assets-table th:nth-child(7) {
    width: 15%;
  }
  th {
    padding-left: 20px;
    padding-right: 20px;
    color: #AAAAAA;
    background-color: #333333;
  }  
  td {
    text-align: center;
  }
  .selected-assets-table tr:nth-child(odd) {
    background-color: #d3dbde;
  }

  .selected-assets-table tr:nth-child(even) {
    background-color: none;
  }

</style>