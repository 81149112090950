<template>
  <div :class="['pdf-page-container']" v-on:click="$emit('click')">
    <div class="pdf-page-item buttons" v-if="showDetails">
      <button class="btn rotate-button btn-small" @click.stop.prevent="onImageRotate(false)" title="Rotate left.">
        <i class="material-icons md-18">rotate_left</i>
      </button>
      <button class="btn rotate-button btn-small" @click.stop.prevent="onImageRotate(true)" title="Rotate right.">
        <i class="material-icons">rotate_right</i>
      </button>
    </div>
    <div class="pdf-page-item" :class="[pageWidthStyle]">
      <div class="pdf" ref="pageCanvasContainer" :class="[rotationStyle]" />
    </div>
    <div class="pdf-page-item form-container" v-if="showDetails">
      <div class="form-item">
        <label v-bind:for="'name_' + pageNumber">Name</label>
        <input type="text" v-bind:id="'name_' + pageNumber" v-model="drawingName" @click.stop>
      </div>
      <div class="form-item">
        <label v-bind:for="'version_' + pageNumber">Version</label>
        <input type="text" v-bind:id="'version_' + pageNumber" v-model="versionName" @click.stop>
      </div>
    </div>
    <p class="page-number">{{pageNumber}}</p>
  </div>
</template>

<script>

  // the key values have a matching class style declared in the style section
  const SIZES = [
    { id: 0, key:"tiny-size", value:"Tiny pages" },
    { id: 1, key:"small-size", value:"Small pages" },
    { id: 2, key:"medium-size", value:"Medium pages" },
    { id: 3, key:"large-size", value:"Large pages" },
    { id: 4, key:"x-large-size", value:"Extra large pages" },
  ]

  export const sizeOptions = _.map(SIZES, w => { return {'key': w.id, 'value': w.value } } );
  export const detailSizeOptions = _.slice(sizeOptions, 1);

  export default {
    props: {
      pageNumber: Number,
      pdfLoader: Object,
      pageSizeOption: Number,
      showDetails: Boolean,
      drawingDetails: Object,
    },
    data: function () {
      return {
        page: null,
        drawingName: '',
        versionName: '',
        rotation: 0,
      }
    },
    watch: {
      drawingDetails: function(details) {
        if (details) {
          this.drawingName = details.drawing_name;
          this.versionName = details.version_name;
          this.rotation = details.rotation;
        } else {
          this.drawingName = '';
          this.versionName = '';
          this.rotation = 0;
        }
      },
      drawingName: function() {
        this.updateDrawing();
      },
      versionName: function() {
        this.updateDrawing();
      },
      rotation: function() {
        this.updateDrawing();
      },
      pageNumber: function() {
        this.reload();
      },
      pdfLoader: function() {
        this.reload();
      },
      pageSizeOption: function() {
        // needs this delay so that the style referenced in the redraw has been applied
        this.$nextTick(() => this.redraw());
      },
    },
    computed: {
      pageWidthStyle: function() {
        return _.find(SIZES, w => w.id == this.pageSizeOption)?.key
          ?? SIZES[1].key;
      },
      rotationStyle: function() {
        return 'rotate-' + this.rotation;
      }
    },
    mounted: async function () {
      this.reload();
    },
    methods: {
      reload: async function () {
        if (this.pdfLoader != null) {
          let pdf = await this.pdfLoader.promise;
          this.page = await pdf.getPage(this.pageNumber);
          this.$emit("loaded");
          this.updateDrawing();
          this.redraw();
        }
      },
      redraw: async function() {
        let page = this.page;
        let container = this.$refs.pageCanvasContainer

        let viewport = page.getViewport({scale: 1});
        let dim = getComputedStyle(container).getPropertyValue('--dimension');
        let scale = dim / Math.max(viewport.width, viewport.height);
        viewport = page.getViewport({scale: scale, rotation: 0});

        let canvas = document.createElement('canvas');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        container.innerHTML = '';
        container.appendChild(canvas);

        let context = canvas.getContext('2d');
        let renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        await page.render(renderContext).promise;
        this.$emit("rendered");
      },
      updateDrawing: function () {
        let drawing = {
          drawing_name: this.drawingName,
          version_name: this.versionName,
          rotation: this.rotation,
        };
        this.$emit("update:drawing", drawing)
      },
      onImageRotate: function(clockwise) {
        this.rotation = (360 + this.rotation + 90 * (clockwise ? 1 : -1)) % 360;
      },
    }
  };
</script>
<style scoped>
  .pdf-page-container {
    display: flex;
    background-color: #CCC;
    position: relative;
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 24px;
  }
  .pdf-page-item {
    flex: 1 1 auto;
    margin: 0 12px;
  }
  .pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 110px;
    margin-bottom: 12px;
    width: calc(var(--dimension) * 1px);
  }
  .form-container {
    display: flex;
    flex-direction: column;
  }
  .form-item {
    display: flex;
    align-items: center;
  }
  .form-item label {
    vertical-align: middle;
    padding-right: 10px;
    min-width: 52px;
    text-align: end;
  }
  .form-item input {
    flex-grow: 1;
    max-width: 400px;
    width: auto;
  }
  .page-number {
    position:absolute;
    bottom: 6px;
    right: 6px;
    font-weight: bolder;
    font-size: larger;
    margin-bottom: 0;
  }
  .buttons { 
    display: flex;
    justify-content: flex-end;
    margin-bottom: 6px;
  }
  .rotate-button {
    height: 24px;
  }
  .rotate-button > i {
    font-size: 18px;
  }
  .tiny-size {
    --dimension: 126;
  }
  .small-size {
    --dimension: 276;
  }
  .medium-size {
    --dimension: 476;
  }
  .large-size {
    --dimension: 776;
  }
  .x-large-size {
    --dimension: 1576;
  }

  .rotate-0 {
    rotate: 0deg;
  }
  .rotate-90 {
    rotate: 90deg;
    height: calc(var(--dimension) * 1px);
  }
  .rotate-180 {
    rotate: 180deg;
  }
  .rotate-270 {
    rotate: 270deg;
    height: calc(var(--dimension) * 1px);
  }
</style>