<template>
  <div id="spinner-overlay" v-on:click.stop>
    <div class="centre progress-reporter-container" aria-hidden="true">
      <div class="progress-reporter">
        <h5>{{ progressLabel }}</h5>
        <h5 :class="[finished ? '' : 'hide']">Finished!</h5>
      </div>
      <div class="spinner-container">
        <div :class="['spinner', finished ? 'nospin' : '']" aria-hidden="true" />
        <div :title="cancelHoverText" class="centre spinner-text cancel" v-show="allowCancel" v-on:click="cancel">X</div>
      </div>
      <div class="messages">{{failureMessages}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      allowCancel: Boolean,
      cancelHoverText: String,
      actionLabel: String,
      totalProgress: Number,
      currentProgress: Number,
      failMessages: Array,
      finished: Boolean,
    },
    data: function () {
      return {
      }
    },
    computed: {
      progressLabel: function() {
        return this.actionLabel + ' ' + this.currentProgress + ' of ' + this.totalProgress
      },
      failureMessages: function() {
        return this.failMessages?.join('\n') ?? "";
      },
    },
    methods: {
      cancel: function() {
        if (this.allowCancel) {
          this.$emit('cancel');
        }
      }
    }
  }
</script>

<style scoped>
  #spinner-overlay {
    --spinner-color: #0088cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    z-index: 10000; /* make sure it's on top */
    cursor: default; 
  }
  .centre {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
  .progress-reporter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .progress-reporter {
    background-color: white;
    padding: 6px 12px;
  }
  .spinner-container {
    position: relative;
  }
  .spinner {
    width: 80px;
    height: 80px;
    border: 8px solid #00000000; /* transparent */
    border-top: 8px solid var(--spinner-color);
    border-left: 8px solid var(--spinner-color);
    border-right: 8px solid var(--spinner-color);
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .nospin {
    animation: none;
  }
  .spinner-text {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border: none;
    color: var(--spinner-color);
    font-size: 16px;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    color: red;
  }
  .messages {
    white-space: pre-line;
    color: red;
    max-height: 200px;
    overflow-y: auto;
  }
  .hide {
    display: none;
  }

</style>