<template>
  <fieldset class="feature-field" v-show="isVisible">
    <legend>
      {{ label }} <span v-if="isRequired">*</span>
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="showCriteria || showMandatoryText">
        <span v-if="showCriteria">{{ criteriaText }}</span>
        <span v-if="showMandatoryText">One or more sub-forms have data issues that need resolving.</span>
      </p>
      <div v-if="isPresent">    
        <div class="table-view-buttons help-block" v-show="hasTableViews" >
          <button  @click.prevent="setCurrentView('Form View')" name="Form View" v-bind:class="[isCurrentView('Form View') ? 'btn-info' : '', 'btn', 'btn-small']">Form View</button><button v-for="tableView in tableViews" @click.prevent="setCurrentView(tableView.name)" :name="tableView.name" v-bind:class="[isCurrentView(tableView.name) ? 'btn-info' : '', 'btn', 'btn-small']">{{ tableView.name }}</button>
        </div>
        <div class="help-block" v-show="isPresent && !showAsRow">
          <button  @click.prevent="toggle" name="Toggle Features" class="btn btn-small">{{ toggleText }}</button>
          <select style="margin-bottom: 0px" v-model="showSubFeature" v-show="hasSubFeatures">
            <option :value="null" selected="selected">Show all fields</option>
            <option v-for="feature in featureSubFeatures()" :value="feature['reference']">Show {{ feature['label'] }} only</option>
          </select>
        </div>
        <div v-show="hasSolutionView" class="solution-view">
          <input style="display: none" type="file" accept="text/csv" @change="onFileSelected" ref="fileInput" >
          <button @click.prevent="clickFileInput" class="btn btn-small">Load Solution Data...</button>
          <div class="alert alert-error small-alert btn-small load-message" v-show="errorsLoadingCSV">{{ loadResultText }}</div>
          <div class="alert alert-success small-alert btn-small load-message" v-show="showLoadingMessages">{{ loadResultText }}</div>
          <div>
            <input type="checkbox" id="option-overrated" :disabled="!hasHints" v-model="overRated" class="choice-checkbox">
            <label for="option-overrated">Include solutions over required substrate fire rating?</label>
          </div>
        </div>
      </div>
      <div class="no-features" v-else>
        There are no "{{ label}}" forms to show.
      </div>
    </legend>

    <div class="feature-instances" style="margin-left: 1em" v-if="showAsRow" >
      <span>{{ selectedView }}</span>
      <table class="table-responsive feature-instance-table">
        <thead>
          <th v-show="hasHints">
            <b title="Loaded hints">?</b>
          </th>
          <th v-for="column in selectedColumns" v-bind:title="fieldHelpText(column)" v-show="!chosenFieldisGuidance(column)">{{ fieldNameForReference(column) }}<span v-if="fieldRequired(column)">*</span></th>
        </thead>
        <feature-instance
          :isRow="true"
          :instance="instance"
          :notifyInstanceData="notifyInstanceData[instance.id]"
          @update:instance="val => updateInstance(index, val)"
          :index="index"
          :specification="specification"
          :fields="fields"
          :fieldsToUse="selectedFields"
          :columns="selectedColumns"
          :read-only="isReadOnly"
          :new-form="isNewInstance(instance.id)"
          :show-validation="showValidation"
          :validate-now="validateNow"
          :instanceReference="instanceReference(index)"
          @destroy="() => removeInstance(index)"
          @update:errors="newErrors => updateValidityState(newErrors)"
          @notifyInstanceEvent="val => onNotifyInstanceEvent(val)"
          v-for="(instance, index) in instances" :key="instance.id"
          />
      </table>
    </div>
    <div class="feature-instances" style="margin-left: 1em" v-else >
      <feature-instance
        :instance="instance"
        :notifyInstanceData="notifyInstanceData[instance.id]"
        @update:instance="val => updateInstance(index, val)"
        :index="index"
        :specification="specification"
        :fields="fields"
        :columns="selectedColumns"
        :read-only="isReadOnly"
        :new-form="isNewInstance(instance.id)"
        :drawing-sets="drawingSets"
        :show-validation="showValidation"
        :validate-now="validateNow"
        :instanceReference="instanceReference(index)"
        :show="showFeatures"
        :subFeatureToShow="subFeatureToShow"
        @destroy="() => removeInstance(index)"
        @update:errors="newErrors => updateValidityState(newErrors)"
        @notifyInstanceEvent="val => onNotifyInstanceEvent(val)"
        v-for="(instance, index) in instances" :key="instance.id"
        />
    </div>
    <div class="btn-group" style="margin-left: 20px">

      <select v-if="!noPrefillData" :disabled="!canAdd" v-show="!isReadOnly" v-model="selectedPrefillData" class="span4" style="margin-bottom: 0px" ref="selPrefillData">
        <option :value="Object({})" selected>Choose from starting data...</option>
        <optgroup :label="listHeader" v-for="listHeader in prefillDataHeadings()">
          <option :value="option.data" v-for="option in prefillDataChoices(listHeader)">{{ option.value }}</option>
        </optgroup>
      </select>

      <div class="btn-group dropdown" >
        <button :disabled="!canAdd" v-show="!isReadOnly" v-on:click="addNewInstance" class="btn btn-success">{{ addButtonLabel }}</button>
        <button :disabled="!canAdd" v-show="!isReadOnly" data-toggle="dropdown" id="state_dropdown" class="btn btn-success dropdown-toggle">
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu pull-right">
          <li class="load-option" v-show="canAdd && !isReadOnly" @click.prevent="$refs.fileLoad.click()" title="Import data from external csv file">Import from csv file</li>
        </ul>
      </div>
      <input v-if="!isReadOnly" class="hide" type="file" accept="text/csv" @change="onLoadFileSelected" ref="fileLoad">

    </div>




  </fieldset>
</template>

<script>
  import _ from 'lodash'
  import generateUUID from 'uuid/v4'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldVisibilityMixin from '../../mixins/field_visibility_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'
  import FeatureInstance from './feature_instance.vue'
  import { ImportFile } from '../../lib/feature_data_loader.js'
  import { EventBus } from '../../lib/event_bus'
  
  //const FILTERFIELDS = { fireRating: 'Fire Rating', substrate: 'Substrate'};
  const FILTERFIELDS = [ 'Fire Rating', 'Substrate' ];
  const ASBESTOSTYPES = ['asbestos_management_survey', 'asbestos_refurbishment_survey', 'asbestos_demolition_survey', 'asbestos_management_plan', 'asbestos_survey'];
  const INDEXFIELD = 'Ref';
  //const MINTARGETS = { solutionReference: 'Solution Reference', solutionDescription: 'Solution Description'};
  const MINTARGETS = [ 'Solution Reference', 'Solution Description' ];

  export default {
    components: {
      FeatureInstance,
    },
    mixins: [fieldMixin, fieldLabelMixin, fieldVisibilityMixin, fieldValidationMixin],
    inject: ['sourceLists'],
    provide: function () {
      return {
        hints: this.fetchHintData,
        filterOn: this.fetchFilterFields(FILTERFIELDS),
        targetFields: this.fetchFilterFields(MINTARGETS),
        includeOverRating: this.fetchOverRating
      }
    },
    data: function () {
      let newInstances = [];

      if (!_.isNil(this.data) && _.isArray(this.data.values)) {
        newInstances = this.readOnly ? _.cloneDeep(this.data.values) : this.setInitialReferenceItems(_.cloneDeep(this.data.values))
      }
      let initialNotify = _.reduce(newInstances, (o, f) => {
        o[f.id] = {};
        return o;
        }, {});
      return {
        instances: newInstances,
        currentView: "Form View",
        currentColumns: [],
        fieldsToUse: [],
        instanceValidity: [],
        newFormId: null,
        selectedFile: null,
        loadResultText: "",
        loadDetails: { errors: [], fileName: ""},
        loadData: [],
        hintData: [],
        loadFilters: FILTERFIELDS,
        loadTargets: MINTARGETS,
        asbestosTypes: ASBESTOSTYPES,
        overRated: false,
        showSubFeature: "",
        selectedPrefillData: {},
        showFeatures: true,
        notifyInstanceData: initialNotify, 
      }
    },
    watch: {
      selectedPrefillData: function (newValue) {
        let vm = this;
        if (!_.isEmpty(newValue)) {
          vm.defineInstance(newValue);
          vm.emitDataUpdate();
        } else {
          return;
        };
        vm.$nextTick(() => { 
          vm.$refs.selPrefillData.selectedIndex = 0; 
          vm.selectedPrefillData = {};
        });
      },
      notifyFieldData: function(val) {
        // we assume the destination here contains just the id of the instance
        // TODO: adapt this to support deeper paths
        this.notifyInstanceData[val.destination] = val;
      },
    },
    computed: {
      noPrefillData: function () {
        return _.isEmpty(this.prefillData);
      },
      isAsbestos: function () {
        return _.includes(this.asbestosTypes, this.solutionType) && (this.reference == 'Samples')
      },
      subFeatureToShow: function () {
        return this.showSubFeature;
      },
      toggleText: function () {
        return this.showFeatures ? "Minimise subforms" : "Show all subforms"
      },
      hasHints: function () {
        return !_.isEmpty(this.hintData);
      },
      errorsLoadingCSV: function () {
        if (!_.isEmpty(this.loadDetails.errors)) {
          let rowMessage = "";
          if (this.loadDetails.errors[0].row > -1) { rowMessage = ", row: " + this.loadDetails.errors[0].row };
          this.loadResultText = this.loadDetails.errors[0].message + rowMessage;
          return true;
        } else {
          return false;
        }
      },
      showLoadingMessages: function () {
        if (!_.isNil(this.selectedFile) && !this.errorsLoadingCSV) {
          return true;
        } else {
          return false;
        }
      },
      showMandatoryText: function () {
        return this.showValidation && !this.noChildErrors;
      },
      selectedView: function () {
        return this.currentView;
      },
      hasCriteria: function () {
        return (this.minInstances != 0 || this.maxInstances != 0);
      },
      minInstances: function () {
        return this.specification.minimum;
      },
      maxInstances: function () {
        return this.specification.maximum;
      },
      selectedColumns: function () {
        return this.currentColumns;
      },
      fields: function () {
        let vm = this;
        return vm.nestSubFeature ? vm.featureFieldSpecification([vm.subFeatureToShow]) : vm.specification.fields;
      },
      instanceCount: function () {
        return this.instances.length;
      },
      canAdd: function () {
        var max = this.maxInstances;
        // Explictly compare to false so nil or undefined values don't trigger restriction
        return !this.isReadOnly && (max === 0 || this.instanceCount < max) && this.specification.allow_add !== false;
      },
      addButtonLabel: function () {
        let buttonText = "Add '" + this.specification.label + "'";
        if (!this.noPrefillData) {
          buttonText =  "Add blank '" + this.specification.label + "'";
        }
        return buttonText;
      },
      tableViews: function () {
        return this.specification.table_views
      },
      hasTableViews: function () {
        return !!this.tableViews && (this.tableViews.length > 0)
      },
      hasSolutionView: function () {
        return !this.isReadOnly && this.hasTableViews && (this.currentView == 'Solution');
      },
      showAsRow: function () {
        return !_.eq(this.currentView,"Form View")
      },
      selectedFields: function () {
        return this.fieldsToUse;
      },
      isRequired: function () {
        return !_.isNil(this.specification.minimum) && this.specification.minimum > 0;
      },
      isPresent: function () {
        return this.instanceCount > 0;
      },
      errorCount: function () {
        return this.instanceValidity.length;
      },
      noChildErrors: function () {
        return this.errorCount == 0;
      },
      loadDataValid: function () {
        return this.loadHeaderCheck() && this.loadFiltersPresent() && this.loadRefIsUnique()
      },
      hasSubFeatures: function () {
        return !_.isEmpty(this.featureSubFeatures());
      },
      nestSubFeature: function () {
        return !_.isEmpty(this.showSubFeature) && this.hasSubFeatures && this.isFormView;
      },
      isFormView: function () {
        return this.isCurrentView('Form View')
      }
    },
    methods: {
      onNotifyInstanceEvent: function(val) {
        val.source = this.specification.reference + ':' + val.source
        this.$emit('notifyFieldEvent', val);
      },
      prefillDataHeadings: function () {
        let vm = this;
        return _.sortBy(_.keys(vm.prefillData));
      },
      prefillDataChoices: function (listSection) {
        return this.prefillData[listSection];
      },
      initialAsbestosCheck: function () {
        let solutionsToCheck = ASBESTOSTYPES; //['asbestos_management_survey', 'asbestos_refurbishment_survey', 'asbestos_demolition_survey', 'asbestos_management_plan', 'asbestos_survey'];
        return _.includes(solutionsToCheck, this.solutionType) && (this.specification.reference == 'Samples')
      },
      setInitialReferenceItems: function (initial_items) {
        // loop through all items, and if a reference field, correct it
        let vm = this;
        if (this.initialAsbestosCheck()) {
          _.forEach(initial_items, function (this_item, key, collection) {
            if (vm.isReferenceItem(this_item)) {
              let referenced_item = vm.referencedItem(vm.referencedItemID(this_item), collection);
              if (!_.isEmpty(referenced_item)) {
                if (!vm.isReferenceItem(referenced_item)) {
                  vm.updateReferenceItem(referenced_item, this_item);
                }
              }
            }
          })
        }
        return initial_items;
      },
      updateReferenceItems: function (itemInvestigated) {
        // the listed items depend on the referenced Item and need to be updated
        let vm = this;
        if (vm.isReferenceItem(itemInvestigated)) { 
          // Reference Item has changed - ensure data is still aligned with referenced fields
          let referenced_item = vm.referencedItem(vm.referencedItemID(itemInvestigated), vm.instances);
          if (!_.isEmpty(referenced_item)) {
            if (!vm.isReferenceItem(referenced_item)) {
              vm.updateReferenceItem(referenced_item, itemInvestigated);
            }
          }
        } 
        else
        {
          // Other field has changed - is it referenced?
          let ID = vm.itemID(itemInvestigated);
          _.forEach(vm.instances, function (this_item) {
            if (vm.isReferenceItem(this_item)) {
              if (vm.itemIsReferenced(this_item, itemInvestigated)) {
                vm.updateReferenceItem(itemInvestigated, this_item);
              }
            }
          })
        }
      },
      updateReferenceItem: function (fromReferencedItem, toReferenceItem) {
        if (_.isEmpty(fromReferencedItem['data']['sel_aType'])) {
          delete toReferenceItem['data']['sel_aType']
        } else {
          toReferenceItem['data']['sel_aType'] = _.cloneDeep(fromReferencedItem['data']['sel_aType'])
        };
        if (_.isEmpty(fromReferencedItem['data']['sel_aAsbTypes'])) {
          delete toReferenceItem['data']['sel_aAsbTypes']
        } else {
          toReferenceItem['data']['sel_aAsbTypes'] = _.cloneDeep(fromReferencedItem['data']['sel_aAsbTypes'])
        };
      },
      itemIsReferenced: function (Referencing, Referenced) {
        let vm = this;
        return (_.isEmpty(vm.itemID(Referenced)) || _.isEmpty(vm.referencedItemID(Referencing))) ? false : (vm.itemID(Referenced) == vm.referencedItemID(Referencing));
      },
      itemID: function (itemInvestigated) {
        let result = ""
        try {result = _.trim(itemInvestigated['data']['ssNumber']['value'])} catch {result = ""};
        return result;
      },
      referencedItemID: function (itemInvestigated) {
        let result = ""
        try {result = _.trim(itemInvestigated['data']['ssRefSample']['value'])} catch {result = ""};
        return result;
      },
      isReferenceItem: function (itemInvestigated) {
        return (this.itemInvestigationMethod(itemInvestigated) == 'R') && !_.isEmpty(this.referencedItemID(itemInvestigated));
      },
      referencedItem: function (item_id, item_list) {
        // return the item with that id
        let vm = this;
        let result = {};
        try {
          result = _.head(_.filter(item_list, function (this_item) { return vm.itemID(this_item) == item_id }));
        } 
        catch {
          result = {}
        }
        return _.isUndefined(result) ? {} : result;
      },
      itemInvestigationMethod: function (itemInvestigated) {
        let result = ""
        try {result = itemInvestigated['data']['sel_aSampType']["selections"][0]['key']} catch {result = ""};
        return result;
      },
      isCurrentView: function (button_name) {
        return button_name == this.currentView ;
      },
      featureFieldSpecification: function (field_references) {
        let vm = this;
        let fieldSet = _.filter(vm.specification.fields, function (field) { return _.includes(field_references, field.reference) });
        return _.isEmpty(fieldSet) ? vm.specification.fields : fieldSet;
      },
      featureSubFeatures: function () {
        let vm = this;
        let output = [];
        let localFeatures = _.forEach(vm.specification.fields, function (field) {
          // is a feature with table views
          if ((field.type == 'feature') && !_.isNil(field['table_views']) && (!_.isEmpty(field.table_views))){
            output.push({'reference': field.reference, 'label': field.label});
          }
        });
        return output;
      },
      toggle: function (e) {
        e.preventDefault();

        this.showFeatures = !this.showFeatures;
      },
      fetchOverRating: function () {
        return this.overRated;
      },
      fetchFilterFields: function (arrayFields) {
        let vm = this;
        let output = {};
        let localFields = _.filter(vm.specification.fields, function (field) { return _.includes(arrayFields, field.label) });
        _.forEach(localFields, function (field) { output[field.reference] = field.label });
        return output;
      },
      fetchHintData: function () {
        return this.hintData;
      },
      clickFileInput: function () {
        if (!this.isReadOnly) {
          this.$refs.fileInput.click();
        }
      },
      onFileSelected: function (event) {
        if (!_.isEmpty(event.target.value)) {
          this.selectedFile = event.target.files[0];
          this.loadCSV(this.selectedFile);
        }
      },
      onLoadFileSelected: function (event) {
        let fileToLoad = event.target.files[0];
        ImportFile(fileToLoad, this.specification, this.instances, this.sourceLists).then(
          instances => {
            this.instances = instances;
            this.emitDataUpdate();
          }, error => {
            alert("There were one or more errors loading '" + fileToLoad.name + "'.\n- " + error);
          });
        this.$refs.fileLoad.value = ''; // reset the input field in case user chooses same file again
      },
      makeHintData: function () {
        // cycle through loadData, and turn into array of objects
        let vm = this;
        let output = [];
        let rowOutput = {};
        if (vm.loadDataValid) {
          let headers = vm.makeHeaderLookup();
          _.forEach(vm.loadData, function (rowData, rowIndex) {
            if (rowIndex > 0) {
              rowOutput = {};
              _.forEach(rowData, function (cell, columnIndex) {
                rowOutput[headers[columnIndex]] = cell;
              });
              output.push(rowOutput);
            }
          });          
        }
        return output;
      },
      makeHeaderLookup: function () {
        // Remap CSV headers into field references
        let vm = this;
        let output = [];
        let thisField = null;
        _.forEach(vm.loadData[0], function (header) {
          thisField = _.find(vm.fields, function (field) {
            return field['label'] == header;
          })
          if (_.isEmpty(thisField)) {
            output.push(header);
          } else {
            output.push(thisField['reference']);
          }
        })
        return output;
      },
      loadCSV: function (fileToLoad) {
        let result = null;
        let vm = this;
        this.loadDetails.fileName = fileToLoad.name;
        this.loadDetails.errors = [];
        this.loadResultText = "Loading " + this.loadDetails.fileName + " ...";
        this.hintData = [];

        result = this.$papa.parse(fileToLoad, { skipEmptyLines: true, complete: function(results, file) {
          vm.loadDetails['errors'] = results.errors;
          vm.loadData = results.data;
          if (_.isEmpty(results.errors)) {
            if (vm.loadDataValid) {
              vm.loadResultText = "Loaded " + vm.loadDetails.fileName + " successfully";
              vm.hintData = vm.makeHintData();
            }
          }
          }});
        this.$refs.fileInput.value = "";
        return result;
      },
      loadRefIsUnique: function () {
        if (!_.isEmpty(this.loadData)) {
          //Do we have a Ref column as 0
          let vm = this;
          let status = true; 
          status = status && _.includes(vm.loadData[0],INDEXFIELD);
          if (status) {
            status = status && vm.checkRefColUnique();
            if (!status) {
              vm.loadDetails.errors.push({ type: "", code: "", message: "There are duplicates in the Ref column.", row: -1 });
            }
          } else {
            vm.loadDetails.errors.push({ type: "", code: "", message: "Ref column missing - must be the first column.", row: -1 });
          }
          return status;
        } else {
          return false;
        }
      },
      loadFiltersPresent: function () {
        if (!_.isEmpty(this.loadData)) {
          // Do we have mandatory filters
          let vm = this;
          let status = true; 
          status = status && _.every(_.map(vm.loadFilters), function (filter) { return _.includes(vm.loadData[0], filter)});
          if (!status) {
            vm.loadDetails.errors.push({ type: "", code: "", message: "Fire Ratings or Substrate columns missing.", row: -1 });
          }              
          return status;
        } else {
          return false;
        }
      },
      loadHeaderCheck: function () {
        if (!_.isEmpty(this.loadData)) {
          // Headers must be unique
          let vm = this;
          let status = true;
          let dataHeaders = _.map(vm.fields, function (o) { return o.label });
          // No blanks
          status = status && !_.includes(vm.loadData[0], "");
          if (status) {
            // convert to Set as this only allows unique values
            status = status && (new Set(vm.loadData[0]).size == vm.loadData[0].length);
            if (status) {
              status = status && vm.loadFiltersInData(dataHeaders);
              if (!status) {
                vm.loadDetails.errors.push({ type: "", code: "", message: "The data does not contain the required fields 'Substrate' and 'Fire Rating'.", row: -1 });  
              }
            } else {
              vm.loadDetails.errors.push({ type: "", code: "", message: "Make sure column headers are unique.", row: -1 });
            }
          } else {
            vm.loadDetails.errors.push({ type: "", code: "", message: "You appear to have a blank column header.", row: -1 }) ; 
          }
          return status;
        } else {
          return false;
        }
      },
      loadFiltersInData: function (dataArray) {
        let vm = this;
        return _.every(_.map(vm.loadFilters), function (filter) { return _.includes(dataArray, filter)});
      },
      mapHeadersFromFields: function () {
        let vm = this;
        let allFields = _.map(vm.fields, function (o) {return o.label}) ;
        return _.every(vm.loadData[0], function (header) {
          return _.includes(allFields, header);
        })
      },
      checkRefColUnique: function () {
        let refCol = [];
        let matrix = this.loadData;
        for(var i=1; i<matrix.length; i++){
          refCol.push(matrix[i][0]);
        }
        return new Set(refCol).size == refCol.length; // return column data..
      },
      isNewInstance: function (instance_id) {
        return this.newFormId == instance_id
      },
      assessValidity: function (sendData) {
        let localValid = 'valid';
        let localSendData = _.isNil(sendData) ? true : sendData ;
        this.checkCriteria();
        
        if (!this.isHidden) {
          localValid = (this.isPresentIfRequired && this.meetsCriteria && this.noChildErrors) ? 'valid' : 'invalid';
        }
        if (localSendData) {
          this.updateValidity(localValid);
        }
        this.currentValidity = localValid;
        return localValid;
      },
      checkCriteria: function () {
        // Text - must be shorter than maximum_length
        let result = true; // all OK - criteria met
        let res_text = "";

        if (this.hasCriteria) {
          let has_min = this.minInstances > 0;
          let has_max = this.maxInstances > 0;

          let min_met = has_min ? (this.instanceCount >= this.minInstances) : true;
          let max_met = has_max ? (this.instanceCount <= this.maxInstances) : true;

          result = min_met && max_met;

          if (!result) {
            res_text = "You must have ";
            let min_text = "at least ";
            let max_text = "up to ";

            if (has_min && has_max) {
              if (this.minInstances == this.maxInstances) {
                res_text = res_text + _.toString(this.maxInstances) + " sub-forms.";
              } else {
                res_text = res_text + "between " + _.toString(this.minInstances) + " and " + _.toString(this.maxInstances) + " sub-forms.";
              }
            } else if (has_max) {
              res_text = "You can have upto " + _.toString(this.maxInstances) + " sub-forms.";
            } else if (has_min) {
              res_text = res_text + min_text + _.toString(this.minInstances) + " sub-forms.";
            } else {
              res_text = ""
            }            
          }
        }
        this.criteriaText = !result ? res_text : "";
        this.criteriaValid = result;
      },
      instanceReference: function (index) {
        return this._uid + "-" + this.reference + "-" + _.toString(index)
      },
      chosenFieldisGuidance: function (column) {
        let localField = _.find(this.fieldsToUse,{'reference': column});
        return (localField.type == 'guidance');
      },
      updateValidityState: function (instanceError) {
        // A feature field is not valid if:
        //  - it does not meet criteria
        //  - Any of its features are not valid
        let instanceUID = _.keys(instanceError)[0];
        let errorValue = instanceError[instanceUID];
        let instanceIndex = -1;
        let originalValidity = this.currentValidity;
        let vm = this;
        // Check validity of features
        if (errorValue == 'valid') {
          // field was in array, remove it
          vm.removeInstanceError(instanceUID);
        } ;
        if (errorValue == 'invalid' && !_.includes(vm.instanceValidity, instanceUID)) {
          // add it in
          vm.instanceValidity.push(instanceUID);
        } ;
        // Chec overall validity
        vm.assessValidity(false);
        // there has been a change. Raise it
        let dataToSend = {};
        dataToSend[vm.reference] = vm.currentValidity;
        this.$emit('update:errors', dataToSend);
      },
      removeInstanceError: function (instanceUID) {
        let vm = this;
        let instanceIndex = -1;

        if (_.includes(vm.instanceValidity, instanceUID)) {
          // field was in array, remove it
          instanceIndex = _.findIndex(vm.instanceValidity, function (entry) {
            return entry == instanceUID;
          });
          if (instanceIndex > -1) {
            // found it - remove it
            vm.instanceValidity.splice(instanceIndex, 1);
          }
        } ;
      },
      calcFieldsToUse: function () {
        var allFields = this.fieldsToUse;
        var filterFields = this.currentColumns;
        return _.filter(allFields, function(fieldToCheck) { return _.includes(filterFields, fieldToCheck.reference); });
      },
      setCurrentView: function (buttonName) {
        var allFields = this.specification.fields;
        this.currentView = buttonName;
        if (this.currentView == "Form View") {
          this.currentColumns = [];
          var returnArray = _.cloneDeep(allFields);
        }
        else {
          this.currentColumns = this.tableViews[_.findIndex(this.tableViews, { 'name': this.currentView})].fields;
          var filterFields = this.currentColumns;
          var returnArray = _.filter(allFields, function(fieldToCheck) { return _.includes(filterFields, fieldToCheck.reference); });
        };
        this.fieldsToUse = returnArray;
      },
      fieldNameForReference: function (fieldReference) {
        return this.specification.fields[_.findIndex(this.specification.fields, { 'reference': fieldReference})].label;
      },
      fieldRequired: function (fieldReference) {
        let thisFieldSpec = this.specification.fields[_.findIndex(this.specification.fields, { 'reference': fieldReference})];
        let result = false;
        if (thisFieldSpec.type == 'selection') {
          result = !_.isNil(thisFieldSpec.minimum_selections) && thisFieldSpec.minimum_selections > 0
        } else {
          result = thisFieldSpec.required;
        }
        return result;
      },
      fieldHelpText: function (fieldReference) {
        return this.specification.fields[_.findIndex(this.specification.fields, { 'reference': fieldReference})]['help_text'];
      },
      addNewInstance: function (e) {
        e.preventDefault();
        this.defineInstance({});
        this.emitDataUpdate()
      },
      defineInstance: function (dataToSend) {
        this.newFormId = generateUUID();
        this.instances.push({
          id: this.newFormId,
          type: 'feature_instance',
          data: dataToSend
        });
        this.notifyInstanceData[this.newFormId] = {};
      },
      removeInstance: function (index) {
        delete this.notifyInstanceData[this.instances[index].id]
        this.instances.splice(index, 1);
        this.removeInstanceError(this.instanceReference(index));
        this.emitDataUpdate()
      },
      updateInstance: function (index, instance) {
        if (this.isAsbestos) {
          this.updateReferenceItems (instance);
        }
        this.$set(this.instances, index, instance)
        this.emitDataUpdate()
      },
      emitDataUpdate: function() {
        this.assessValidity(true);
        this.$emit('update:data', {
          type: 'feature',
          values: this.instances
        });
      }
    },
    created: function () {
      let vm = this;
      if (!!vm.underRollup){
        EventBus.$on(vm.underRollup, (eventData) => {
          vm.visibleUnderRollup = eventData['rollup'];
          let thisShowHide = eventData['groupShowHide'];
          if (!!thisShowHide) {
            vm.groupShowHide = thisShowHide;
            vm.assessValidity(true);
          }
        });
      };
      if (!!vm.controlledField){
        EventBus.$on(vm.controlEvent, (ShowHideData) => {
          let thisShowHide = ShowHideData[vm.reference];
          if (!!thisShowHide) {
            vm.controlShowHide = thisShowHide;
            vm.assessValidity(true);
          }
        });
      };
    }
  }
</script>

<style scoped lang="scss">
  .feature-instance-table {
    border: 1px black;
    table-layout: auto;
    width: 100%;
  }
  .feature-instance-table th {
    background-color: #556875;
    color: #ffffff;
    font-size: 12px;
  }
  .feature-instance-table tr:nth-child(even) {
    background-color: #d3dbde;
  }
  .feature-instance-table tr:nth-child(odd) {
    background-color: none;
  }
  .load-message {
    display: inline;
  }
  .solution-view label {
    display: inline-block;
  }
  .choice-checkbox {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .hide {
    display: none;
  }  
  .load-option {
    white-space: nowrap;
    padding: 6px 12px;
  }
  .load-option:hover {
    color: white;
    background-color: black;
    cursor: pointer;
  }
</style>