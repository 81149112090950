<template>
  <div class="text-field control-group" v-if="isRow">
    <div class="controls inspection-table-cell" :title="label" v-show="isTableCellVisible">
      <div v-show="!isListOnly() || isReadOnly">
        <textarea v-if="specification.multiline" v-bind:name="formId" v-on:mousedown="resetInsertionPoint" v-model="value" :disabled="isReadOnly" :class="[{'field-error': !isValid}, 'inspection-table-field', {dirty: isDirty}]" ref="text_multi"></textarea>
        <input v-else v-bind:name="formId" type="text" v-model="value" v-on:mousedown="resetInsertionPoint" :disabled="isReadOnly" :class="[{'field-error': !isValid}, 'inspection-table-field', {dirty: isDirty}]" v-on:keydown.enter.prevent="" ref="text_single" />
      </div>
      <select v-if="showListSource && !isReadOnly" v-model="singleSelection" ref="selTextList" :class="[{'left-list': isListAndText()}, {'span8': isListOnly()},isValid ? '' : 'field-error', 'no-select inspection-table-field']" :title="dropDownHelpText" >
        <option v-if="isListAndText() || (isListOnly() && !isPresent)" value="initial_value_04112021_unique">{{ dropDownPrompt }}</option>
        <option v-if="isListOnly() && isPresent" value="clear_value_04112021_unique">- Clear current value</option>
        <option :value="option.key" v-for="option in listValues" :key="option.key">{{ option.value }}</option>
      </select>
    </div>
    <div class="controls" :title="label" v-show="!isTableCellVisible">
      &nbsp;
    </div>
  </div>
  <div class="text-field control-group" v-show="isVisible" v-else>
    <label v-bind:for="formId" class="control-label">{{ label }}<span v-if="isRequired">*</span>
    </label>
    <div class="controls">
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="showCriteria || showMandatoryText">
        <span v-if="showCriteria">{{ criteriaText }}</span>
        <span v-if="showMandatoryText">This field is mandatory.</span>
      </p>
      <div v-show="!isListOnly() || isReadOnly" style="display:inline">
        <textarea v-if="specification.multiline" v-bind:name="formId" v-on:mousedown="resetInsertionPoint" v-model="value" :disabled="isReadOnly" :class="['span8', {dirty: isDirty}]" ref="text_multi"></textarea>
        <input v-else v-bind:name="formId" type="text" v-model="value" v-on:mousedown="resetInsertionPoint" :disabled="isReadOnly" :class="['span8', {dirty: isDirty}]" v-on:keydown.enter.prevent="" ref="text_single" />        
      </div>
      <select v-if="showListSource && !isReadOnly" v-model="singleSelection" ref="selTextList" :class="[{'left-list': isListAndText()}, {'span8': isListOnly()} ]" :title="dropDownHelpText">
        <option v-if="isListAndText() || (isListOnly() && !isPresent)" value="initial_value_04112021_unique">{{ dropDownPrompt }}</option>
        <option v-if="isListOnly() && isPresent" value="clear_value_04112021_unique">- Clear current value</option>
        <option :value="option.key" v-for="option in listValues" :key="option.key">{{ option.value }}</option>
      </select>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldVisibilityMixin from '../../mixins/field_visibility_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'
  import { EventBus } from '../../lib/event_bus'
  import { TextField } from '../../lib/fields/_fields.js'

  export default {
    mixins: [fieldMixin, fieldLabelMixin, fieldVisibilityMixin, fieldValidationMixin],
    inject: ['sourceLists'],
    data: function () {
      let initialValue = null;

      if (!_.isNil(this.data)) {
        initialValue = this.data.value;
      }
      let initialSelection = this.checkSelection(initialValue);
      let initialValues = this.makeListValues(initialValue);

      return {
        initialValue: initialValue,
        value: initialValue,
        insertionPoint: -1,
        singleSelection: initialSelection,
        listValues: initialValues
      }
    },
    watch: {
      data: function (newValue) {
        let vm = this;
        if (!_.isNil(vm.data) && (vm.value != newValue.value)) {
          vm.value = newValue.value;
        }
      },
      notifyFieldData: function(val) {
        this.value = val.payload.value;
      },
      singleSelection: function (newValue) {
        let vm = this;
        if (_.isUndefined(newValue)) {
          vm.singleSelection = "initial_value_04112021_unique";
          return;
        }
        let isNewValue = (newValue != "initial_value_04112021_unique");
        let clearValue = (newValue == "clear_value_04112021_unique");
        if (vm.isListOnly() && isNewValue && !clearValue) {
          // replace the current value
          let selectedItem = _.filter(vm.listValues, function(listValue) { return listValue.key == newValue; });
          vm.value = selectedItem[0].value;
        } else if (vm.isListOnly() && clearValue) {
          vm.value = "";
        }
        if (vm.isListAndText() && isNewValue) {
          // if list+K then insert value
          let selectedItem = _.filter(vm.listValues, function(listValue) { return listValue.key == newValue; });
          let selectedValue = selectedItem[0].value;
          vm.value = vm.insertText(selectedValue);
          vm.insertionPoint = vm.value.length;
          vm.singleSelection = "initial_value_04112021_unique";
          vm.activeTextField.focus();
        }
      },
      value: _.debounce(function (newValue) {
          this.setNewValue(newValue)
        },150)
    },
    computed: {
      activeTextField: function () {
        let thisField = null;
        if (this.specification.multiline) {
          thisField = this.$refs.text_multi
        } else {
          thisField = this.$refs.text_single
        };
        return thisField;
      },
      dropDownHelpText: function () {
        return "Add text from the list at cursor position / selection."
      },
      dropDownPrompt: function () {
       return " - Insert text at cursor / selection...";
      },
      isPresent: function () {
        return !_.isEmpty(this.value);
      },
      hasCriteria: function () {
        return !!this.specification.maximum_length;
      },
      showListSource: function () {
        return this.hasListSource() && !_.isEmpty(this.listValues);
      },
      isDirty: function () {
        return !(_.isEqual(this.initialValue, this.value) || this.checkBothEmpty(this.initialValue, this.value));
      }
    },
    methods: {
      checkBothEmpty: function (initial, current) {
        return ((_.isNull(initial) || initial === '') && (_.isNull(current) || current === ''))
      },
      makeListValues: function (valueToCheck) {
        let vm = this;
        let sourceList = vm.listItemsToUse(valueToCheck);
        return vm.hasListSource() && !!sourceList ? _.filter(sourceList, function(listEntry) { return !!listEntry.use; }) : [];
      },
      hasSource: function () {
        return !!this.specification.source && !_.isEmpty(this.specification.source[0]);
      },
      sourceString: function () {
        return this.hasSource() ? this.specification.source[0] : ""
      },
      sourceType: function () {
        return this.hasSource() ? _.split(this.sourceString(), ":")[0] : ""
      },
      sourceSpecifier: function () {
        return this.hasSource() ? _.split(this.sourceString(), ":")[1] : ""
      },
      hasListSource: function () {
        return this.hasSource() ? ((this.sourceType() == "list") || (this.sourceType() == "list+K")) : false
      },
      listName: function () {
        return this.hasListSource() ? this.sourceSpecifier() : ""
      },
      isListOnly: function () {
        return this.sourceType() == "list";
      },
      isListAndText: function () {
        return this.sourceType() == "list+K";
      },
      indexListHoldsValue: function (listToCheck, valueToFind) {
        return _.findIndex(listToCheck, function(thisItem) { return thisItem.value == valueToFind; });
      },
      listItemsToUse: function (valueToCheck) {
        let vm = this;
        let sourceList = [];
        if (!!this.sourceLists && !_.isEmpty(this.listName())) {
          sourceList = _.cloneDeep(this.sourceLists[this.listName()].items);
          if (vm.isListOnly() && !_.isEmpty(valueToCheck)) {
            // If listOnly then check text value and add into list if not present
            let hasValue = vm.indexListHoldsValue(sourceList, valueToCheck);
            if (hasValue == -1) {
              // add to list
              sourceList.push({ 'key': valueToCheck, 'value': valueToCheck, 'alternate': null, 'use': true })
            }
          }

        }
        return sourceList;
      },
      checkSelection: function (checkThisValue) {
        let vm = this;
        return !_.isEmpty(checkThisValue) && vm.isListOnly() ? vm.keyForValue(vm.listItemsToUse(checkThisValue), checkThisValue) : "initial_value_04112021_unique";
      },
      keyForValue: function (listToCheck, valueToFind) {
        let result = "initial_value_04112021_unique";
        let index = this.indexListHoldsValue(listToCheck, valueToFind);
        if (index != -1) {
          result = listToCheck[index].key
        } 
        return result;
      },
      resetInsertionPoint: function (e) {
        this.insertionPoint = -1;
      },
      insertText: function (textToInsert) {
        let result = "";
        let thisField = this.activeTextField;
        let startPos = 0;
        let endPos = 0;

        if (thisField.selectionStart || thisField.selectionStart == '0') {
          let priorPoint = this.insertionPoint != -1
          startPos = priorPoint ? this.insertionPoint : thisField.selectionStart;
          endPos = priorPoint ? this.insertionPoint : thisField.selectionEnd;
          result = thisField.value.substring(0, startPos)
              + textToInsert
              + thisField.value.substring(endPos, thisField.value.length);
        } else {
          result = thisField.value + textToInsert;
        };
        return result;
      },
      setNewValue: function (newValue) {
        let vm = this;
        vm.checkCriteria(newValue);
        vm.currentValidity = vm.assessValidity();
        let dataToSend = {};
        dataToSend = _.isNil(vm.data) ? {} : _.clone(vm.data);
        dataToSend['value'] = newValue;
        dataToSend['type'] = TextField.type;
        vm.$emit('update:data', dataToSend)
        vm.insertionPoint = newValue.length;
      },
      checkCriteria: function (newValue) {
        // Text - must be shorter than maximum_length
        let result = true; // all OK - criteria met
        if (this.hasCriteria) {
          result = (_.toString(newValue).length <= this.specification.maximum_length);
        }
        this.criteriaText = !result ? "Text must be no longer than " + _.toString(this.specification.maximum_length) + " characters." : "";
        this.criteriaValid = result;
      },
      checkCurrentCriteria: function () {
        this.checkCriteria(this.value);
      }
    },
    created: function () {
      let vm = this;
      if (!!vm.underRollup){
        EventBus.$on(vm.underRollup, (eventData) => {
          vm.visibleUnderRollup = eventData['rollup'];
          let thisShowHide = eventData['groupShowHide'];
          if (!!thisShowHide) {
            vm.groupShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
      if (!!vm.controlledField){
        EventBus.$on(vm.controlEvent, (ShowHideData) => {
          let thisShowHide = ShowHideData[vm.reference];
          if (!!thisShowHide) {
            vm.controlShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
    }
  }
</script>

<style scoped lang="scss">
  .inspection-table-cell {
    width: 95%;
    display:flex;
  }
  .inspection-table-cell > div {
    flex: 1 1 auto;
  }
  .inspection-table-field {
    padding-left: 6px;
    padding-right: 0px;
    margin-bottom: 0px;
    width: 100%;
  }
  .control-group {
    margin-bottom: 0px;  
  }
  .underlying {
    position: absolute;
  }
  .ontop {
    width: 550px;
    position: relative;
    z-index: 200;
  }
  .left-list {
    vertical-align:top;
    width: 20px;
    border-radius:10px;
    background-color: #0000FF;
    color: white;
  }
  .left-list option {
    cursor: pointer;
    background-color: white;
    color: black;
  }

</style>