<template>
  <div>
    <img ref="image"/>
    <canvas ref="coordinateCanvas"/>
  </div>
</template>

<script>

export const TOP_LEFT_COLOR = "#00FF"; // blue
export const BOTTOM_RIGHT_COLOR = "#F00F"; // red
export const TRANSPARENT = '#FFFF'
export const HIGHLIGHT = '#FF07' // yellow highlight

export default {
  props: {
    subjectImage: Image,
    currentDrawing: Object,
    selectedCoordinate: String,
    forceRedraw: Boolean
  },
  data: function () {
    return {
      canvas: null,
      context: null,
    }
  },
  watch: {
    subjectImage: function(image) {
      if (image != null) {
        this.canvas.width = image.naturalWidth;
        this.canvas.height = image.naturalHeight;

        let img = this.$refs.image;
        img.src = image.src;

        this.redraw();
      }
    },
    currentDrawing: function() {
      this.redraw();
    },
    'currentDrawing.top_left': function() {
      this.redraw();
    },
    'currentDrawing.bottom_right': function() {
      this.redraw();
    },
    selectedCoordinate: function() {
      this.redraw();
    },
    forceRedraw: function (redraw) {
      if (redraw) this.redraw();
    }
  },

  mounted() {
      this.canvas = this.$refs.coordinateCanvas;
      this.context = this.canvas.getContext("2d");
  },
  computed: {
    focusingOrigin: function() {
      return this.selectedCoordinate == 'tl' || this.selectedCoordinate == 'all';
    },
    focusingEnd: function() {
      return this.selectedCoordinate == 'br' || this.selectedCoordinate == 'all';
    },
  },
  methods: {
    redraw: function() {
      let rect = this.canvas.getBoundingClientRect();
      if (this.currentDrawing == null || rect.width == 0) {
        return;
      }
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      let drawHighlight = true;
      let canvas = this.canvas;
      let context = this.context;
      
      let tl_x = this.currentDrawing.top_left[0] * canvas.width;
      let tl_y = this.currentDrawing.top_left[1] * canvas.height;
      let br_x = this.currentDrawing.bottom_right[0] * canvas.width;
      let br_y = this.currentDrawing.bottom_right[1] * canvas.height;

      drawCrosshairs(canvas, context, tl_x, tl_y, TOP_LEFT_COLOR, drawHighlight && this.focusingOrigin)
      drawCrosshairs(canvas, context, br_x, br_y, BOTTOM_RIGHT_COLOR, drawHighlight && this.focusingEnd)

      function drawCrosshairs(canvas, context, x, y, color, drawHighlight) {
        let lineWidth = Math.floor(canvas.width / rect.width) + 1;
        if (drawHighlight) {
          context.lineWidth = lineWidth * 7;
          context.strokeStyle = HIGHLIGHT
          context.beginPath();
          context.moveTo(0, y);
          context.lineTo(canvas.width, y);
          context.stroke();
          context.beginPath();
          context.moveTo(x, 0);
          context.lineTo(x, canvas.height);
          context.stroke();
        }
        context.lineWidth = lineWidth;
        context.strokeStyle = color
        context.beginPath();
        context.moveTo(0, y);
        context.lineTo(canvas.width, y);
        context.stroke();
        context.beginPath();
        context.moveTo(x, 0);
        context.lineTo(x, canvas.height);
        context.stroke();
      };
    },
  }
}
</script>

<style scoped>
div {
  position: relative;
}
canvas, img {
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
}
</style>
