<template>
  <div class="inc-divider">
    <button v-show="!isReadOnly" @click.prevent="$refs.fileInput.click()" :disabled="isReadOnly" class="btn btn-small">{{ inclusionToggleButtonText }}</button>
    <span v-show="isReadOnly" class="control-label">{{ inclusionName }}</span>
    <div class="page-chooser">Page(s) : <input class="narrowPages" type="text" :disabled="!(!isReadOnly && hasInclusion)" v-model="pagesEntered" title="(e.g. all or 1,2,7 or 1,2,8-12) As shown in Adobe Acrobat, rather than shown on the pages" v-on:keydown.enter.prevent="">
    </div>
    <div class="alert alert-primary small-alert" v-if="hasInclusion" style="margin-top: 5px;"> <a :href="assetUrl(this.inclusionID)" target="_blank" class="alert-link">{{ inclusionFileName }}</a> 
    <button v-show="!disableRemove" type="button" class="btn btn-danger btn-small inc-remove" v-on:click="remove" :disabled="disableRemove">
      <span class="material-icons annotation-unstyled trash-entry" title="Remove included report">delete_outline</span>
    </button> 

    </div>
    <input type="file" style="display: none" @change="onFileSelected" ref="fileInput" >
    <div class="alert alert-error small-alert" v-if="!isValidPageRange">Please enter a valid page range e.g. all or 1,2,7 or 1,2,8-12</div>
    <div class="alert alert-error small-alert" v-if="showWrongFileTypeChosen">Select a valid file (pdf, jpg, png)</div>
    <div class="alert alert-error small-alert" v-if="showFileUploadError">{{ uploadErrorText }}</div>
    <div class="alert alert-success small-alert" v-if="showLoadInProgress">{{ loadingProgress }}</div>
    <div class="alert alert-success small-alert" v-if="showAssetCreatedSuccessfully">Asset created successfully!</div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { AssetUploader } from "../lib/asset_uploader"

  const isNumeric = input => !isNaN(input) && (input > 0) && !input.includes('.') // you may also check if the value is a nonzero positive integer
  const isAll = input => _.upperCase(input) == 'ALL'
  const isOrdered = (start, end) => parseInt(start) < parseInt(end)
  const isRangeValid = range => range.length == 2 && range.every(isNumeric) && isOrdered(range[0], range[1])
  const isSingleValid = single => single.length == 1 && isNumeric(single[0])

  export default {
    props: {
      data: Object,
      schema: Object,
      readOnly: Boolean
    },
    data: function () {
      let initialValue = "all";
      let initialValidRange = false;

      if (!_.isNil(this.data) && !_.isNil(this.data.page_range)) {
        initialValue = _.uniq(this.data.page_range.split(",")).join(",");
      }
      if (!_.isNil(this.data) && _.isNil(this.data.page_range) && !_.isNil(this.data.pages)) {
        initialValue = _.join(this.data.pages,",");
      }
      initialValidRange = this.validPageRange(initialValue);

      return { 
        pagesEntered: initialValue,
        isValidPageRange: initialValidRange,
        selectedFile: null,
        testFile: null,
        loadInProgress: false,
        fileLoadProgress: "",
        createdFileSuccess: false,
        fileChoiceMistake: false,
        canUndo: false,
        errorText: "Oops, something we weren't expecting went wrong!",
        fileUploadError: false      
      }
    },
    watch: {
      pagesEntered: _.debounce(function(newValue) {
        this.isValidPageRange = this.validPageRange(newValue);
        if (this.isValidPageRange && this.hasInclusion) {
          this.emitUpdateData({
            name: this.schema.name,
            uuid: this.inclusionID,
            pages: this.arrayFromString(newValue),
            page_range: _.uniq(newValue.split(",")).join(","),
            file_name: this.inclusionFileName
          })          
        }
      }, 150)
    },
    computed: {
      disableRemove: function () {
        return this.isReadOnly || (!this.isReadOnly && !this.hasInclusion)
      },
      isReadOnly: function () {
        return this.readOnly;
      },
      pageArray: function () {
        return this.arrayFromString(this.pagesEntered)
      },
      inclusionID: function () {
        if (this.hasInclusion) {
          return this.data.uuid;
        } else {
          return null;
        }        
      },
      inclusionFileName: function () {
        if (this.hasInclusion) {
          return this.data.file_name;
        } else {
          return "";
        }       
      },
      hasInclusion: function () {
        return !!this.data && !!this.data.uuid && this.data.uuid !== '';
      },
      inclusionToggleButtonText: function () {
        if (this.hasInclusion) {
          return 'Replace "' + this.schema.name +'"';
        } else {
          return 'Load "' + this.schema.name + '"';
        }
      },
      inclusionName: function () {
        if (this.hasInclusion) {
          return this.schema.name;
        } else {
          return '';
        }
      },      
      uploadErrorText: function () {
        return this.errorText
      },
      loadingProgress: function () {
        return this.fileLoadProgress
      },
      hasValidFileType: function () {
        // check that file is an image or pdf
        return _.isNull(this.testFile) ? false : this.requiredFileType(this.testFile)
      },
      validSelectedFileType: function () {
        return _.isNull(this.selectedFile) ? false : this.requiredFileType(this.selectedFile)
      },
      showAssetCreatedSuccessfully: function () {
        return this.createdFileSuccess
      },
      showWrongFileTypeChosen: function () {
        return this.fileChoiceMistake
      },
      showFileUploadError: function () {
        return this.fileUploadError
      },
      showLoadInProgress: function () {
        return this.loadInProgress
      }
    },
    methods: {
      validPageRange: function (input) {
        if (isAll(input)) return true;
        const inputs = input.split(',').map(x => x.trim());

        for (const x of inputs) {
          if (!x) return false;
          const pages = x.split('-');
          if (!isSingleValid(pages) && !isRangeValid(pages))
            return false;
        }

        return true;
      },
      assetUrl: function (assetId) {
        return '/o/' + this.organisationId() + '/assets/' + (assetId)
      },
      organisationId: function () {
        const organisationIdRegex = /^\/o\/(\d+)\//g;
        let matches = organisationIdRegex.exec(window.location.pathname)

        if (matches) {
          return matches[1]
        }
      },
      arrayFromString: function (inputString) {
        let vm = this;
        var returnArray = [];
        if (isAll(inputString)) {
          returnArray = ["all"];
        } else {
          let inputElements = inputString.split(',').map(x => x.trim());
          returnArray = _.uniq(_.map(inputElements, vm.parseRange).flat()).sort((a,b)=>a-b);
        }
        if (returnArray.length >= 1) {
          return returnArray;
        }
        return ["all"];
      },
      parseRange: function (inputString) {
        if (_.isNaN(_.toNumber(inputString))) {
          return this.arrayFromLimits(inputString);
        } else {
          return [_.parseInt(inputString)];
        }
      },
      arrayFromLimits: function (inputString) {
        // Takes "1-7" and returns [1,2,3,4,5,6,7]
        let values = _.split(inputString, "-");
        return Array.from({ length: _.parseInt(values[1]) - _.parseInt(values[0]) + 1 }, (value, index) => _.parseInt(values[0]) + index);
      },
      requiredFileType: function (file){
        return (file != null) && (file.type == 'application/pdf') || (file.type == 'image/jpeg') || (file.type == 'image/jpg') || (file.type == 'image/gif') || (file.type == 'image/png')
      },
      toggleLoadInProgress: function () {
        this.loadInProgress = !this.loadInProgress;
        if (!(this.loadInProgress)) {
          this.fileLoadProgress = '';
        }
        else{
          this.resetAssetCreatedSuccess()
        }
      },
      toggleCreateSuccess: function () {
        this.createdFileSuccess = !this.createdFileSuccess;
      },
      toggleFileMistake: function () {
        this.fileChoiceMistake = !this.fileChoiceMistake;
      },
      resetAssetCreatedSuccess: function () {
        this.createdFileSuccess = false
      },
      toggleLoadSuccessStatus: function () {
        this.fileUploadError = !this.fileUploadError
      },
      onFileSelected: function (event) {
        this.testFile = event.target.files[0];
        if (this.requiredFileType(this.testFile)) {
          this.selectedFile = event.target.files[0];
          this.onUpload();
        } else {
          this.toggleFileMistake();
          setTimeout( () => {
            this.toggleFileMistake()
          },4000)          
        }
      },
      remove: function (e) {
        e.preventDefault();

        if (!this.disableRemove && window.confirm("Are you sure you want to remove " + this.inclusionFileName + "?")) {
          this.emitClearData();
        }
      },          
      onUpload: function () {
        let vm = this;

        if (this.validSelectedFileType) {
          vm.fileUploadError = false;
          vm.toggleLoadInProgress();

          let uploader = AssetUploader(
            success, 
            fail, 
            message => vm.fileLoadProgress = message
          );
          uploader.uploadJpeg(vm.selectedFile)
        }

        function success(assetId) {
          vm.toggleCreateSuccess();
          vm.toggleLoadInProgress();
          vm.emitUpdateData({
              name: vm.schema.name,
              uuid: assetId,
              pages: vm.pageArray,
              page_range: vm.pagesEntered,
              file_name: vm.selectedFile.name
          });
          setTimeout( () => {
            vm.toggleCreateSuccess()
          },3500)
        };
        function fail(message) {
          vm.errorText = message;
          vm.toggleLoadInProgress();
          vm.toggleLoadSuccessStatus();
        }
      },
      emitUpdateData: function (dataToSend) {
        this.$refs.fileInput.className="dirty";
        this.$emit('update:data', dataToSend);
      },
      emitClearData: function () {
        this.$refs.fileInput.className="dirty";
        this.pagesEntered = "all"
        this.$emit('clear:data');
      }
    }
  }
</script>

<style scoped lang="scss">
  .small-alert {
    width: 569px;
    margin-bottom: 5px;
  }
  .narrowPages {
    width: 80px;
    font-size: 12px;
    height: 14px
  }
  .page-chooser {
    float: right;
    font-size: 12px;
  }
  .inc-remove {
    float: right;
    right: 0px;
  }
  .inc-divider {
  width: 100%;
  position: relative;
  padding-bottom: 5px;
  padding-top: 5px;
  }
  .alert-primary {
  color: rgb(0, 64, 133);
  background-color: rgb(204, 229, 255);
  border-color: rgb(184, 218, 255);
  }
  .trash-entry {
    margin-top: 0px;
    float: right;
    font-size: 20px;
  }

</style>  