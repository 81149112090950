import _ from 'lodash'

const type = 'text';
const TextFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  function parse(value) {
    let output = {
      type: type,
      value: value,
    };
    return {
      data: output,
      messages: [],
    };
  }

  return {
    type,
    reference: spec.reference,
    parse: parse,
  }
}

export const TextField = {
  type,
  create: (specification) => TextFieldImpl(specification),
}
