import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf';


export const PdfImageGenerator = async (pdfFile) => {

  const IMAGE_MAX_AREA = 60000000;
  const IMAGE_MAX_DIMENSION = 15000; // internal limit for image magick, less a fudge for scaling imprecisions // 32767; //https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas
  const IMAGE_MAX_SIZE = 20000000;

  var pdfjs = pdfjsLib;
  var loadingTask;

  async function makeJpegImage(pageNumber, rotation) {
    let pdf = await loadingTask.promise;
    return await doMake(pdf, pageNumber, 'image/jpeg', rotation);
  }

  let doMake = async function (pdf, pageNumber, type, rotation) {
    // get the page 
    let page = await pdf.getPage(pageNumber);

    // scale the page
    let viewport = page.getViewport({scale: 1});
    let aspectRatio = viewport.width / viewport.height;
    let width = Math.sqrt(IMAGE_MAX_AREA * aspectRatio);
    let height = Math.sqrt(IMAGE_MAX_AREA / aspectRatio);
    let scale = width / viewport.width;
    if (Math.max(width, height) > IMAGE_MAX_DIMENSION) {
      scale = (IMAGE_MAX_DIMENSION / Math.max(width, height) * width) / viewport.width;
    }

    viewport = page.getViewport({scale: scale, rotation: rotation});

    // Prepare canvas using PDF page dimensions
    let canvas = new OffscreenCanvas(viewport.width, viewport.height);
    let context = canvas.getContext('2d');

    // Render PDF page into canvas context
    let renderContext = {
      canvasContext: context,
      viewport: viewport
    };
    await page.render(renderContext).promise;

    // extract the image blob
    let blob = await extractBlob(canvas, 0.99, type);
    return { 
      blob: blob,
      width: viewport.width,
      height: viewport.height
    };
  }



  async function extractBlob(canvas, quality, type) {
    let blob = await canvas.convertToBlob({ type:type, quality:quality});
    if (blob.size > IMAGE_MAX_SIZE) {
      blob = await extractBlob(canvas, quality - 0.01, type);
    }
    return blob;
  }


  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = (ev) => {
      let typedarray = new Uint8Array(fileReader.result);
      loadingTask = pdfjs.getDocument(typedarray);
      resolve({
        makeJpegImage: makeJpegImage,
      });
    };
    fileReader.onerror = () => reject();
    fileReader.readAsArrayBuffer(pdfFile);
  });
}