
export const DefaultColorSchemes = () => {
  return {
    _blue: {
      label: "Blue",
      fill_color: "#00719C",
      text_color: "#FFFFFF"
    },
    _red: {
      label: "Red",
      fill_color: "#BD141B",
      text_color: "#FFFFFF"
    },
    _green: {
      label: "Green",
      fill_color: "#007E3D",
      text_color: "#FFFFFF"
    },
    _gold: {
      label: "Gold",
      fill_color: "#F7941E",
      text_color: "#000000"
    },
  }
}