import _ from 'lodash'

const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "saturday"];

export default {
  props: {
  },
  computed: {
  },
  methods: {
    cleanType: function (text_input) {
      // remove first word 
      return _.join(_.map(_.slice(_.words(text_input),1),_.capitalize)," ")
    },
    assetName: function (asset_type) {
      let allWords = _.map(_.words(asset_type), _.capitalize);
      allWords[0] = allWords[0].toUpperCase();
      return _.join(allWords,' ')
    },
    humaniseToken: function (asset_type) {
      return _.join(_.map(_.words(asset_type), _.capitalize),' ')
    },
    dateLong: function (date_string) {
      let result = "No valid date";
      try {
        let this_date = new Date(date_string);
        result = _.isNaN(this_date.getDay()) ? "No Valid Date" : days[this_date.getDay()] + ", " + this_date.getDate() + "-" + months[this_date.getMonth()] + "-" + this_date.getFullYear()
      }
      catch {
        result = "Invalid Date"
      }
      return result;
    },
    dateShort: function (date_string) {
      let result = "No valid date";
      try {
        let this_date = new Date(date_string);
        result = _.isNaN(this_date.getDay()) ? "No Valid Date" : this_date.getDate() + "-" + months[this_date.getMonth()] + "-" + this_date.getFullYear()
      }
      catch {
        result = "Invalid Date"
      }
      return result;
    }
  }
}