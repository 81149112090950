
import _ from 'lodash'

import { ShapeFactory } from "./annotation"
import { AssetUploader } from "../asset_uploader"
import { DrawingOptions } from "./annotation"
import { LayoutParams } from "../../lib/annotations/annotation"

const shapeFactory = ShapeFactory();
const IMAGE_MAX_AREA = 3145728 //2148 * 1536 -> this is the max size the view image will be on the app

export const OverlayGenerator = (successCallback, failCallback, progressCallback) => {

  let canvas = document.createElement('canvas');
  let context = canvas.getContext("2d");
  let annotationDrawingOptions = new DrawingOptions();

  let uploader = AssetUploader(success, fail, progressCallback);

  let inProcess = false;
  let waiting = false;

  let pendingPhotoFieldValue = null;
  let pendingBackgroundImage = null;
  let pendingNewAnnotationAssetUUID = null;

  let layoutParams = new LayoutParams();


  function start(photoFieldValue, backgroundImage, newAnnotationAssetUUID) {
    if (inProcess) {
      pendingPhotoFieldValue = photoFieldValue;
      pendingBackgroundImage = backgroundImage;
      pendingNewAnnotationAssetUUID = newAnnotationAssetUUID
      waiting = true;
      return;
    }
    restart(photoFieldValue, backgroundImage, newAnnotationAssetUUID);
  };
  function restart(photoFieldValue, backgroundImage, newAnnotationAssetUUID) {
    inProcess = true;
    if (!photoFieldValue) {
      fail("");
    } else {
      if (newAnnotationAssetUUID) {
        uploader.setAssetId(newAnnotationAssetUUID);
      }
      annotationDrawingOptions.balloonOptions.textScale = photoFieldValue.text_scale;
      let shapes = shapeFactory.makeShapes(photoFieldValue.annotations);

      if (backgroundImage != null) {
        let width = backgroundImage.naturalWidth;
        let height = backgroundImage.naturalHeight;
        annotationDrawingOptions.backgroundNaturalHeight = backgroundImage.naturalHeight;
        if (canvas.width != width || canvas.height != height) {
          canvas.width = width;
          canvas.height = height;
          layoutParams.setOverallSize(width, height);
          redrawShapes(shapes);
        }
      }
      if (shapes.length == 0) {
        success(null);
      } else {
        makeAnnotationOverlay();
      }
    }
  };

  function redrawShapes(shapes) {
    context.clearRect(0,0, canvas.width, canvas.height);
    let last = shapes.length;
    for (let index = 0; index < last; index++) {
      let shape = shapes[index];
      shape.draw(context, layoutParams, annotationDrawingOptions, false);
    }
  }
  function makeAnnotationOverlay() {
    canvas.toBlob((blob) => {
      try {
        uploader.uploadPng(blob);
      } catch {
        fail("Unexpected error making overlay.");
      }
    }, 'image/png', 0.95);
  }

  function success (assetId) {
    successCallback(assetId);
    reset();
  };
  function fail(message) {
    failCallback(message);
    reset();
  };
  function reset() {
    canvas.width = 0;
    canvas.height = 0;
    if (waiting) {
      waiting = false;
      restart(pendingPhotoFieldValue, pendingBackgroundImage, pendingNewAnnotationAssetUUID);
    }
    inProcess = false;
  };

  return { start: start }
}
