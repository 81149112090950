<template>
  <div class="workpackage-editor">
    <div class="control-group">
      <div class="controls">
        <common-data-form
          :common-data="commonData"
          :main-fields="mainFields"
          :read-only="readOnly"
          :disabled="readOnly"
          @update:common-data="val => updateCommonData(val)">
        </common-data-form>
      </div>
    </div>
    <div class="control-group" v-if="isEditable">
      <label class="control-label" for="vue_assigned_template_ids">Inspection Types</label>
      <div class="controls">
        <v-select 
          class="style-vue"
          v-model="selectedTemplates"
          :options="templatesForSelection"
          :multiple=true
          :filterable=true
          :disabled="!isEditable"
          :clearable=false
          :clearSearchOnSelect=true>
        </v-select>
      </div>
    </div>
    <div class="control-group" v-else>
      <label class="control-label" for="vue_assigned_template_ids">Inspection Types</label>
      <div class="controls">
        <ul>
          <li v-for="(assigned, index) in assignedTemplateArray(assignedTemplates)">
            {{ templateLabel(assigned) }}
          </li>
        </ul>
      </div>
    </div>
    <div class="control-group">
      <label class="control-label">Staff on Work Package</label>
      <div class="controls">
        <v-select 
          class="style-vue"
          v-model="selectedStaff"
          :options="editableStaffForSelection"
          :multiple=true
          :filterable=true
          :disabled="!(isAdmin && !readOnly)"
          :clearable=false
          :clearSearchOnSelect=true>
        </v-select>
      </div>
      <div v-show="anyLocked">
        <label class="control-label" title="Staff with assigned inspections">Staff with Inspections</label>
        <div class="controls">
          <span>The following staff are assigned to this workpackage, but cannot be removed as they are assigned to inspections:</span>
          <div class="locked-staff">{{ lockedStaffNames }}</div>
        </div>
      </div>
    </div>    
    <sequence-form
      :sequence="sequence"
      :read-only="readOnly"
      :index="index"
      :user-list="staffForSequence"
      @update:sequence="val => updateSequence(index, val)"
      v-for="(sequence, index) in sequencesAvailable" :key="sequence.name + keySequenceUsers">
    </sequence-form>
  </div>
</template>

<script>
  import _ from 'lodash'
  import SequenceForm from './work_package_sections/sequence_form.vue'
  import CommonDataForm from './work_package_sections/common_data_form.vue'
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';

  export default {
    components: {
      SequenceForm,
      CommonDataForm,
      vSelect
    },
    props: {
      readOnly: Boolean,
      isAdmin: Boolean,
      isDuplicate: Boolean,
      sequences: Array,
      isActivated: Boolean,
      allTemplates: Array,
      assignedTemplates: Array,
      allAssignableStaff: Array,
      assignedUserIds: Array,
      mainFields: Object,
      commonData: Array
    },
    data: function () {
      let initialTemplates = _.cloneDeep(this.assignedTemplates);
      let initialSeqNames = this.sequenceNameForTemplateIds(initialTemplates);
      let initialAssessors = _.cloneDeep(this.assignedUserIds);
      let initialSequences = this.configureSequences(this.sequences, initialSeqNames, initialAssessors);
      let initialSelectedStaff = this.staffAssignedButNoWork();
      let initialSelectedTemplates = this.selectedTemplateData(this.listAllTemplates(), initialTemplates);
      return {
        workingAssignedTemplates: initialTemplates,
        workingAssignedUsers: initialAssessors,
        sequencesAvailable: initialSequences,
        seqNames: initialSeqNames,
        showLockedWarning: false,
        selectedStaff: initialSelectedStaff,
        selectedTemplates: initialSelectedTemplates
      }
    },
    watch: {
      workingAssignedUsers: function (newSelection) {
        this.setUsers(newSelection);
      },
      //workingAssignedTemplates: function (newSelection) {
      //  this.setTemplates(newSelection);
      //},
      selectedStaff: function (newSelection) {
        let vm = this;
        this.workingAssignedUsers = _.concat(_.map(newSelection, 'value'), _.map(this.staffForSelection(true), 'value'));
      },
      selectedTemplates: function (newSelection) {
        let vm = this;
        this.setTemplates(_.map(newSelection, 'value'))
        //this.workingAssignedTemplates = _.map(newSelection, 'value');
      }      
    },
    computed: {
      showWarning: function () {
        return this.showLockedWarning;
      },
      keySequenceUsers: function () {
        // concatenate sequence name and users names
        let vm = this;
        return _.size(vm.workingAssignedUsers);
      },
      editableStaffForSelection: function () {
        let vm = this;
        return _.differenceWith(vm.staffForSelection(false), vm.selectedStaff, _.isEqual);
      },
      staffForSequence: function () {
        return _.union(this.staffForSelection(true), this.selectedStaff)
      },
      lockedStaffNames: function () {
        let vm = this;
        return _.join(_.map(vm.staffForSelection(true), function(user) { return user.label }), ", ")
      },
      templatesForSelection: function () {
        let vm = this;
        return _.differenceWith(vm.listAllActiveTemplates(), vm.selectedTemplates, _.isEqual);
      },
      activeTemplates: function () {
        let vm = this;
        return _.filter(vm.allTemplates, function(template) { return template[3] != 'superseded'; })
      },
      isEditable: function () {
        return (this.isAdmin && !this.readOnly && !this.isActivated);
      },
      anyLocked: function () {
        return !_.isEmpty(this.staffForSelection(true));
      }
    },
    methods: {
      templateLabel: function (templateEntry) {
        return (templateEntry[3] != 'superseded') ? templateEntry[1] : (templateEntry[1] + " (superseded)");
      },
      listAllTemplates: function () {
        let vm = this;
        var templateArray = [];
        _.forEach(vm.allTemplates, function(thisTemplate) {
          templateArray.push({ "value": thisTemplate[0], "label": vm.templateLabel(thisTemplate)})
        });
        return templateArray;
      },
      listAllActiveTemplates: function () {
        let vm = this;
        var templateArray = [];
        _.forEach(vm.activeTemplates, function(thisTemplate) {
          templateArray.push({ "value": thisTemplate[0], "label": vm.templateLabel(thisTemplate)})
        });
        return _.orderBy(templateArray, 'label');
      },
      selectedTemplateData: function (allVueTemplates, assignedIDList) {
        let vm = this;
        // pull out those templates that are selected
        return _.filter(allVueTemplates, function (thisTemplate) { return (_.includes(assignedIDList, thisTemplate.value)) })
      },
      staffForSelection: function (locked) {
        let vm = this;
        var staffArray = [];
        var thisUser = {}
        _.forEach(vm.allAssignableStaff, function (user) {
          if (vm.isDataLocked(user) == locked) {
            thisUser = { "value": user[0], "label": user[1] };
            staffArray.push(thisUser);
          }
        });
      return staffArray;
      },
      staffAssignedButNoWork: function () {
        let vm = this;
        var staffArray = [];
        var thisUser = {}
        _.forEach(vm.staffForSelection(false), function (user) {
          if (_.includes(vm.assignedUserIds, user.value)) {
            thisUser = { "value": user.value, "label": user.label };
            staffArray.push(thisUser);
          }
        });
      return staffArray;
      },
      setTemplates: function (newSelection) {
        this.workingAssignedTemplates = newSelection;
        this.seqNames = this.sequenceNameForTemplateIds(this.workingAssignedTemplates)
        this.$emit('update:assignedTemplates', this.workingAssignedTemplates);
        this.sequencesAvailable = this.configureSequences(this.sequencesAvailable, this.seqNames, this.workingAssignedUsers);
        this.$emit('update:sequences', this.sequencesAvailable);
      },
      setUsers: function (newSelection) {
        if (this.lockedUsersMissing(newSelection).length > 0) { 
          this.showLockedWarning = true;
        } else {          
          this.showLockedWarning = false;
          this.workingAssignedUsers = newSelection;
          // this.workingUserData = this.assignedUserData(this.allAssignableStaff, this.workingAssignedUsers);
          this.$emit('update:assignedUserIds', this.workingAssignedUsers);
          this.sequencesAvailable = this.configureSequences(this.sequencesAvailable, this.seqNames, this.workingAssignedUsers);
          this.$emit('update:sequences', this.sequencesAvailable);
        };
      },
      isDataLocked: function (userLock) {
        //return !this.isDuplicate && userLock[2];
        return userLock[2];
      },
      defaultSequence: function (sequenceName) {
        return { "name": sequenceName, "enabled": false, "scope": "inspection", "padding": 3, "user_ranges": [] };
      },
      defaultUserRange: function (userID) {
        return { "user_id": userID, "user_name": this.getUserNameFromList(userID), "start": 1, "prefix": "" };
      },
      getUserNameFromList: function (userID) {
        var userEntry = [];
        userEntry =  _.find(this.allAssignableStaff, function (user) { return user[0] == userID; });
        return userEntry[1];
      },
      configureSequences: function (startSequences, availableSeqNames, assignedStaffIds) {
        // ensure sequences exist for all names and users
        let vm = this;
        var localSeq = [];
        var thisSeq = {};
        var localSortedStaffIds = [];
        if ((availableSeqNames.length > 0) && (assignedStaffIds.length > 0)) {
          localSortedStaffIds = this.sortedStaffIdList(assignedStaffIds);
          // we have sequences names & staff
          _.forEach(availableSeqNames, function (thisName) {
            if (vm.sequenceExistsForName(startSequences, thisName)) {
              // add discovered sequence
              thisSeq = vm.sequenceForName(startSequences, thisName);
            } else {
              // add default
              thisSeq = vm.defaultSequence(thisName);
            }
            vm.configureUserRanges(localSortedStaffIds, thisSeq);
            localSeq.push(thisSeq);
          })
        };
        return localSeq;
      },
      sortedStaffIdList: function (staffIds) {
        var localSortedStaffIds = [];
        _.forEach(this.allAssignableStaff, function (thisUser) {
          if (_.includes(staffIds, thisUser[0])) {
            localSortedStaffIds.push(thisUser[0])
          }
        });
        return localSortedStaffIds;
      },
      configureUserRanges: function (assignedStaffIds, thisSequence) {
        // check uers in this sequence, and add user_ranges if missing
        let vm = this;
        var localRanges = [];
        if (_.isNil(thisSequence.user_ranges)) { thisSequence.user_ranges = [] }
        _.forEach(assignedStaffIds, function(userId) {
          if (vm.rangeExistsForUserId(thisSequence.user_ranges, userId)) {
            localRanges.push(vm.rangeForUserId(thisSequence.user_ranges, userId))
          } else {
            localRanges.push(vm.defaultUserRange(userId))
          }
        })
        thisSequence.user_ranges = localRanges;
      },
      sequenceForName: function (sequencesToTest, sequenceName) {
        // return sequence with this name
        var output = {};
        let vm = this; 
        _.forEach(sequencesToTest, function (thisSequence) {
          if (thisSequence.name == sequenceName) {
            output = _.cloneDeep(thisSequence);
          }
        });
        return output;
      },
      rangeForUserId: function (userRanges, userId) {
        var range = {};
        let vm = this;
        _.forEach(userRanges, function (thisRange) {
          if (thisRange.user_id == userId) {
            range = _.cloneDeep(thisRange);
            range.user_name = vm.getUserNameFromList(userId);
            // check start is no null or empty
            if (_.isNil(range.start) || _.isNaN(range.start)) {
              range.start = 1;
            } 
          }
        });
        return range;
      },      
      rangeExistsForUserId: function (userRanges, userId) {
        var rangeExists = false;
        _.forEach(userRanges, function (thisRange) {
          if (thisRange.user_id == userId) {
            rangeExists = true;
          }
        });
        return rangeExists;
      },
      sequenceExistsForName: function (sequencesToTest, sequenceName) {
        // check sequences for available names
        var output = false;
        let vm = this;
        _.forEach(sequencesToTest, function (thisSequence) {
          if (thisSequence.name == sequenceName) {
            output = true;
          }
        });
        return output;
      },
      assignedUserData: function(staffList, chosenStaffIds) {
        // array of :id and :full_name
        var localArray = [];
        _.forEach(staffList, function (thisUser) {
          if (_.includes(chosenStaffIds, thisUser[0])) {
            localArray.push(thisUser);
          }
        })
        return localArray;
      },
      assignedTemplateArray: function (arrayTemplateIDs) {
        var localTemplates = [];
        let vm = this;
        _.forEach(vm.allTemplates, function (thisTemplate) {
          if (_.includes(arrayTemplateIDs, thisTemplate[0])) {
            localTemplates.push(thisTemplate);
          }
        })
        return localTemplates;
      },
      sequenceNameForTemplateIds: function (arrayTemplateIDs) {
        var localSeq = [];
        let vm = this;
        // get all sequence names together
        _.forEach(vm.assignedTemplateArray(arrayTemplateIDs), function (thisTemplate) {
          localSeq.push(thisTemplate[2])
        });
        return _.uniq(_.flatten(localSeq));
      },
      updateSequence: function (index, instance) {
        this.sequencesAvailable.splice(index, 1, instance);
        //this.$set(this.instances, index, instance)
        this.$emit('update:sequences', this.sequencesAvailable);
      },
      updateCommonData: function (newCommonData) {
        this.$emit('update:commonData', newCommonData);
      },      
      userIdsIncludingLocked: function (newSelection) {
        // got through all users and add them to new selection and do a _.uniq
        var localArray = [].concat(newSelection);
        _.forEach(this.allAssignableStaff, function(thisUser) {
          if (thisUser[2] == true) {
            localArray.push(thisUser[0])
          }
        });
        return _.uniq(localArray);
      },
      lockedUsersMissing: function (newSelection) {
        // go through locked users and check they are in newSelection
        var localArray = [];
        _.forEach(this.allAssignableStaff, function(thisUser) {
          if ((thisUser[2] == true) && (!_.includes(newSelection, thisUser[0]))) {
            localArray.push(thisUser[1])
          }
        });
        return localArray;
      }
    }
  }
</script>

<style scoped lang="scss">
  .style-vue {
    background: #ffffff;
    width: 80%;
  }
  .style-vue .vue-disabled {
    background: #f0f0f0;
    width: 80%;
  }
  #vs1__combobox {
    height: 40px;
  }
  #vs2__combobox {
    height: 40px;
  }
  .locked-staff {
    font-weight: bold;
    font-style: italic;
  }
</style>