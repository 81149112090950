<template>
  <div>
    <spinner v-show="showSpinner"/>
  </div>
</template>

<script>

  import spinner from '../utils/spinner_overlay.vue'

  const IMAGE_MAX_AREA = 60000000;
  const IMAGE_MAX_AREA_VIEW = 3145728;  // same as app
  const IMAGE_MAX_DIMENSION = 16000; // internal limit for image magick // 32767; //https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas
  const IMAGE_MAX_SIZE = 20000000;

  export default {
    components: {
      spinner: spinner
    },
    props: {
        drawingSource: Blob,
        loadView: Boolean,
    },
    data: function () {
      return {
        showSpinner: false,
        loading: false
      }
    },
    watch: {
      drawingSource: function (file) {
        if (!_.isNil(file)) {
          this.loadImage();
        }
      },
      loadView: function () {
        if (!_.isNil(vm.drawingSource)) {
          this.loadImage();
        }
      }
    },
    methods: {
      loadImage: function () {
        let vm = this;
        setTimeout(() => {
          if (!_.isNil(vm.drawingSource) && !vm.loading) {
            vm.loading = true;
            doLoad(vm.drawingSource, vm.imageMaxArea());
          }
        }, 100);
        
        function doLoad(blob, maxArea) {
          if (!vm.requiredType(blob)) {
            // TODO: check other types which can be canvassed and expand range of allowable types
            error("Select a valid file (jpg or jpeg)... come on ...");
          // } else if (blob.size > IMAGE_MAX_SIZE) {
          //   // TODO: remove this check once we do our own views
          //   error("Please check your file, it needs to be less than 20MB ...");
          } else {
            let source = URL.createObjectURL(blob);
            let img = new Image();
            img.onload = function() {
              URL.revokeObjectURL(source);
              if (this.width > IMAGE_MAX_DIMENSION 
                  || this.height > IMAGE_MAX_DIMENSION 
                  || this.width * this.height > maxArea
                  || blob.size > IMAGE_MAX_SIZE
                  || blob.type != 'image/jpeg') {
                // only needs canvassing if too big or is not a jpeg
                vm.showSpinner = true;
                try {
                  scaleAndLoadImage(this, maxArea)
                } catch { 
                  error("Unexpected error loading image file.");
                }
              } else {
                success(false, blob);
              }
            };
            img.src = source;
            getImageSize(img, (w, h) => {
              if (w > IMAGE_MAX_DIMENSION 
                  || h > IMAGE_MAX_DIMENSION 
                  || w * h > maxArea
                  || blob.size > IMAGE_MAX_SIZE 
                  || blob.type != 'image/jpeg') {
                vm.showSpinner = true;  // user management - set this as soon as we can
              }
            });
          }

          function scaleAndLoadImage (image, maxPixels) {
            let width = image.width;
            let height = image.height;
            if (height > IMAGE_MAX_DIMENSION || width > IMAGE_MAX_DIMENSION || width * height > maxPixels) {
              let aspectRatio = image.width / image.height;

              width = Math.sqrt(maxPixels * aspectRatio);
              height = Math.sqrt(maxPixels / aspectRatio);

              let scale = IMAGE_MAX_DIMENSION / Math.max(width, height);
              if (scale < 1) {
                width = width * scale;
                height = height * scale;
              }
            }

            let canvas = document.createElement('canvas')
            canvas.width = width;
            canvas.height = height;
            let context = canvas.getContext('2d');
            context.drawImage(image, 0, 0, width, height);

            canvas.toBlob((blob) => {
              try {
                if (blob.size <= IMAGE_MAX_SIZE) {
                  success(true, blob);
                } else {
                  scaleAndLoadImage(image, maxPixels - maxPixels * 0.1); // reduce by 10% of original each time
                }
              } catch {
                error("Unexpected error loading image file.");
              }
            }, 'image/jpeg', 0.95);
          };
        };

        function getImageSize(img, callback) {
          let wait = setInterval(function() {
            let w = img.naturalWidth,
                h = img.naturalHeight;
            if (w && h) {
              clearInterval(wait);
              callback.apply(this, [w, h]);
            }
          }, 30);
        }

        function success(isScaled, result) {
          vm.$emit(isScaled ? "scaled_image" : "unscaled_image", result);
          finishedLoading();
        };

        function error(message) {
          vm.$emit("error", message);
          finishedLoading();
        };

        function finishedLoading() {
          vm.showSpinner = false;
          vm.loading = false;
        };
      },
      imageMaxArea: function () {
        return this.loadView ? IMAGE_MAX_AREA_VIEW : IMAGE_MAX_AREA;
      },
      requiredType: function (blob){
        return blob != null && blob.type == 'image/jpeg'
      },
    }
  }
</script>
