<template>
  <div class="integer-field control-group" v-if="isRow">
    <div class="controls" :title="label" v-show="isTableCellVisible">
      <input v-bind:name="formId" type="number" v-model.number="value" :disabled="isReadOnly" v-bind:class="[isValid ? '' : 'field-error', 'inspection-table-field', {dirty: isDirty}]" v-on:keydown.enter.prevent="">
    </div>
    <div class="controls" :title="label" v-show="!isTableCellVisible">
      &nbsp;
    </div>
  </div>
  <div class="integer-field control-group" v-show="isVisible" v-else>
    <label v-bind:for="formId" class="control-label">{{ label }}<span v-if="isRequired">*</span></label>
    <div class="controls">
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="showCriteria || showMandatoryText">
        <span v-if="showCriteria">{{ criteriaText }}</span>
        <span v-if="showMandatoryText">This field is mandatory.</span>
      </p>
      <input v-bind:name="formId" type="number" v-model.number="value" :disabled="isReadOnly" v-on:keydown.enter.prevent="" :class="[{dirty: isDirty}]">
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldVisibilityMixin from '../../mixins/field_visibility_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'
  import { EventBus } from '../../lib/event_bus'
  import { IntegerField } from '../../lib/fields/_fields.js'

  export default {
    mixins: [fieldMixin, fieldLabelMixin, fieldVisibilityMixin, fieldValidationMixin],
    data: function () {
      let initialValue = null;

      if (!_.isNil(this.data)) {
        initialValue = this.data.value
      }

      return {
        initialValue: initialValue,
        value: initialValue,
        field: IntegerField.create(this.specification),
      }
    },
    watch: {
      specification: function (spec) {
        this.field = IntegerField.create(spec);
      },
      value: _.debounce(function (newValue) {
          this.setNewValue(newValue)
        },150)
    },
    computed: {
      isPresent: function () {
        return !_.isNull(this.value)
      },
      hasCriteria: function () {
        return !!this.specification.maximum_value || !!this.specification.minimum_value;
      },
      isDirty: function () {
        return !(_.isEqual(this.initialValue, this.value) || this.checkBothEmpty(this.initialValue, this.value));
      }
    },
    methods: {
      checkBothEmpty: function (initial, current) {
        return ((_.isNull(initial) || initial === '' || _.isNaN(initial)) && (_.isNull(current) || current === '' || _.isNaN(current)))
      },
      setNewValue: function (newValue) {
        let dataUpdate = this.field.parse(newValue).data;
        this.currentValidity = this.assessValidity();
        this.$emit('update:data', dataUpdate)
      },
      checkCurrentCriteria: function () {
        let result = this.field.checkCriteria(this.value);
        this.criteriaValid = result.isValid;
        this.criteriaText = result.messages.length === 0 ? ""
            : result.messages[0] + ".";
      }
    },
    created: function () {
      let vm = this;
      if (!!vm.underRollup){
        EventBus.$on(vm.underRollup, (eventData) => {
          vm.visibleUnderRollup = eventData['rollup'];
          let thisShowHide = eventData['groupShowHide'];
          if (!!thisShowHide) {
            vm.groupShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
      if (!!vm.controlledField){
        EventBus.$on(vm.controlEvent, (ShowHideData) => {
          let thisShowHide = ShowHideData[vm.reference];
          if (!!thisShowHide) {
            vm.controlShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
    }
  }
</script>

<style scoped lang="scss">
  .inspection-table-field {
    width: 95%;
    padding-right: 0px;
    margin-bottom: 0px;
  }
  .control-group {
    margin-bottom: 0px;  
  }
</style>