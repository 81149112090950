<template>
  <div id="spinner-overlay" v-on:click.stop>
    <div class="centre" aria-hidden="true">
      <div class="spinner" aria-hidden="true">
      </div>
    </div>
    <div class="centre spinner-text" v-show="showCountdown">{{ countdownValue }}</div>
    <div :title="cancelHoverText" class="centre spinner-text cancel" v-show="showCancel" v-on:click="cancel">X</div>
  </div>
</template>

<script>
// used in drawing_editor.vue and drawing_pdf_document.vue
  export default {
    props: {
      countdownSeconds: Number,
      allowCancelAfter: Number,
      cancelHoverText: String
    },
    data: function () {
      return {
        countdownTimer: 0,
        cancelTimer: 0,
        countdownValue: -1,
        allowCancel: false
      }
    },
    watch: {
      countdownSeconds: function(value) {
        if (this.countdownTimer) {
          clearInterval(this.countdownTimer);
          this.countdownTimer = 0;
        }
        this.countdownValue = value <= 0 ? -1 : value;
        if (value > 0) {
          let vm = this;
          this.countdownTimer = setInterval(() => {
            if (vm.countdownValue == 0) {
              clearInterval(vm.countdownTimer);
            } else {
              vm.countdownValue--;
            }
          }, 1000)
        }
      },
      allowCancelAfter: function(value) {
        if (this.cancelTimer) {
          clearTimeout(this.cancelTimer);
          this.cancelTimer = 0;
        }
        if (value > 0) {
          let vm = this;
          this.cancelTimer = setTimeout(() => {
            vm.allowCancel = true;
          }, value);
        } else if (value == 0) {
          this.allowCancel = true;
        } else {
          this.allowCancel = false;
        }
      }
    },
    computed: {
      showCountdown: function() {
        return this.countdownValue > -1;
      },
      showCancel: function() {
        return this.allowCancel && !this.showCountdown;
      },
    },
    methods: {
      cancel: function() {
        if (this.allowCancel) {
          this.$emit('cancel');
        }
      }
    }
  }
</script>

<style scoped>
  #spinner-overlay {
    --spinner-color: #0088cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.5);
    z-index: 10000; /* make sure it's on top */
    cursor: default; 
  }
  .centre {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
  .spinner {
    width: 80px;
    height: 80px;
    border: 8px solid #00000000; /* transparent */
    border-top: 8px solid var(--spinner-color);
    border-left: 8px solid var(--spinner-color);
    border-right: 8px solid var(--spinner-color);
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .spinner-text {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border: none;
    color: var(--spinner-color);
    font-size: 16px;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    color: red;
  }
</style>