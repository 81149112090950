import * as base from "./shape"

export const Circle = (annotation) => {
  var shape = base.Shape(annotation);
  var utils = base.ShapeUtils();

  var annotation_data = annotation.annotation_data;

  var start = { x: 0, y: 0, data: annotation_data.origin };
  var end = { x: 0, y: 0, data: annotation_data.end_point };

  var radius;
  var origin;
  var line_width;

  var hot_spot_radius;

  function init(layoutParams) {
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    start.x = annotation_data.origin.x * overallSize.width - offset.x;
    start.y = annotation_data.origin.y * overallSize.height - offset.y;
    end.x = annotation_data.end_point.x * overallSize.width - offset.x;
    end.y = annotation_data.end_point.y * overallSize.height - offset.y;

    line_width = utils.strokeWidth(annotation_data.stroke_scale, overallSize.height);

    let origin_x = (start.x + end.x) / 2;
    let origin_y = (start.y + end.y) / 2;

    origin = {x: origin_x, y: origin_y};
    radius = Math.sqrt((origin_x - end.x) ** 2 + (origin_y - end.y) ** 2);
  }

  function draw(ctx, layoutParams, selectedIndices) {
    init(layoutParams);

    ctx.setGlobalAlpha(utils.strokeTransparency(annotation_data.stroke_opacity));
    ctx.setStrokeColor(annotation_data.stroke_color);
    ctx.setStrokeWidth(line_width);
    ctx.setLineJoin('round')
    ctx.setLineCap('round')

    ctx.strokeCircle(origin.x, origin.y, radius);

    if (annotation_data.fill_opacity) {
      let fillRadius = radius - line_width / 2;
      if (fillRadius >= 0) {
        ctx.setGlobalAlpha(utils.strokeTransparency(annotation_data.fill_opacity));
        ctx.setFillColor(annotation_data.fill_color ? annotation_data.fill_color : annotation_data.stroke_color);
        ctx.fillCircle(origin.x, origin.y, radius - line_width / 2);
      }
    }
    
    hot_spot_radius = line_width / 2;
    if (selectedIndices.length > 0) {
      hot_spot_radius = utils.hotSpotRadius(layoutParams.overallSize().height);
      utils.highlightPoints(ctx, utils.vectorIndiciesToPoints(selectedIndices, start, end), hot_spot_radius);
      utils.drawAnchors(ctx, utils.vectorAnchorsFromSelectedIndicies(selectedIndices, start, end), hot_spot_radius);
    }        
  }

  function findPoints(x, y) {
    let selected = [];
    if (utils.isOnHotSpot(x, y, start, hot_spot_radius)) {
      selected.push(0);
    } else if (utils.isOnHotSpot(x, y, end, hot_spot_radius)) {
      selected.push(1);
    }
    else if (isHere(x, y)) {
      selected.push(0);
      selected.push(1);
    }
    return selected;

    function isHere(x, y) {
      let lineLength = Math.sqrt((origin.x - x) ** 2 + (origin.y - y) ** 2);
      if (annotation_data.fill_opacity && annotation_data.fill_opacity > 0) {
        return lineLength <= radius + line_width / 2;
      } else {
        return Math.abs(lineLength - radius) <= line_width / 2;
      }
    }
  }
  function allSelectablePoints() {
    return [0, 1];
  }




  var foundPoints = [];
  var selectedPoints = [];
  var currentX = -1;
  var currentY = -1
    
  shape.draw = function(context, layoutParams, drawingOptions, highlight) {
    const ctx = base.DrawingContext(context);
    let points = !highlight 
        ? []
        : shape.isSelected() ? selectedPoints
        : allSelectablePoints();
    draw (ctx, layoutParams, points);
  }
  shape.selectHere = function(x, y) {
    if (currentX != x || currentY != y) {
      shape.isHere(x, y);
    }
    selectedPoints = foundPoints;  
  }
  shape.selectAll = function() {
    selectedPoints = allSelectablePoints();
  }
  shape.unselect = function() {
    selectedPoints = [];
  }
  shape.isSelected = function() {
    return selectedPoints.length > 0;
  }

  shape.isHere = function(x, y) {
    currentX = x;
    currentY = y;
    foundPoints = findPoints(x, y);
    return foundPoints && foundPoints.length > 0;
  }

  shape.move = function(x, y, layoutParams) {
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    return utils.move(x + offset.x, y + offset.y, utils.vectorIndiciesToPoints(selectedPoints, start, end), overallSize);
  }

  return shape;
}
