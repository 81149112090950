<template>
  <div>
    <div class="drawing-photo">
      <div class="display-layer">
        <drawing-canvas
          :width="imageViewPortWidth"
          :height="imageViewPortHeight"
          :offset="viewOffset"
          :overallSize="overallSize"
          :background="background"
          :shapes="shapes"
          :selectedAnnotationIds="selectedAnnotationIds"
          :annotationDrawingOptions="annotationDrawingOptions"
          :readOnly="readOnly"
          :redraw="redrawCanvas"
          @finished_drawing="() => $emit('finished_drawing')"
        />
      </div>
      <div class="drawing-container" ref="drawingViewContainer">
        <canvas tabindex="1" ref="viewCanvas" v-bind:width="canvasWidth" v-bind:height="canvasHeight" @mousedown.prevent="mousedown" @mousemove="mousemove" @mouseup="mouseup" @keydown="keydown"/>
      </div>
    </div>
    <drawing-canvas readOnly offScreen
      :width="saveWidth"
      :height="saveHeight"
      :offset="saveOffset"
      :overallSize="saveOverallSize"
      :background="background"
      :shapes="shapes"
      :selectedAnnotationIds="selectedAnnotationIds"
      :annotationDrawingOptions="saveDrawingOptions"
      :getImageData="getImageData"
      @saved_image_blob="(val) => { $emit('saveImageData', val); saveWidth = 0; saveHeight = 0; }"
    />
  </div>
</template>

<script>
  import _ from 'lodash'
  import { DrawingOptions, LayoutParams } from "../../lib/annotations/annotation"
  import DrawingCanvas from './drawing_canvas.vue'
  import { AnnotationTypes } from "../../lib/annotations/annotation"
  import { ShapeFactory } from "../../lib/annotations/annotation"
  import { jsPDF } from "jspdf"

  const SCROLL_BAR_WIDTH = 17; // TODO: make this robust against styling changes and browser zoom
  const ZOOM_RANGE_MULTIPLIER = 1.5; // this is how far beyond 100% we can zoom

  const annotationType = AnnotationTypes();
  const shapeFactory = ShapeFactory();

  export default {
    components: {
      DrawingCanvas,
    },
    props: {
      background: Image,
      annotations: Array,
      currentAnnotationId: String,
      selectedAnnotationIds: Array,

      annotationDrawingOptions: DrawingOptions,
      relativeZoomPosition: Number,
      findOrigin: Boolean,
      saveFunctionOptions: Object,
      readOnly: Boolean,
      allowSelectMultiple: Boolean,
      // drawSettings: Object,
      // colorSchemes: Object,
      mergeRadius: Number,
      mutateFunction: Function,
    },
    data: function () {
      // let options = new DrawingOptions();
      // let colorScheme = this.drawSettings?.balloon?.color_scheme;
      // let initialBalloonColor = _.get(this.colorSchemes, colorScheme);
      // let initialTextScale = this.drawSettings?.balloon?.text_scale ?? 20;
      // options.balloonOptions.drawBigPicturePointers = true;
      // options.balloonOptions.fillColorOverride = initialBalloonColor?.fill_color;
      // options.balloonOptions.textColorOverride = initialBalloonColor?.text_color;
      // options.balloonOptions.drawBorder = !initialBalloonColor?.fill_color;
      // options.balloonOptions.drawHeadlessArrows = !(this.drawSettings?.balloon?.draw_arrows ?? true);
      return {
        container: null,
        viewCanvas: null,
        canvasWidth: 600,
        viewZoomFocusX: -1,
        viewZoomFocusY: -1,
        preventPan: false,
        aspectRatio: 0,
        imageViewPortWidth: 0,
        imageViewPortHeight: 0,
        // annotationDrawingOptions: options,
        viewOffset: {x: 0, y: 0},
        overallSize: {width: 0, height: 0},

        shapes: [],

        selectStart: null,
        moveOffset: null,
        isMoving: false,
        selectionShape: null,
        redrawCanvas: false,
        selectedBalloons: [],
        layoutParams: new LayoutParams(),

        saveOverallSize: {width: 0, height: 0},
        saveWidth: 0,
        saveHeight: 0,
        saveOffset: {x: 0, y: 0},
        saveDrawingOptions: new DrawingOptions(),
        getImageData: false,
      };
    },
    watch: {
      background: function () {
        this.changeImage();
      },
      annotations: function(val) {
        this.shapes = shapeFactory.makeShapes(val, true);
      },
      currentAnnotationId: function(val) {
        this.panToCurrent();
      },
      mergeRadius: function(radius) {
        this.mergeAnnotations();
      },
      findOrigin: function () {
        this.panToCurrent();
      },
      shapes: function() {
        this.selectedShapesChanged();
      },
      selectedAnnotationIds: function() {
        this.selectedShapesChanged();
      },
      relativeZoomPosition: function () {
        let portX = this.viewZoomFocusX >= 0 ? this.viewZoomFocusX : this.imageViewPortWidth / 2;
        let portY = this.viewZoomFocusY >= 0 ? this.viewZoomFocusY : this.imageViewPortHeight / 2;
        let relativeScroll_x = (this.container.scrollLeft + portX) / this.canvasWidth;
        let relativeScroll_y = (this.container.scrollTop + portY) / this.canvasHeight;
        this.canvasWidth = parseInt((this.background?.naturalWidth ?? 0) * this.zoomFactor);
        this.$nextTick(() => { 
          this.overallSize = {width: this.canvasWidth, height: this.canvasHeight}
          let x = this.canvasWidth * relativeScroll_x - portX;
          let y = this.canvasHeight * relativeScroll_y - portY;
          this.container.scrollTo({
            top: y,
            left: x,
            behavior: 'auto'
          });
          // reset these so we go back to zooming on the view centre with the buttons
          this.viewZoomFocusX = -1;
          this.viewZoomFocusY = -1;
        });
      },
      overallSize: function(val) {
        this.layoutParams.setOverallSize(val.width, val.height);
        this.mergeAnnotations();
      },
      viewOffset: function(val) {
        this.layoutParams.setViewOffset(val.x, val.y);
      },
      selectionShape: function() {
        this.redrawView();
      },

      saveFunctionOptions: function (options) {
        if (options?.format === 'image') {
          this.startSave(options.area === 'only_visible_view')
        } else if (options?.format === 'pdf') {
          this.outputPdf();
        }
      },
      mutateFunction: function(mutate) {
        if (mutate) {
          mutate(this.shapes);
          this.emitSelectedAnnotationsData();
          this.redrawAnnotationCanvas();
        }
      },
    },
    computed: {
      canvasHeight: function () {
        return parseInt(this.canvasWidth/this.aspectRatio);
      },
      localSelectedAnnotationIds: function () {
        return this.selectedAnnotationIds ?? [];
      },
      zoomFactor: function () {
        let result = 1;
        let image_width = this.background?.naturalWidth ?? 1;
        if (image_width > 0) {
          let canvas_view_width = this.imageViewPortWidth;
          let default_zoom = canvas_view_width / image_width;
          let zoom_range = ZOOM_RANGE_MULTIPLIER - default_zoom;
          result = Math.abs(zoom_range) * this.relativeZoomPosition + default_zoom;
        }
        return result;
      },
    },
    mounted() {
      this.container = this.$refs.drawingViewContainer;
      this.viewCanvas = this.$refs.viewCanvas;
      window.addEventListener('resize', this.handleResize);
      this.container.addEventListener('scroll', this.handleScroll);
      this.container.addEventListener('wheel', this.handleWheel);
      this.changeImage();
    },
    methods: {
      changeImage: function() {
        this.aspectRatio = this.background ? this.background.naturalWidth / this.background.naturalHeight : 0;
        this.handleResize();
      },
      handleResize: function () {
        if (!this.background) return;
        this.imageViewPortWidth = this.container.clientWidth;// - SCROLL_BAR_WIDTH;
        this.canvasWidth = parseInt(this.background.naturalWidth * this.zoomFactor);
        this.$nextTick(() => this.overallSize = {width: this.canvasWidth, height: this.canvasHeight});

        // set the height of the document pane
        let containerRect = this.container.getBoundingClientRect();
        let viewPortHeight = document.documentElement.clientHeight;
        let heightReduction = 42; // lift it from the bottom a little
        let ratio = this.background.naturalWidth / this.canvasWidth;
        let maxHeight = this.background.naturalHeight / ratio;
        let height = viewPortHeight - containerRect.top - heightReduction;
        this.container.style.height = height+'px';
        this.imageViewPortHeight = Math.min(maxHeight, height - SCROLL_BAR_WIDTH + 1);
      },
      handleScroll: function () {
        this.viewOffset = {
          x: this.container.scrollLeft,
          y: this.container.scrollTop
        };
      },
      handleWheel: function (evt) {
        if (evt.ctrlKey) {
          evt.preventDefault();
          this.viewZoomFocusX = evt.offsetX - this.container.scrollLeft;
          this.viewZoomFocusY = evt.offsetY - this.container.scrollTop;
          this.$emit('zoom', evt.deltaY < 0);
        }
      },

      panToCurrent: function() {
        if (!this.preventPan && this.currentAnnotationId) {
          let shape = _.find(this.shapes, (s) => s.uuid == this.currentAnnotationId);
          if (shape) {
            let data = shape.getAnnotationData();
            let centre = this.findOrigin ? data.origin : data.end_point;
            let x = this.canvasWidth * centre.x - this.imageViewPortWidth / 2;
            let y = this.canvasHeight * centre.y - this.imageViewPortHeight / 2;
            this.container.scrollTo({
              top: y,
              left: x,
              behavior: 'smooth'
            });
          }
        }
      },

      mergeAnnotations: function() {
        let width = this.overallSize.width;
        let height = this.overallSize.height;

        if (width === 0 || height === 0) return;
        let mergeRadius = this.mergeRadius;

        function mergeFunction (annotations) {
          let radius = mergeRadius / 100 * width;
          if (radius === 0) return annotations;
          let inList = [...annotations].reverse();
          let outList = [];
          let next = inList.pop();
          while (next) {
            let mergeList = [];
            if (next.annotation_type === annotationType.balloon) {
              let origin = next.annotation_data.origin;
              for (let i = inList.length - 1; i >= 0; i--) {
                let comp = inList[i];
                if (comp.annotation_type === annotationType.balloon) {
                  let o = comp.annotation_data.origin;
                  let r = Math.sqrt(Math.abs(origin.x * width - o.x * width) ** 2 + Math.abs(origin.y * height - o.y * height) ** 2)
                  if (r <= radius) {
                    mergeList.push(comp);
                    inList.splice(i, 1);
                  }
                }
              }
            }
            if (mergeList.length === 0) {
              outList.push(next)
            } else {
              mergeList.unshift(next);
              outList.push({
                uuid: mergeList.map(a => a.uuid).join(),
                // uuid: generateUUID(),
                annotation_type: annotationType.mergeBalloon,
                text_balloons: mergeList,
              });
            }
            next = inList.pop();
          }
          return outList;
        };
        this.$emit('update:mergeFunction', mergeFunction); 
      },

      redrawAnnotationCanvas: function() {
        this.redrawCanvas = true;
        this.$nextTick(() => this.redrawCanvas = false);
      },
      redrawView: function() {
        if (!this.imageViewPortWidth || !this.imageViewPortHeight) {
          return;
        }

        let context = this.viewCanvas.getContext("2d");
        context.clearRect(0,0, this.viewCanvas.width, this.viewCanvas.height);

        if (this.selectionShape && this.allowSelectMultiple) {
          let vertexes = this.selectionShape;

          let path = new Path2D();
          path.moveTo(vertexes[0].x, vertexes[0].y);
          _.forEach(_.tail(vertexes), function(point) {
            path.lineTo(point.x, point.y);
          });
          path.lineTo(vertexes[0].x, vertexes[0].y);

          context.lineWidth = 2;
          context.globalAlpha = 1;
          context.lineJoin = 'round';
          context.strokeStyle = '#FFFF00';
          context.stroke(path);

          context.globalAlpha = 0.3;
          context.fillStyle = '#FFFF00';
          context.fill(path);
        }
      },
      keydown: function (evt) {
        if (evt.key === 'Escape') {
          evt.stopPropagation();
          this.selectStart = null;
          this.selectionShape = null;
        }
      },
      mousedown: function (evt) {
        if (evt.button == 0) {
          this.viewCanvas.focus();
          let foundShape = this.findHere(evt.offsetX, evt.offsetY);
          if (evt.button == 0 && !this.readOnly) {
            if (foundShape && !evt.ctrlKey) {
              this.moveOffset = {x: evt.offsetX, y: evt.offsetY};
            } else if (!foundShape) {
              this.selectStart = {x: evt.offsetX, y: evt.offsetY};
            }
            this.selectionShape = null;
            this.isMoving = false;
          }
          this.selectShape(foundShape, evt.offsetX, evt.offsetY, !evt.ctrlKey || !this.allowSelectMultiple);
        }
      },
      mouseup: function (evt) {
        if (evt.button == 0) {
          if (this.isMoving && !this.readOnly) {
            this.emitSelectedAnnotationsData();
          } else if (this.selectionShape) {
            let selected = evt.ctrlKey ? [...this.localSelectedAnnotationIds] : [];

            // TODO: adapt for polygon
            let left = Math.min(this.selectionShape[0].x, this.selectionShape[2].x);
            let right = Math.max(this.selectionShape[0].x, this.selectionShape[2].x);
            let top = Math.min(this.selectionShape[0].y, this.selectionShape[2].y);
            let bottom = Math.max(this.selectionShape[0].y, this.selectionShape[2].y);
            let balloonsSelected = _.filter(this.shapes, s => {
              if (s.type === annotationType.balloon || s.type === annotationType.mergeBalloon) {
                let end = s.getAnnotationData().end_point;
                let end_x = end.x * this.overallSize.width;
                let end_y = end.y * this.overallSize.height;
                return end_x >= left
                  && end_y >= top
                  && end_x <= right
                  && end_y <= bottom
              }
            });
            let ids = _.map(balloonsSelected, b => b.uuid);

            selected.push(...ids);
            this.emitCurrentAnnotationId(selected[0], 0);
            this.emitSelectedAnnotationIds(selected);
          }
          this.selectStart = null;
          this.moveOffset = null;
          this.isMoving = false;
          this.selectionShape = null;

          let x_rel = (evt.offsetX + this.container.scrollLeft - this.viewOffset.x) / this.canvasWidth;
          let y_rel = (evt.offsetY + this.container.scrollTop - this.viewOffset.y) / this.canvasHeight;
          this.$emit('left_click_relative_location' ,{x: x_rel, y: y_rel});
        }
      },
      mousemove: function (evt) {
        let flags = evt.buttons !== undefined ? evt.buttons : evt.which;
        let primaryMouseButtonDown = (flags & 1) === 1;
        if (primaryMouseButtonDown && this.localSelectedAnnotationIds.length > 0 && !this.readOnly && this.moveOffset) {

          let offsetX = evt.offsetX - this.moveOffset.x;
          let offsetY = evt.offsetY - this.moveOffset.y;

          if (offsetX !== 0 || offsetY !== 0) {
            let moveX = true;
            let moveY = true;
            _.forEach(this.selectedBalloons, (s) => { 
              if(!s.canMoveHeadX(offsetX, this.layoutParams)) moveX = false; 
              if(!s.canMoveHeadY(offsetY, this.layoutParams)) moveY = false;
            });

            _.forEach(this.selectedBalloons, (s) => { 
              s.moveHead(moveX ? offsetX : 0, moveY ? offsetY : 0, this.layoutParams);
            });

            if (moveX) {
              this.isMoving = true;
              this.moveOffset.x = evt.offsetX;
            }
            if (moveY) {
              this.isMoving = true;
              this.moveOffset.y = evt.offsetY;
            }
            this.redrawAnnotationCanvas();
          }
        } else if (this.selectStart && this.allowSelectMultiple) {
          this.selectionShape = [
            {x: this.selectStart.x, y: this.selectStart.y}, 
            {x: this.selectStart.x, y: evt.offsetY}, 
            {x: evt.offsetX, y: evt.offsetY}, 
            {x: evt.offsetX, y: this.selectStart.y}];
        }
      },
      selectedShapesChanged: function() {
        this.selectedBalloons = _.filter(this.shapes, s => this.localSelectedAnnotationIds.indexOf(s.uuid) >= 0 && (s.type === annotationType.balloon || s.type === annotationType.mergeBalloon));
        _.forEach(this.shapes, s => {
          if (this.selectedBalloons.indexOf(s) < 0) {
            s.unselect();
          } else if (!s.isSelected()) {
            s.selectAll();
          }
        });
      },
      findHere: function (x, y) {
        let last = this.shapes.length;
        for (let index = last - 1; index >= 0; index--) {
          let shape = this.shapes[index];
          if (shape.isHere(x - this.viewOffset.x, y - this.viewOffset.y)) {
            return shape;
          }
        }
        return null;
      },
      selectShape: function (shapeToSelect, x, y, isSingle) {
        let id = shapeToSelect ? shapeToSelect.uuid : null;
        let index = this.localSelectedAnnotationIds.indexOf(id);
        let selected = isSingle && index < 0 ? (id ? [id] : []) : [...this.localSelectedAnnotationIds];
        shapeToSelect?.selectHere(x - this.viewOffset.x, y - this.viewOffset.y);

        if (!isSingle && shapeToSelect) {
          if (index < 0) {
            selected.push(id);
          } else {
            selected.splice(index, 1);
          }
        }
        let selectedIndex = 0;
        if (shapeToSelect?.type === annotationType.mergeBalloon) {
          selectedIndex = shapeToSelect.findHeadRow(x - this.viewOffset.x, y - this.viewOffset.y);
        }

        this.preventPan = true;
        this.emitCurrentAnnotationId(id, selectedIndex);
        this.$nextTick(() => this.preventPan = false);

        this.emitSelectedAnnotationIds(selected);
      },
      emitCurrentAnnotationId: function(id, selectedRowIndex) {
        this.$emit('update:currentViewAnnotationId', { annotation_id: id, selected_row_index: selectedRowIndex });
      },
      emitSelectedAnnotationIds: function(ids) {
        this.$emit('update:selectedViewAnnotationIds', ids);
      },
      emitSelectedAnnotationsData: function() {
        this.$emit('update:annotations_data', _.map(this.selectedBalloons, s => { return {uuid: s.uuid, data: s.getAnnotationData()} }));
      },




      
      startSave: function(onlyVisibleView) {
        let ratio = this.background.naturalWidth / this.canvasWidth;
        let offsetX = onlyVisibleView
            ? this.viewOffset.x * ratio
            : 0;
        let offsetY = onlyVisibleView
            ? this.viewOffset.y * ratio
            : 0;
        this.saveOffset = {x: offsetX, y: offsetY};
        this.saveWidth = onlyVisibleView 
            ? this.imageViewPortWidth * ratio
            : this.background.naturalWidth;
        this.saveHeight = onlyVisibleView
            ? this.imageViewPortHeight * ratio
            : this.background.naturalHeight;

        this.saveDrawingOptions.balloonOptions.textScale = this.annotationDrawingOptions.balloonOptions.textScale;
        this.saveDrawingOptions.balloonOptions.drawHeadlessArrows = this.annotationDrawingOptions.balloonOptions.drawHeadlessArrows;
        this.saveDrawingOptions.balloonOptions.fillColorOverride = this.annotationDrawingOptions.balloonOptions.fillColorOverride;
        this.saveDrawingOptions.balloonOptions.textColorOverride = this.annotationDrawingOptions.balloonOptions.textColorOverride;
        this.saveDrawingOptions.balloonOptions.drawBorder = this.annotationDrawingOptions.balloonOptions.drawBorder;

        this.saveDrawingOptions.balloonOptions.drawBigPicturePointers = true;
        this.saveDrawingOptions.backgroundNaturalHeight = this.background.naturalHeight;
        this.saveOverallSize = {width: this.background.naturalWidth, height: this.background.naturalHeight};

        // initiate the draw and fetch - do this last
        this.getImageData = true;
        this.$nextTick(() => this.getImageData = false);
      },
      outputPdf: function() {
        let overallSize = {width: this.background.naturalWidth, height: this.background.naturalHeight};
        let orientation = overallSize.height > overallSize.width ? 'p' : 'l';
        let doc = new jsPDF({orientation: orientation, unit: 'px', format: 'a3'});
        let pageWidth = doc.internal.pageSize.getWidth();
        let pageHeight = doc.internal.pageSize.getHeight();

        let scale = Math.max(overallSize.height / pageHeight, overallSize.width / pageWidth)

        let size = { width: overallSize.width / scale, height: overallSize.height / scale };
        let offset = {x: pageWidth / 2 - size.width / 2, y: pageHeight / 2 - size.height / 2 };

        let pdfLayoutParams = new LayoutParams();
        pdfLayoutParams.setOverallSize(size.width, size.height);
        pdfLayoutParams.setViewOffset(-offset.x, -offset.y);

        doc.addImage(this.background, 'JPEG', offset.x, offset.y, size.width, size.height);

        let last = this.shapes.length;
        for (let index = 0; index < last; index++) {
          let shape = this.shapes[index];
          let selected = this.selectedAnnotationIds?.indexOf(this.shapes[index].uuid) >= 0;
          shape.draw(doc, pdfLayoutParams, this.annotationDrawingOptions, selected);
        }

        this.$emit('pdf_doc', doc);
      },
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
      this.container.removeEventListener('scroll', this.handleScroll);
      this.container.removeEventListener('wheel', this.handleWheel);
    }
  }
</script>


<style scoped lang="scss">
.drawing-photo {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
}
.drawing-container {
  grid-area: 1 / 1 / 1 / 1;
  max-width: 100%;
  max-height: 100%;
  overflow: scroll;
}
.display-layer {
  grid-area: 1 / 1 / 1 / 1;
  max-width: none;
}
</style>
