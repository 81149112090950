<template>
  <div :class="[dragAndDropCapable ? 'dropzone-selector-container' : '']">
    <dropzone :label="dropZoneLabel" :clickFunction="click" @files_selected="val => onFilesSelected(val)"/>
    <div v-show="!dragAndDropCapable" class="btn-group">
      <a class="btn btn-success" @click.stop="click">Select {{typeLabel}}</a>
    </div>
    <input type="file" :accept="mimeType" @change="onFileChosen" ref="fileSelect" class="hide">
  </div>
</template>

<script>
  import dropzone, { dragAndDropCapable } from './dropzone.vue'

  export default {
    components: {
      dropzone,
    },
    props: {
      typeLabel: String,
      mimeType: String,
    },
    data: function() {
      return {
        dragAndDropCapable: dragAndDropCapable,
        dragEvent: false,
      }
    },
    computed: {
      dropZoneLabel: function() {
        return "Drop your " + this.typeLabel + " file here, or click to select one";
      }
    },
    methods: {
      click: function () {
        this.$refs.fileSelect.click();
      },
      onFilesSelected: function (files) {
        if (files?.length) {
        this.$emit('file_selected', files[0]);
        }
      },
      onFileChosen: function (event) {
        this.onFilesSelected(event.target.files);
        this.$refs.fileSelect.value = ''; // reset the input field in case use chooses same file again
      },
    }
  }
</script>

<style scoped>
  .dropzone-selector-container {
    width: 100%;
    height: 80px;
  }
  .hide {
    display: none;
  }
</style>