<template>
  <div v-show="show">
    <div id="coordinate-overlay" v-on:click.self="cancel">
      <div id="coordinate-overlay-content">
        <div id="coordinate-selector">
          <div id="title">
            <button type="button" class="btn" style="float:right" v-on:click="cancel">Close</button>
            <button type="button" :class="['btn', {'btn-success': editingOrigin}]" v-on:click="onEditCoordinate('tl')">Top Left</button>
            <button type="button" :class="['btn', {'btn-success': editingEnd}]" v-on:click="onEditCoordinate('br')">Bottom Right</button>
            <div v-show="errorText.length > 0" class="alert alert-error small-alert">{{ errorText }}</div>
          </div>
          <div id="canvas-container">
            <!-- magnifier needs to be first -->
            <drawing-magnifier
              :subject="$refs.clickLayer"
              :imageSource="magnifierSource"
              :zoom="4"
              :crosshairColor="magnifierCrosshairColor"
              :redraw="magnifierRedraw"
              v-show="showMagnifier"
            />
            <canvas id="coordinate-sizer" ref="sizerCanvas"/> <!-- this is our sizer - do not hide it -->
            <drawing-version-display
              :currentDrawing="currentDrawing"
              :subjectImage="subjectImage"
              :selectedCoordinate="selectedCoordinate"
              :forceRedraw="coordinateRedraw"
              class="display-layer"
            />
            <div class="display-layer" ref="clickLayer" :class="{magnifier: showMagnifier}" v-on:click="canvasClicked"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import drawing_magnifier from './drawing_magnifier.vue'
import drawing_version_display from './drawing_version_display.vue'
import { TOP_LEFT_COLOR, BOTTOM_RIGHT_COLOR, TRANSPARENT } from './drawing_version_display.vue'

export default {
  components: {
    drawingMagnifier: drawing_magnifier,
    drawingVersionDisplay: drawing_version_display
  },
  props: {
    subjectImage: Image,
    currentDrawing: Object,
    show: Boolean
  },
  data: function () {
    return {
      selectedCoordinate: 'none',
      magnifierSource: null,
      magnifierRedraw: false,
      coordinateRedraw: false,
      errorText: ''
    }
  },

  watch: {
    subjectImage: function(image) {
      if (image != null) {
        this.magnifierSource = image.src;
      }
      this.redraw();
    },
    currentDrawing: function() {
      this.redraw();
    },
    show: function(show) {
      if (show) {
        this.coordinateRedraw = true;
        this.$nextTick(() => this.coordinateRedraw = false)
      }
    }
  },
  computed: {
    editingOrigin: function() {
      return this.selectedCoordinate == 'tl';
    },
    editingEnd: function() {
      return this.selectedCoordinate == 'br';
    },
    showMagnifier: function() {
      return this.editingOrigin || this.editingEnd;
    },
    magnifierCrosshairColor: function() {
      return this.editingOrigin ? TOP_LEFT_COLOR
        : this.editingEnd ? BOTTOM_RIGHT_COLOR
        : TRANSPARENT;
    },
  },
  methods: {
    onEditCoordinate: function(coordinate) {
      this.selectedCoordinate = coordinate == this.selectedCoordinate ? 'none' : coordinate;
      if (this.selectedCoordinate != 'none') {
        this.magnifierRedraw = true;
        this.$nextTick(() => this.magnifierRedraw = false);
      }
    },
    cancel: function () {
      this.selectedCoordinate = 'none';
      this.$emit("close");
    },
    redraw: function() {
      if (null != this.subjectImage) {

        let canvas = this.$refs.sizerCanvas;
        let context = canvas.getContext("2d");
        canvas.width = this.subjectImage.naturalWidth;
        canvas.height = this.subjectImage.naturalHeight;

        context.beginPath();
        context.moveTo(0, 0);
        context.lineTo(canvas.width, canvas.height);
        context.stroke();
      }
    },
    canvasClicked: function (event) {
      let rect = event.target.getBoundingClientRect();
      let rel_x = (event.clientX - rect.left) / rect.width;
      let rel_y = (event.clientY - rect.top) / rect.height;

      this.errorText = '';
      if (!this.checkValidLocation(rel_x, rel_y)) {
        let text = this.editingOrigin ? "This is not a valid location.\nIt must be higher and lefter by 20% than the other coordinate."
            : "This is not a valid location.\nIt must be lower and righter by 20% than the other coordinate.";
        this.errorText = text;
        // alert(text);
        return;
      }
      if (this.editingOrigin) {
        this.currentDrawing.top_left.splice(0, 2);
        this.currentDrawing.top_left.push(rel_x, rel_y);
      } else if (this.editingEnd) {
        this.currentDrawing.bottom_right.splice(0, 2);
        this.currentDrawing.bottom_right
        .push(rel_x, rel_y);
      }
    },
    checkValidLocation: function(x, y) {
      if (null == this.currentDrawing) {
        return false;
      }
      let other_x = this.editingOrigin 
        ? this.currentDrawing.bottom_right[0]
        : this.currentDrawing.top_left[0];
      let other_y = this.editingOrigin 
        ? this.currentDrawing.bottom_right[1]
        : this.currentDrawing.top_left[1];

      let a = this.currentDrawing.aspect_ratio;

      let x_adj = (a < 1.0) ? 1.0 : a;
      let y_adj = (a < 1.0) ? (1.0 / a) : 1.0

      let minSeperation = 0.2 * Math.max(x_adj, y_adj);

      let dx = (other_x - x) * x_adj;
      let dy = (other_y - y) * y_adj;
      let d = Math.sqrt(dx*dx + dy*dy);

      if (this.editingOrigin) {
        return dx > 0 && dy > 0 && d >= minSeperation;
      } else if (this.editingEnd) {
        return dx < 0 && dy < 0 && d >= minSeperation;
      }
      return true; // otherwise
    },
  }
}
</script>

<style scoped>
  #canvas-container {
    margin: 8px;
    position: relative;
  }
  #coordinate-sizer {
    height: 100%;
    width: 100%;
    visibility: hidden;
  }
  .display-layer {
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
  }
  .magnifier {
    cursor: crosshair;
  }
  #coordinate-overlay {
    --border-radius: 6px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    cursor: pointer;
    overflow: auto;
  }
  #coordinate-overlay-content {
    padding: 8px;
  }
  #coordinate-selector {
    position: absolute;
    top: 2%;
    left: 4%;
    width: 92%;
    background-color: rgba(255,255,255,1);
    border-radius: var(--border-radius);
  }
  .btn {
    margin: 8px;
  }
  #title {
    background-color:#EFEFEF;
    border: 1px solid #CDCDCD;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    height: 100%;
  }
  .title-text {
    margin-left: 12px;
  }
  .alert {
    display:inline-block; 
    margin-bottom:0;
  }
</style>