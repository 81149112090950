<template>
  <div class="inspection-editor" ref="inspectionEditor">
    <div v-if="!readOnly">
      <select name="save_instructions" id="save_instructions" v-model="saveChoice" :multiple="false" class="edit-choices" >
        <option :value="option[1]" v-for="option in saveChoices">{{ option[0] }}</option>
      </select>&nbsp;
      <button class="btn btn-primary" @click.prevent="tryAndComplete">Submit</button>
      <br><br>
      <div classs="control-group">
        <label class="control-label">Assign to ...</label>
        <div class="controls" v-if="haveAssessorList">
          <v-select 
            class="style-chooser"
            placeholder="Select from Staff on Work Package"
            v-model="thisAssessor"
            :searchable=true
            :clearable=true
            :multiple=false
            :options="theseAssessors"
            :clearSearchOnSelect=true>
          </v-select>
        </div>
        <div v-else>
          <b>There are no staff assigned to this workpackage...</b>
        </div>
      </div>
    </div>
    <br><br>
    <div class="alert alert-error small-alert error-finder" v-if="displayErrors">There are errors on some of the fields - please check for warnings.
      <button class="btn btn-small error-button" v-if="displayErrors" @click.prevent="scrollToNext">Next Error</button>
    </div>
    <div class="alert alert-error small-alert" v-if="displayNeedAssessor">You need to assign a member of staff before this can be completed.</div>
    <inclusion-section 
      :inclusionsSchema="inclusionsSchema" 
      :inclusions="inclusions" 
      @update:inclusions="inclusions => $emit('update:inclusions', inclusions)" 
      :read-only="readOnly">
    </inclusion-section>
    <inspection-section 
      :fields="fields" 
      :solution-type="solutionType"
      :data="data" 
      @update:data="data => $emit('update:data', data)" 
      :read-only="readOnly" 
      :new-form="newForm" 
      :drawing-sets="drawingSets"
      :under-rollups="rollupCoverage"
      :default-rollup="defaultRolledUp"
      :controlled-fields="controlledFields"
      :show-validation="showValidation"
      :validate-now="validateNow"
      :instanceReference="firstInstanceReference()"
      @update:errors="newErrors => updateValidityState(newErrors)">
    </inspection-section>
  </div>
</template>

<script>
  import InspectionSection from './inspection_section.vue'
  import InclusionSection from './inclusion_section.vue'
  import labelTemplateMixin from '../mixins/label_template_mixin'
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';

  export default {
    components: {
      InspectionSection,
      InclusionSection,
      vSelect
    },
    mixins: [labelTemplateMixin],
    props: {
      schema: Object,
      listsToUse: Object,
      solutionType: String,
      data: Object,
      inclusions: Object,
      readOnly: Boolean,
      newForm: Boolean,
      drawingSets: Array,
      saveInstructions: Array,
      saveDefault: Number,
      availableAssessors: Array,
      chosenAssessor: Number
    },
    provide: function () {
      return {
        sourceLists: this.listsToUse,
      }
    },
    data: function () {
      let selectableStaff = this.staffForSelection();
      let initialSelection = this.thisStaff(this.chosenAssessor)
      return {
        fieldValidity: [],
        currentValidity: 'valid',
        validateNow: '',
        saveChoice: this.saveDefault,
        saveChoices: this.saveInstructions,
        thisAssessor: initialSelection,
        theseAssessors: selectableStaff,
        tryToComplete: false,
        errorPositions: []
      }
    },
    watch: {
      thisAssessor: function (newAssessor) {
        let localID = _.isEmpty(newAssessor) ? null : _.toInteger(newAssessor.value);
        this.$emit('update:chosenAssessor', localID);
      },
      saveChoice: function (newChoice) {
        this.$emit('update:saveChoice', newChoice);
        document.getElementById('save_instructions').value = newChoice;
      }
    },
    computed: {
      hasAssessor: function () {
        return !_.isNil(this.chosenAssessor);
      },
      haveAssessorList: function () {
        return !_.isEmpty(this.theseAssessors);
      },
      displayNeedAssessor: function () {
        return this.tryToComplete && !this.hasAssessor;
      },
      displayErrors: function () {
        return this.tryToComplete && !this.isValid;
      },
      isValid: function () {
        this.setCurrentValidity()
        return (this.currentValidity == 'valid');
      },
      errorText: function () {
        return this.isValid ? "All Good in the Hood" : "Whoopsie!"
      },
      fields: function () {
        return this.schema.fields;
      },
      inclusionsSchema: function () {
        return this.schema.inclusions;
      },
      labelTemplateStr: function () {
        return this.schema.instance_label;
      },
      showValidation: function() {
        return this.tryToComplete;
      },
      rollupCoverage: function () {
        // iterate over fields, make corss reference
        let localHash = {};
        let lastRollup = '';
        let vm = this;
        _.forEach(vm.schema.fields, function(thisField) {
          if (!!thisField.rollup) {
            if (thisField.rollup == 'start') {
              lastRollup = vm.firstInstanceReference ()+ "-" + thisField.reference;
            } else {
              lastRollup = '';
            }
          };
          localHash[thisField.reference] = lastRollup;
        })
        return _.cloneDeep(localHash);
      },
      defaultRolledUp: function () {
        let localHash = {};
        let vm = this;
        _.forEach(this.schema.fields, function(thisField) {
          if (!!thisField.rollup) {
            if (thisField.rollup == 'start') {
              // If there is a style then test it else false
              if (!!thisField.style) {
                localHash[vm.firstInstanceReference() + "-" + thisField.reference] = vm.rollupState(thisField.style);
              } else {
                localHash[vm.firstInstanceReference() + "-" + thisField.reference] = false;
              }
            }
          }
        })
        return _.cloneDeep(localHash);
      },
      controlledFields: function () {
        // Check for any select that have rules and pull out an array of their names
        let localArray = [];
        let vm = this;
        _.forEach(vm.schema.fields, function(thisField) {
          if (thisField.type == 'selection') {
            if (!!thisField['field-control']) {
              // There are fields to control - get the field references
              _.forEach(thisField['field-control'], function (thisRule) {
                localArray = _.union(localArray, thisRule['field-references']);
              })
            }
          }
        })
        return localArray;
      }
    },
    methods: {
      scrollToNext: function () {
        let vm = this;
        let offset = 85;
        let currentPosition = document.documentElement.scrollTop + offset;
        vm.warningSigns();
        if (!_.isEmpty(vm.errorPositions)) {
          let firstError = vm.errorPositions[0];
          let nextPosition = _.find(vm.errorPositions, function(o) { return o > currentPosition })
          document.documentElement.scrollTo(0,nextPosition - offset);
        }
      },
      warningSigns: function () {
        let vm = this;
        let errorNodes = vm.$refs.inspectionEditor.querySelectorAll('p.criteria-block');
        vm.errorPositions = _.map(errorNodes, function (o) { return o.getBoundingClientRect().top + document.documentElement.scrollTop })
      },
      tryAndComplete: function () {
        let vm = this;
        if (vm.saveChoice == 4) {
          vm.tryToComplete = true;
          vm.validateNow = _.toString(Date.now());
          // wait for it....
          if (!vm.isValid || !vm.hasAssessor) return

          // check validity after a timeout
          vm.$nextTick( function () {if (vm.isValid && vm.hasAssessor) { vm.submitForm(); }})

        } else {
          vm.submitForm();
        }
      },
      submitForm: function() {
        document.getElementById('edit_inspection').submit();
      },
      thisStaff: function (userID) {
        let vm = this;
        var thisUser = null;
        _.forEach(vm.availableAssessors, function (user) {
            if (userID == user[1]) {
              thisUser = { "value": user[1], "label": user[0] };
              return thisUser;
            }
        });
      return thisUser;        
      },
      staffForSelection: function () {
        let vm = this;
        var staffArray = [];
        var thisUser = {}
        _.forEach(vm.availableAssessors, function (user) {
          thisUser = { "value": user[1], "label": user[0] };
          staffArray.push(thisUser);
        });
      return staffArray;
      },      
      firstInstanceReference: function () {
        return "MAIN-" + this._uid.toString();
      },
      updateValidityState: function (fieldError) {
        let fieldName = _.keys(fieldError)[0];
        let fieldValue = fieldError[fieldName];
        let fieldIndex = -1;
        let originalValidity = this.currentValidity;
        let vm = this;
        if (fieldValue == 'valid' && _.includes(vm.fieldValidity, fieldName)) {
          // field was in array, remove it
          fieldIndex = _.findIndex(vm.fieldValidity, function (entry) {
            return entry == fieldName;
          });
          if (fieldIndex > -1) {
            // found it - remove it
            vm.fieldValidity.splice(fieldIndex, 1);
          }
        } ;
        if (fieldValue == 'invalid' && !_.includes(vm.fieldValidity, fieldName)) {
          // add it in
          vm.fieldValidity.push(fieldName);
        } ;
      },
      setCurrentValidity: function () {
        this.currentValidity = (this.fieldValidity.length == 0) ? 'valid' : 'invalid';
        return this.currentValidity;
      },
      rollupState: function (fieldStyle) {
        let styleArray = _.split(fieldStyle.replace(/ /gi,""),",")
        let collapsedState = false;
        _.forEach(styleArray, function(styleEntry) {
          let thisEntry = _.split(styleEntry,":");
          if (thisEntry[0] == 'collapsed') {
            if (thisEntry[1] == 'true') {
              collapsedState = true
            }
          }
        });
        return collapsedState;
      }
    }
  }
</script>

<style scoped lang="scss">
  .edit-choices {
    margin-bottom: 0px;
    width: 350px;
    display: inline-block;
  }
  .small-alert {
    width: 569px;
    margin-bottom: 5px;
  }
  .style-chooser {
    width: 350px;
    display: inline-block;
    background: #ffffff;
    margin-bottom: 0px; 
  }
  .style-chooser input .vs__search {
    border: none;
    color: #FF0000;
    margin-bottom: 0px; 
  }
  .error-finder {
    position: sticky;
    top: 20px;
  }
  .error-button {
    right: 2px;
    position: absolute;
    top: 4px;
  }

</style>