/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> and
// <%= stylesheet_pack_tag 'hello_vue' %> to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import './main-style.sass'

import Vue from 'vue'
import VueResource from 'vue-resource/dist/vue-resource.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import VuePapaParse from 'vue-papa-parse'

Vue.use(VueResource)
Vue.use(TurbolinksAdapter)
Vue.use(VuePapaParse)

import App from '../App'
import _ from 'lodash'

const REQUEST_METHODS_FOR_CSRF_TOKEN = ['PUT', 'POST', 'PATCH', 'DELETE'];

Vue.http.interceptors.push(function(request) {
  if (_.includes(REQUEST_METHODS_FOR_CSRF_TOKEN, request.method)) {
    request.headers.set('X-CSRF-TOKEN', $('[name="csrf-token"]').attr('content'));
  }
});

document.addEventListener("turbolinks:load", () => {
  // If current page contains an app root element
  // mount the vue application to render any contained components
  const el = document.getElementById('app-root');

  if (el) {
    const props = JSON.parse(el.getAttribute('data'));

    const app = _.defaultsDeep({
      data: props
    }, App);


    new Vue(app).$mount(el);
  }
});
