import * as base from "./shape"
import _ from 'lodash'

export const FreeStyle = (annotation) => {
  var shape = base.Shape(annotation);
  var utils = base.ShapeUtils();

  var points;
  var line_width;

  var annotation_data = annotation.annotation_data;
  var hot_spot_radius;

  function init(layoutParams) {
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
   points = [];
    _.forEach(annotation_data.points, function(point) {
      let pt = {
        x: point.x * overallSize.width - offset.x,
        y: point.y * overallSize.height - offset.y,
        data: point
      };
      points.push(pt);
    });
    line_width = utils.strokeWidth(annotation_data.stroke_scale, overallSize.height);
  }

  function draw(ctx, layoutParams, selectedIndices) {
    if (annotation_data.points.length == 0) return;
    init(layoutParams);

    ctx.setGlobalAlpha(utils.strokeTransparency(annotation_data.stroke_opacity));
    ctx.setStrokeColor(annotation_data.stroke_color);
    ctx.setStrokeWidth(line_width);
    ctx.setLineJoin('round')
    ctx.setLineCap('round')

    ctx.beginPath();

    ctx.moveTo(points[0].x, points[0].y);

    _.forEach(_.tail(points), function(point) {
      ctx.lineTo(point.x, point.y);
    });

    ctx.stroke();

    hot_spot_radius = line_width / 2;
    if (selectedIndices.length > 0 && points.length > 0) {
      let pts = [];
      pts.push(points[0]);
      if (points.length > 1) {
        pts.push(points[points.length - 1]);
      }
      hot_spot_radius = utils.hotSpotRadius(layoutParams.overallSize().height);
      utils.highlightPoints(ctx, pts, hot_spot_radius);
    }        
  }

  function findPoints(x, y) {
    let selectedPoints = [];
    if (!points || points.length == 0 || !line_width) return selectedPoints;
    if (isHere(x, y)) {
      selectedPoints.push(0);
      selectedPoints.push(1);
    }
    return selectedPoints;

    function isHere(x, y) {
      if (utils.isOnHotSpot(x, y, points[0], hot_spot_radius)) {
        return true;
      }
      if (points.length > 1 && utils.isOnHotSpot(x, y, points[points.length - 1], hot_spot_radius)) {
        return true;
      }
 
      for (let index = 0; index < points.length - 1; index++) {
        if (utils.isOnPoint(x, y, points[index], line_width)
          || utils.isOnLine(x, y, points[index], points[index + 1], line_width)) {
          return true;
        }
      }
      return utils.isOnPoint(x, y, points[points.length - 1], line_width);
    };
  }

  function getPoints() {
    let selectedPoints = [];
    if (!points || points.length == 0 || !line_width) return selectedPoints;
    for (let index = 0; index < points.length; index++) {
      selectedPoints.push(points[index]);
    }
    return selectedPoints;
  }
  function allSelectablePoints() {
    return [0, 1];
  }




  var foundPoints = [];
  var selectedPoints = [];
  var currentX = -1;
  var currentY = -1
    
  shape.draw = function(context, layoutParams, drawingOptions, highlight) {
    const ctx = base.DrawingContext(context);
    let points = !highlight 
        ? []
        : shape.isSelected() ? selectedPoints
        : allSelectablePoints();
    draw (ctx, layoutParams, points);
  }
  shape.selectHere = function(x, y) {
    if (currentX != x || currentY != y) {
      shape.isHere(x, y);
    }
    selectedPoints = foundPoints;  
  }
  shape.selectAll = function() {
    selectedPoints = allSelectablePoints();
  }
  shape.unselect = function() {
    selectedPoints = [];
  }
  shape.isSelected = function() {
    return selectedPoints.length > 0;
  }

  shape.isHere = function(x, y) {
    currentX = x;
    currentY = y;
    foundPoints = findPoints(x, y);
    return foundPoints && foundPoints.length > 0;
  }

  shape.move = function(x, y, layoutParams) {
    let offset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    if (selectedPoints.length > 0 && points.length > 0) {
      return utils.move(x + offset.x, y + offset.y, getPoints(), overallSize);
    }
  }

  return shape;
}
