import _ from 'lodash'

const type = 'decimal';
const DecimalFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  function checkCriteria(value) {
    let messages = [];
    let isValid = true;

    let hasMin = !!spec.minimum_value;
    let hasMax = !!spec.maximum_value;
    if (hasMin || hasMax) {
      let parsed = parse(value);
      if (!_.isNil(parsed.data?.value)) {
        let val = parseFloat(parsed.data?.value);
        let minMet = !hasMin || (val !== Number.NEGATIVE_INFINITY && val >= _.toNumber(spec.minimum_value));
        let maxMet = !hasMax || (val !== Number.POSITIVE_INFINITY && val <= _.toNumber(spec.maximum_value));
        isValid = minMet && maxMet;
        if (!isValid) {
          let message = "Value must be "
            + (hasMin ? "greater than or equal to " + spec.minimum_value + (hasMax ? " and " : "") : "")
            + (hasMax ? "less than or equal to " + spec.maximum_value : "");
          messages.push(message);
        }
      } else if (parsed.messages.length > 0) {
        isValid = false;
        messages.push(parsed.messages[0])
      }
    }
    return {
      isValid: isValid,
      messages: isValid ? [] : messages,
    }
  };

  function parse(value) {
    let output = null;
    let messages = [];
    let isEmpty = false;

    let newFloat = NaN;
    if (typeof value === 'string' || value instanceof String) {
      let matches = value?.match(/^\s*([+|-]?\d+(\.\d+)?|\d+\.\d+[e|E][+|-]\d+)\s*$/);
      if (_.isArray(matches)) {
        newFloat = parseFloat(matches[1]);
      } else {
        let val = value.trim();
        isEmpty = _.isEmpty(val) || val == '+' || val == '-';
      }
    } else {
      newFloat = parseFloat(value);
      isEmpty = _.isNil(value);
    }
    if (!_.isNaN(newFloat)) {
      let newValue = newFloat.toString();
      if (newValue.includes('e')) {
        messages.push("Decimal number is too big for the field");
      } else {
        output = {
          type: type,
          value: newValue,
        };
      }
    } else if (!isEmpty) {
      messages.push("Value must be a decimal");
    }

    return {
      data: output,
      messages: messages,
    };
  };
  return {
    type,
    reference: spec.reference,
    parse: parse,
    checkCriteria: checkCriteria,
  }
}

export const DecimalField = {
  type,
  create: (specification) => DecimalFieldImpl(specification),
}
