import Vue from 'vue'
import AssetMapControls from '../components/asset_map_controls.vue'
import _ from 'lodash'

class MapControls {
  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');

    this._vm = new Vue(_.defaults({
      propsData: {
        map: map,
        control: this
      }
    }, AssetMapControls));

    this._vm.$mount(this._container);

    return this._vm.$el;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._vm.$el);
    this._vm.$destroy();

    this._vm = undefined;
    this.map = undefined;
  }

  $on(event, callback) {
    this._vm.$on(event, callback);
  }

  $off(event, callback) {
    this._vm.$off(event, callback);
  }
}

export default MapControls
