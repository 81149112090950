<template>
  <div id="versions-list" class="row-fluid">
    <div v-for="version in assets.slice().reverse()" :key="version.original_asset_id" class="span4 drawing-version">
      <div class="widget-box">
        <div class="widget-title">
          <h5>{{getVersionId(version)}} {{version.version}}</h5>
          <div class="buttons btn-group">
            <button v-if="!version.current" class="btn btn-mini" name="edit_button" v-on:click.stop="selectNewVersion(version.original_asset_id)">
                <!-- <i class="icon-edit icon-white"></i> -->
                Make Current
              </button>
            <a class="btn btn-primary btn-mini" :href="editVersionUrl(version)">
                <i class="icon-edit icon-white"></i>
                Edit Version
              </a>
          </div>
          <h5 v-if="version.current" class="current-version-header">(Current Version)</h5>
        </div>
        <div :class="['widget-content', {'current-version': version.current}]">
          <img class="photo" :src="photoUrl(version)">
          <!-- <drawing-version-display
            :currentDrawing="version"
            :subjectImage="coordinateImage"
            :selectedCoordinate="'all'"
            :style="thumbSize(version)"
          /> -->
        </div>
      </div>

    </div>
    <input type="submit" id="submit_form" style="display:none" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
    props: {
      assets: Array
    },
    data: function () {
      var initialName = this.drawingName;
      return {
        newName: initialName,
      }
    },
    mounted: function() {
      window.addEventListener('resize', this.setDocumentHeight);
      this.setDocumentHeight();
    },
    beforeUnmount: function() {
      window.removeEventListener('resize', this.setDocumentHeight);
    },
    methods: {
      thumbSize: function (version) {
      return {
        width: '600px',
        height: 600 / version.aspect_ratio + 'px'
      }
    },
    getVersionId: function(version) {
        let index = _.findIndex(this.assets, { 'original_asset_id' : version.original_asset_id }) + 1;
        return '(v'+index+')';
      },
      selectNewVersion: function(uuid) {
        if (uuid != "" && confirm("This will change the current version for all users immediately.\n\nAre you sure?")) {
          this.$http.put(window.location.pathname + '/update_current_version?version_asset_id=' + uuid, '{}')
            .then((response) => {
              if (response.body.result) {
                _.forEach(this.assets, (assetEntry) => { 
                  assetEntry.current = assetEntry.original_asset_id == uuid; 
                });
              } else {
                // some failure message... ?
              }
            });
        }
      },
      photoUrl: function(version) {
        let uuid = _.isEmpty(version.view_id) ? version.original_asset_id : version.view_id;
        return this.assetUrl(uuid);
      },
      assetUrl: function (assetId) {
        return '/o/' + this.organisationId() + '/assets/' + (assetId)
      },
      editVersionUrl: function (version) {
        return window.location.pathname + '/edit_version?version_asset_id=' + version.original_asset_id;
      },

      organisationId: function () {
        const organisationIdRegex = /^\/o\/(\d+)\//g;
        let matches = organisationIdRegex.exec(window.location.pathname)

        if (matches) {
          return matches[1]
        }
      },
      setDocumentHeight: function() {
        let pdfDoc = document.getElementById("versions-list");
        let viewPortHeight = document.documentElement.clientHeight;
        let heightReduction = 30;// lift it from the bottom to reduce chances of a page scrollbar too
        let height = viewPortHeight - pdfDoc.getBoundingClientRect().top - heightReduction;
        pdfDoc.style.height = height+'px';
      },
    }
  }
</script>

<style scoped>
#versions-list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}
.drawing-version {
  margin-left: 0;
  margin-right: 20px;
}
.current-version {
  background-color:green;
}
.current-version-header {
  float: right;
}

</style>
