<template>
  <div id="filter-controls">
    <div class="text-filters">
    <h5>Street Name</h5>
    <input type="text" v-model="streetNameFilter">
    </div>
    <div class="text-filters">
    <h5>Asset Number</h5>
    <input type="text" v-model="rammAssetIDFilter">
    </div>
    <h5>Inspection Due Date</h5>
    <input type="checkbox" id="date-filter" v-model="enableDateFilter" class="map-checkbox">
    <label for="date-filter" style='display:inline;'>Enable Date Filter</label></br>
    <input :disabled="!enableDateFilter" type="date" v-model="dueFromDate">
    <input :disabled="!enableDateFilter" type="date" v-model="dueToDate">


    <div class="widget-box">
      <div class="widget-title">
        <h5 class="toggle-header">
          <span>Inspection Status</span>
          <a v-on:click="statusUnrolled = !statusUnrolled">
            <i :class="['icon', statusUnrolled ? 'icon-chevron-down' : 'icon-chevron-up']" title="Click to toggle"></i>
          </a>
        </h5>
      </div>
      <transition name="slide-fade">
        <div class="widget-content" v-show="statusUnrolled">
          <div class="filter-container">
            <ul class="status-option-list">
              <li v-for="statusOption in statusFilterOptions">
                <input type="checkbox" v-bind:id="'option-' + statusOption" v-bind:value="statusOption" v-model="statusFilter" class="map-checkbox">
                <label v-bind:for="'option-' + statusOption">{{ humaniseToken(statusOption) }}</label>
              </li>
            </ul>
          </div>

        </div>
      </transition>
    </div>

    <div class="widget-box">
      <div class="widget-title">
        <h5 class="toggle-header">
          <span>Traffic Management Level</span>
          <a v-on:click="trafficLevelUnrolled = !trafficLevelUnrolled">
            <i :class="['icon', trafficLevelUnrolled ? 'icon-chevron-down' : 'icon-chevron-up']" title="Click to toggle"></i>
          </a>
        </h5>
      </div>
      <transition name="slide-fade">
        <div class="widget-content" v-show="trafficLevelUnrolled">
          <div class="filter-container">
            <ul class="status-option-list">
              <li v-for="option in trafficManagementFilterOptions">
                <input type="checkbox" v-bind:id="'option-' + option.key" v-bind:value="option.key" v-model="trafficManagementFilter" class="map-checkbox">
                <label v-bind:for="'option-' + option.key">{{ option.value }}</label>
              </li>
            </ul>
          </div>  
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'
  import MapMixin from '../mixins/map_mixin'

//  const ASSET_STATUS = ['normal', 'monitored', 'overdue', 'planned', 'in_progress']
  const ASSET_STATUS = ['normal', 'overdue', 'planned', 'in_progress']

  const TRAFFIC_MANAGEMENT = [
    {key: "U", value: "Unknown"},
    {key: "2L", value: "Level 2L"},
    {key: "LVLR", value: "Low volume < 250 vpd with low risk"},
    {key: "L", value: "<500 vpd (Low Volume)"},
    {key: "1", value: "< 10,000 vpd Rural OR <15,000 vpd Urban"},
    {key: "2", value: ">= 10,000 vpd Rural OR >=15,000 vpd Urban"},
    {key: "3", value: ">=10,000 vpd AND high speed"},
    {key: "2LS", value: ">= 15,000 vpd Urban road"},
    {key: "SH", value: "State Highway"},
    {key: "PVT", value: "Private"}
  ]

  export default {
    mixins: [MapMixin],
    data: function () {
      return {
        streetNameFilter: '',
        rammAssetIDFilter: '',
        dueFromDate: null,
        dueToDate: null,
        enableDateFilter: false,
//        statusFilter: ['normal', 'monitored', 'overdue', 'planned'],
        statusFilter: ['normal', 'overdue', 'planned'],
        statusFilterOptions: ASSET_STATUS,
        trafficManagementFilter: ["U", "2L", "LVLR", "L", "1", "2", "3", "2LS", "SH", "PVT"],
        trafficManagementFilterOptions: TRAFFIC_MANAGEMENT,
        statusUnrolled: true,
        trafficLevelUnrolled: false
      }
    },
    mounted: function () {
      this.updateFilter()
    },
    watch: {
      statusFilter: function (newStatusFilter) {
        this.updateFilter()
      },
      trafficManagementFilter: function(newFilter) {
        this.updateFilter()
      },
      streetNameFilter: _.debounce(function (newFilter) {
        this.updateFilter()
      },150),
      rammAssetIDFilter: _.debounce(function (newFilter) {
        this.updateFilter()
      },150),
      enableDateFilter: function () {
        this.updateFilter()
      },
      dueFromDate: function () {
        this.updateFilter()
      },
      dueToDate: function() {
        this.updateFilter()
      }
    },
    methods: {
      updateFilter: function () {
        let vm = this;

        let trafficPredicate = (feature) => {
          return _.includes(vm.trafficManagementFilter, feature.properties.traffic_management_level)
        }

        let statusPredicate = (feature) => {
          return _.includes(vm.statusFilter, feature.properties.status)
        }

        let streetNamePredicate = (feature) => {
          if (vm.streetNameFilter) {
            return _.startsWith(_.toLower(feature.properties.road_name), _.toLower(vm.streetNameFilter))
          } else {
            return true
          }
        }

        let rammAssetIDPredicate = (feature) => {
          if (vm.rammAssetIDFilter) {
            return _.includes(_.trim(vm.rammAssetIDFilter).split(' '), _.trim(feature.properties.id))
          } else {
            return true
          }
        }

        let dueToMoment = moment(vm.dueToDate)
        let dueFromMoment = moment(vm.dueFromDate)

        let dueDatePredicate = (feature) => {
          if (vm.enableDateFilter && dueToMoment.isValid() && dueFromMoment.isValid()) {
            let dueDate = moment(feature.properties.inspect_due_date)

            return dueDate.isBetween(dueFromMoment, dueToMoment)
          } else {
            return true
          }
        }

        let predicate = _.overEvery(trafficPredicate, statusPredicate, rammAssetIDPredicate, streetNamePredicate, dueDatePredicate)

        this.$emit('update:filter', predicate)
      }
    }
  }
</script>

<style scoped lang="scss">
  .status-option-list {
    list-style-type: none;
  }
  .status-option-list label {
    display: inline-block;
  }
  .map-checkbox {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .text-filters {
    display: inline-block;
  }
  .toggle-header {
    width: 100%; 
    box-sizing: border-box; 
    display: flex; 
    justify-content: space-between
  }
  .slide-fade-enter-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
  }    
</style>