<template>
  <div>
    <div v-if="solutionOptions.length > 0" class="filter-container">
      <div>
      <ul class="choice-option-list">
        <li v-for="option in solutionOptions">
          <span title="Tick to show this type of work">
            <input type="checkbox" v-bind:id="'option-' + option.key" v-bind:value="option.key" v-model="chosenSolutions" class="choice-checkbox">
            <label v-bind:for="'option-' + option.key">{{option.value}}</label>
          </span>
        </li>
      </ul>
      </div>
    </div>
    <div class="filter-container">
      <label for="filter_assigned_to">Inspection State:</label>
      <div>
      <ul class="choice-option-list stretch-width">
        <li v-for="option in stateFilterOptions" class="state-option">
          <span v-bind:title="option">
            <input type="checkbox" v-bind:id="'option-' + option" v-bind:value="option" v-model="chosenStates" class="choice-checkbox">
            <i v-bind:class="'material-icons annotation-unstyled ' + stateClass(option)">{{ stateIcon(option) }}</i>
          </span>
        </li>
      </ul>
      </div>
    </div>
    <div class="filter-container">
      <label for="filter_assigned_to">Inspection assigned to:</label>
      <select v-model="selectedAssignedTo" name="filter[assigned_to]" id="filter_assigned_to">
        <option value="">All</option>
        <option :value="option[0]" v-for="option in assignedToList">{{ option[1] }}</option>
      </select>
    </div>
    <div class="filter-container">
      <input type="checkbox" id="useDateFilter" v-model="useDateFilter" class="choice-checkbox">
      <label for="useDateFilter" class="inline-block">Inspection completed date</label>
      <input v-model="dateCompletedFrom" type="date" title="From date" class ="stretch-width">
      <input v-model="dateCompletedTo" type="date" title="To date" class ="stretch-width">  
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'
  import DrawingBalloonList from "./drawing_balloon_list"

  const FILTER_STATES = ['draft','active', 'finished']

  export default {
    components: {
      DrawingBalloonList,
    },
    props: {
      solutionsFieldList: Object,
      solutionsFilter: Array,
      assignedToList: Array,
    },
    data: function () {
      return {
        chosenSolutions: this.solutionsFilter,
        stateFilterOptions: FILTER_STATES,
        chosenStates: FILTER_STATES,
        selectedAssignedTo: "",
        useDateFilter: false,
        dateCompletedFrom: "",
        dateCompletedTo: "",
      };
    },
    watch: {
      selectedAssignedTo: function (newValue) {
        this.updateFilter();
      },
      useDateFilter: function (newValue) {
        this.updateFilter();
      },
      dateCompletedFrom: function (newValue) {
        this.updateFilter();
      },
      dateCompletedTo: function (newValue) {
        this.updateFilter();
      },
      chosenSolutions: function (newValue) {
        this.updateFilter();
        this.$emit('update:showSolutions', newValue)
      },
      chosenStates: function (newValue) {
        this.updateFilter();
      },
    },
    computed: {
      showSolutionFilters: function () {
        return this.canFilterSolutions(this.solutionsFieldList);
      },
      solutionOptions: function () {
        return this.makeSolutionOptions(this.solutionsFieldList)
      },
    },
    mounted() {
      this.updateFilter();
    },
    methods: {
      updateFilter: function () {
        let vm = this;

        let solutionPredicate = (annotation) => {
          return _.includes(vm.chosenSolutions,annotation.solution_token);
        }

        let statePredicate = (annotation) => {
          let state = annotation.inspection_state
          if (state == 'completed' || annotation.inspection_state == 'reporting') {
            state = 'finished'
          }
          return _.includes(vm.chosenStates, state);
        }

        let assignedPredicate = (annotation) => {
          let assignedTo = vm.selectedAssignedTo;
          return (!_.isFinite(assignedTo) || assignedTo == annotation.inspection_assigned_to);
        }

        let completedFromMoment = moment(vm.dateCompletedFrom)
        let completedToMoment = moment(vm.dateCompletedTo)
        
        let completedDatePredicate = (annotation) => {
          if (vm.useDateFilter) {
            let dueDate = moment(annotation.inspection_completed_at)
            return (!completedFromMoment.isValid() || dueDate.isAfter(completedFromMoment))
              && (!completedToMoment.isValid() || dueDate.isBefore(completedToMoment))
          } else {
            return true
          }
        }

        let predicate = _.overEvery(solutionPredicate, statePredicate, assignedPredicate, completedDatePredicate)
        
        this.$emit('update:filter', predicate)
      },
      canFilterSolutions: function (solutionList) {
        return !_.isEmpty(solutionList);
      },
      makeSolutionOptions: function (solutionList) {
        if (!this.canFilterSolutions(solutionList)) { return [] };
        let result = [];
        _.forEach(solutionList, function (soln, key) {
          result.push({key: key, value: soln.name});
        })
        //result << {key: 'OTH', value: 'Other'};
        return result;
      },
      stateClass: function (state) {
        switch (state) {
          case 'active': return 'annotation-active';
          case 'completed':
          case 'reporting':
          case 'finished': return 'annotation-finished';
          default: return 'annotation-draft';
        }
      },
      stateIcon: function(state) {
        switch (state) {
          case 'active': return 'loop';
          case 'completed':
          case 'reporting':
          case 'finished': return 'check_circle';
          default: return 'lens';
        }
      },
      inspectionState: function (state) {
        switch (state) {
          case 'completed':
          case 'reporting':
          case 'finished': return 'finished';
          default: return state;
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .choice-option-list {
    list-style-type: none;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .choice-option-list label {
    display: inline-block;
  }
  .state-option {
    display: inline-block;
    margin-right: 15px;
    * {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      column-gap: 4px;
    }
  }
  .inline-block {
    display: inline-block;
  }
  .choice-checkbox {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .filter-container {
    margin-top: 8px;
    margin-left: -6px;
    margin-right: -6px;
    padding:6px;
    background-color:#eee;
  }
  .stretch-width {
    width: stretch;
  }
  .annotation-unstyled {
    font-size: unset;
  }
  .annotation-finished {
    color: #4CAF50;
  }
  .annotation-draft {
    color: #b9b8b8;
  }
  .annotation-active {
    color: #4CAF50;
  }
</style>