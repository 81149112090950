<template>
  <div>
    <div id="pdf-overlay" v-show="selectedPdfFile" v-on:click.self="cancel">
      <div id="pdf-overlay-content">
        <div id="pdf-selector">
          <div class="title">
            <h5 class="title-text">Select a page to load</h5>
            <select v-model="thumbnailSizeOption" style="float:right">
              <option :value="option.key" v-for="option in sizeOptions" :key="option.key">{{ option.value }}</option>
            </select>
          </div>
          <div id="loading_progress_bar" 
            :aria-valuenow="loadedPercent" 
            aria-valuemin="0" 
            aria-valuemax="100"
            :style="{width: loadedPercent + '%'}">
          </div>
          <pdfDocument
            id="pdf_document"
            v-bind="{selectedPdfFile, selectedPages}"
            :showDetails="false"
            :drawingDetails="drawings"
            :pageSizeOption="thumbnailSizeOption"
            @pageClicked="val => onPageClicked(val)"
            @update:drawing="val => onUpdateDrawing(val)"
            @loading_percent="val => loadedPercent = val"
          />
          <button type="button" class="btn" v-on:click="cancel">Cancel</button>
          <button id="load-button" type="button" class="btn btn-primary" v-on:click="onUseSelected" :disabled="!allowUse">Load Selected Page</button>
        </div>
      </div>
    </div>
    <spinner 
      :allowCancelAfter="cancelAfter"
      :cancelHoverText="'Whoops...  Looks like I\'ve disappeared into my own navel!\nClick to cancel.'"
      @cancel="cancel()"
      v-show="haveDrawingsToLoad"
    />
  </div>
</template>


<script>
  import pdf_view from './drawing_pdf_document.vue'
  import { sizeOptions as pageSizeOptions } from './drawing_pdf_document.vue'
  import { PdfImageGenerator } from "../../lib/pdf_image_generator"
  import spinner from '../utils/spinner_overlay.vue'

  const CANCEL_AFTER = 15000; // after 15 seconds allow the user to stop it

  export default {
    components: {
      pdfDocument: pdf_view,
      spinner: spinner,
    },
    props: {
      selectedPdfFile: File,
    },
    data: function () {
      return {
        selectedPages: [],
        drawings: [],
        drawingsToLoad: [],
        cancelAfter: -1,
        sizeOptions: pageSizeOptions,
        thumbnailSizeOption: pageSizeOptions[1].key,
        loadedPercent: 0,
      }
    },
    computed: {
      allowUse: function () {
        return this.selectedPages.length > 0;
      },
      haveDrawingsToLoad: function () {
        return this.drawingsToLoad.length > 0;
      },
    },
    mounted: function() {
      window.addEventListener('resize', this.setDocumentHeight);
    },
    beforeUnmount: function() {
      window.removeEventListener('resize', this.setDocumentHeight);
    },
    watch: {
      selectedPdfFile: function(file) {
        this.cancelAfter = -1;
        this.loadedPercent = 0;
        document.getElementById("pdf_document").scrollTop = 0;
        this.selectedPages = [];
        this.$nextTick(() => this.setDocumentHeight());
      }
    },
    methods: {
      cancel: function () {
        this.$emit("cancel");
      },
      setDocumentHeight: function() {
        // set the height of the document pane
        let pdfDoc = document.getElementById("pdf_document");
        let loadButton = document.getElementById("load-button");
        let loadButtonMargins = 16; // top and bottom - copied from styles below
        let loadButtonHeight = loadButton.getBoundingClientRect().height + loadButtonMargins;
        let viewPortHeight = document.documentElement.clientHeight;
        let heightReduction = viewPortHeight * 0.04; // lift it from the bottom
        let height = viewPortHeight - pdfDoc.getBoundingClientRect().top - loadButtonHeight - heightReduction;
        pdfDoc.style.height = height+'px';
      },
      onPageClicked: function(page) {
        this.selectedPages = this.selectedPages.length > 0 && this.selectedPages[0] == page
          ? []
          : [page];
      },
      onUpdateDrawing: function(drawing) {
        let localDrawings = this.drawings.slice();
        let index = _.findIndex(localDrawings, d => { return d.page_number == drawing.page_number; });
        if (index < 0) {
          localDrawings.push(drawing);
        } else {
          localDrawings[index] = drawing;
        }
        this.drawings = localDrawings;
      },
      onUseSelected: async function() {
        this.cancelAfter = CANCEL_AFTER;
        this.drawingsToLoad = _.filter(this.drawings, d => {
          return this.selectedPages.includes(d.page_number);
        });
        if (this.selectedPages.length > 0) {
          let loader = await PdfImageGenerator(this.selectedPdfFile);
            let image = {blob: null};
            try {
              image = await loader.makeJpegImage(this.selectedPages[0], 0);
            } catch {}
          if (image.blob == null) {
            this.$emit("error", "Unexpected error loading selected PDF page.  Please wait for the pages to complete drawing before selecting one...");
          } else {
            this.$emit("saved_image_blob", image.blob);
          }
        }
        this.cancelAfter = -1;
        this.drawingsToLoad = [];
      },
    }
  }
</script>
<style scoped>
  #pdf_document {
   overflow: auto;
   margin-left: 8px;
  }
  #pdf-overlay {
    --border-radius: 6px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    cursor: pointer;
  }
  #pdf-overlay-content {
    padding: 8px;
  }
  #pdf-selector {
    position: absolute;
    top: 4%;
    left: 8%;
    width: 87%;
    background-color: rgba(255,255,255,1);
    border-radius: var(--border-radius);
  }
  .btn {
    margin: 8px;
    float: right;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:#EFEFEF;
    margin-bottom: 8px;
    border: 1px solid #CDCDCD;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  .title > * {
    margin-left: 12px;
    margin-right: 12px;
  }
  #loading_progress_bar {
    width: 0%;
    height: 2px;
    background-color: #0099FF;
  }
</style>
