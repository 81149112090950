<template>
  <div class="list-editor">
    <div class="widget-box span8">
      <div class="widget-title">
        <h5>Drawings - {{ listLabel }}</h5>
        <span title="Show Drawings not in use and reorder list." class="show-item-list">
        <input type="checkbox" id="check_show_unused" class="choice-checkbox" v-model="showUnused"/>
        <label for="check_show_unused">Show hidden</label>
        </span>
      </div>
      <div class="item-buttons">
        <input type="text" class="form-control-left filter-text" v-on:keydown.enter.prevent="filterDown()" v-model="filterText" placeholder="Filter entries" />
        <div class="btn-group">
          <button class="btn" v-on:click.prevent="sortAscending" title="Sort Ascending">A-Z</button>
          <button class="btn" v-on:click.prevent="sortDescending" title="Sort Descending">Z-A</button>
        </div>
        <div class="btn-group">
          <button class="btn" v-on:click.prevent="addAll" title="This will show all drawings and use all drawing">Show all Drawings</button>
          <button class="btn" v-on:click.prevent="removeAll" title="This will hide all drawings and make the Drawing Set empty">Hide all Drawings</button>
        </div>
      </div>
      <div class="widget-content">
        <div id="list-item" ref="listItem">
            <draggable 
              class="list-group"
              tag="ul"
              v-model="options"
              v-bind="dragOptions"
              handle=".handle"
              >
              <li class="list-group-item" v-bind:class="{'hidden': isHidden(element), unused: !element.use }" v-for="(element, idx) in options" :key="element.key">
                <div>
                  <div>
                    <i class="material-icons annotation-unstyled handle">dehaze</i>
                    <span class="text">{{ element.value }} </span>
                    <input type="checkbox" class="form-control" v-model="element.use" v-on:change="emitData" :title="useListCaption(element.use)"/>
                    <br>
                  </div>
                </div>
              </li>
            </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import _ from 'lodash'
  export default {
      components: {
        draggable
      },
      props: {
      list: {
        type: Object,
        default: function () { return []; }
      },
      listLabel: String
    },
    data: function () {
      let initialList = _.cloneDeep(this.list);

      return {
        showUnused: false,
        drag: false,
        listInUse: initialList,
        filterText: "",
        filterOn: false
      }
    },
    watch: {
      filterText: _.debounce(function (newValue) {
          this.setNewFilter(newValue)
      },150)
    },
    computed: {
      options: {
        get() {
          return this.listInUse;
        },
        set(value) {
          this.listInUse = value;
          this.emitData();
        }
      },
      dragOptions: function() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      }
    },
    methods: {
      sortAscending: function () {
        let vm = this;
        vm.listInUse = vm.sortedListInUse();
        vm.emitData();
      },
      sortDescending: function () {
        let vm = this;
        vm.listInUse = _.reverse(vm.sortedListInUse());
        vm.emitData();
      },
      sortedListInUse: function () {
        let vm = this;
        return _.sortBy(vm.listInUse, function(o) { return _.toLower(o.value) });
      },
      setNewFilter: function (newValue) {
        this.filterOn = !!newValue;
      },
      changeAll: function(changeFunc) {
        _.forEach(this.listInUse, changeFunc);
        this.emitData();
      },
      addAll: function() {
        this.changeAll(function(o) {
          o.use = true;
        });
      },
      removeAll: function() {
        this.changeAll(function(o) {
          o.use = false;
        });
      },
      emitData: function () {
        this.$emit('update:list', this.listInUse);
      },
      isHidden: function (element) {
        return !(this.foundFilter(element) && (this.showUnused || element.use));
      },
      foundFilter: function (element) {
        if (this.filterOn) {
          let vm = this;
          return _.includes(_.toUpper(element.value), _.toUpper(_.trim(vm.filterText)));
        } else {
          return true
        }
      },
      useListCaption: function (useListItem) {
        return useListItem ? "Untick to hide this value." : "Tick to use this value.";
      },
      nextKey: function () {
        let vm = this;
        return (_.max(_.map(vm.listInUse, function (thisItem) { return _.isNaN(parseInt(thisItem.key)) ? 0 : parseInt(thisItem.key) })) + 1).toString() ;
      },
      removeAt(idx) {
        this.listInUse.items.splice(idx, 1);
        this.emitData()
      },      
      useItem: function (thisItem) {
       this.listInUse.push(thisItem);
       this.emitData()
      },
      scrollItemList: function () {
        let container = this.$refs.listItem;
        if (!_.isNil(container)) {
          container.scrollTop = container.scrollHeight;
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .currentlist {
    background-color:#FFFF00 !important;
  }
  #list-item {
    border: #cdcdcd 1px solid;
    min-height: 200px;
    max-height: 400px;
    overflow-y: scroll;
  }
  .list-text { 
    padding-left: 4px;
    cursor: pointer;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group {
    min-height: 20px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .list-group-item {
    cursor: pointer;
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #eee;
    border: 1px solid rgba(0,0,0,.125);
    overflow: auto;

    &.unused {
      opacity: 0.5;
    }

    &.hidden {
      display: none;
    }
  }
  .list-group-item i {
    cursor: move;
  }
  .handle {
    float: left;
    padding-top: 4px;
    padding-bottom: 8px;
    margin-right: 8px
  }
  .trash-entry {
    margin-top: 0px;
    float: right;
    font-size: 20px;
  }
  .form-control {
    margin-top: 0px;
    float: right;
  }
  .form-control-left {
    margin-top: 0px;
    float: left;
  }
  .show-item-list {
    list-style-type: none;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 7px;
    margin-right: 5px;
    float: right;
  }
  .show-item-list label {
    display: inline-block;
  }
  .choice-checkbox {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .item-buttons {
    text-align: right;
    margin-top: 6px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .alternate-text {
    max-width: 100%;
    min-width: 100%;
    float: none;
    resize: vertical;
    min-height: 22px;
  }
  .use-alternate {
    float: left;
    margin-top: 5px;
  }
  .use-alternate-checkbox {
    margin-top: 7px;
  }
  .filter-text {
    float: left;
    width: 200px !important;
  }
  .filter-text-label {
    float: left;
    margin-top: 5px;
    margin-left: 12px;
    white-space: pre;
  }
  .small-alert {
    margin-bottom: 5px;
  }

</style>