import _ from 'lodash'


const type = 'selection';

const SelectionFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);
  let options = _.isEmpty(spec.options) ? []
    : _.map(spec.options, o => { return { key: o[0], value: o[1] }; });

  function initialize(lists, existingSelections) {
    if (_.isEmpty(options)) {
      if (spec.source && lists && !_.isEmpty(lists[spec.source])) {
        options = _.chain(lists[spec.source].items)
          .filter(i => i.use)
          .map(i => { return { key: i.key, value: i.value } })
          .value();
      }
    }

    if (_.isArray(existingSelections)) {
      let keys = _.map(options, _.property('key'));
      _.forEach(existingSelections, function(dataItem) { 
        if (!_.includes(keys, dataItem.key)) {
          options.unshift(dataItem);
        }
      });
    }

    return this; // for chaining from create
  }

  function getOptions() {
    return _.map(options, o => { return {key: o.key, value: o.value}})
  }

  function checkCriteria(values) {
    let messages = [];
    let isValid = true;

    if (options.length > 0) {
      let min = Math.min(options.length, spec.minimum_selections ?? 0);
      let max = spec.maximum_selections ?? 0;
      let hasMin = min > 0;
      let hasMax = max > 0;

      if (hasMin || hasMax) {
        let selections = parse(values).data?.selections ?? []
        let minMet = !hasMin || (selections.length >= min);
        let maxMet = !hasMax || (selections.length <= max);
        
        isValid = minMet && (maxMet || !spec.multiple);

        if (!isValid) {
          let message = "You must select "
            + (!spec.multiple ? "an item"
              : hasMin && hasMax 
                ? (min == max ? items(min)
                  : "between " + _.toString(min) + " and " + items(max))
              : hasMax ? "no more than " + items(max)
              : "at least " + items(min));
          messages.push(message);
        }
      }
    }

    return {
      isValid: isValid,
      messages: isValid ? [] : messages,
    }

    function items(val) {
      return _.toString(val) + (val === 1 ? " item" : " items");
    }
  }

  function parse(values) {
    values = values ? _.castArray(values).map(v => v.trim()) : [];
    let selections = [];

    if (values.length > 0) {
      // assume its keys
      selections = _.filter(options, o => _.includes(values, o.key));

      if (selections.length == 0) {
        // might have been passed value strings instead
        selections = _.filter(options, o => _.includes(values, o.value));
      }
      if (selections.length == 0) {
        // might have been passed key-value pair objects instead
        selections = _.filter(options, o => _.find(values, v => (typeof v === 'object') && ('key' in v) && v.key == o.key));
      }
    }

    let output = null;
    let messages = [];
    if (!spec.multiple && selections.length > 1) {
      messages.push("Selections would overflow the field");
      selections = [selections[0]];
    }
    output = {
      type: type,
      selections: selections,
    };
    return {
      data: output,
      messages: messages,
    };
  }

  return {
    type,
    reference: spec.reference,
    initialize,
    getOptions: getOptions,
    parse: parse,
    checkCriteria: checkCriteria,
  }
}


export const SelectionField = {
  type,
  create: (specification) => SelectionFieldImpl(specification),
}