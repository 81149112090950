import _ from 'lodash'

const type = 'integer';
const IntegerFieldImpl = (specification) => {

  const spec = _.cloneDeep(specification);

  function checkCriteria(value) {
    let messages = [];
    let isValid = true;

    let hasMin = !!spec.minimum_value;
    let hasMax = !!spec.maximum_value;
    if (hasMin || hasMax) {
      let parsed = parse(value);
      if (!_.isNil(parsed.data?.value)) {
        let val = parsed.data?.value;
        let minMet = !hasMin || (val !== Number.NEGATIVE_INFINITY && val >= _.toNumber(spec.minimum_value));
        let maxMet = !hasMax || (val !== Number.POSITIVE_INFINITY && val <= _.toNumber(spec.maximum_value));
        isValid = minMet && maxMet;
        if (!isValid) {
          let message = "Value must be "
            + (hasMin ? "greater than or equal to " + spec.minimum_value + (hasMax ? " and " : "") : "")
            + (hasMax ? "less than or equal to " + spec.maximum_value : "");
          messages.push(message);
        }
      } else if (parsed.messages.length > 0) {
        isValid = false;
        messages.push(parsed.messages[0])
      }
    }
    return {
      isValid: isValid,
      messages: isValid ? [] : messages,
    }
  };
  
  function parse(value) {
    let output = null;
    let messages = [];
    let isEmpty = false;
  
    let newInt = NaN;
    if (typeof value === 'string' || value instanceof String) {
      let matches = value?.match(/^\s*([+|-]?\d+)\s*$/);
      if (_.isArray(matches)) {
        newInt = parseInt(matches[1], 10);
      } else {
        let val = value.trim();
        isEmpty = _.isEmpty(val) || val == '+' || val == '-';
      }
    } else {
      newInt = parseInt(value, 10);
      isEmpty = _.isNil(value);
    }
    if (!_.isNaN(newInt)) {
      if (Number.isSafeInteger(newInt)) {
        output = {
          type: type,
          value: newInt,
        };
      } else {
        messages.push("Integer number is too big for the field");
      }
    } else if (!isEmpty) {
      messages.push("Value must be an integer");
    }
  
    return {
      data: output,
      messages: messages,
    };
  };
  
  return {
    type,
    reference: spec.reference,
    parse: parse,
    checkCriteria: checkCriteria,
  }
}

export const IntegerField = {
  type,
  create: (specification) => IntegerFieldImpl(specification),
}