import * as shapeBase from './shape'
import _ from 'lodash'


export const defaultTextScale = 50;


const HIGHLIGHT_COLOR = '#00FF00';
const HIGHLIGHT_ALPHA = 0.6;
const HIGHLIGHT_WIDTH_FACTORIAL = 3.8;

const ORIGIN_ID = 0;
const HEAD_ID = 1;

const utils = shapeBase.Utils;


function calculateMargin(isCanvasContext, text_height) {
  let minMargin = isCanvasContext ? 2 : 0.125;
  return Math.max(minMargin, text_height * 0.1);
}

function calculateTextHeight(textScale, backgroundNaturalHeight, canvasActualHeight, isBigPicture) {
  let max = backgroundNaturalHeight * (isBigPicture ? 0.05 : 0.1);
  let min = isBigPicture ? 5 : 15;
  let scale = textScale ? textScale : defaultTextScale;
  let zoomFactor = canvasActualHeight / backgroundNaturalHeight;
  let result = ((max - min) * scale / 100 + min) * zoomFactor;
  return result;
}

function getTextLines(text) {
  return text.split('\n');
}

function measureHeadHeight(text_height, text_lines, margin) {
  return text_height * text_lines.length 
    + 2 * margin;
}

function measureHeadWidth(ctx, text_lines, margin) {
  let l = 0;
  let last = text_lines.length - 1;
  for (let i = 0; i <= last; i++) {
    l = Math.max(l, ctx.measureText(text_lines[i]) + 2 * margin)
  }
  return l;
}

function getFirstLineYCenter(end, text_lines, text_height, margin) {
  return end.y - (text_lines.length - 1) * text_height / 2 + margin / 2;
}
function getLineYOffset(index, text_height) {
  return index * text_height;
}



function BalloonBase(annotation_data) {

  var ctx;
  var viewOffset;
  var overallSize;

  var forBigPicture;
  var pointer;

  var start = { x: 0, y: 0 };
  var end = { x: 0, y: 0 };

  function getOrigin() { return { x: start.x, y: start.y, data: annotation_data.origin }; };
  function getEnd() { return { x: end.x, y: end.y, data: annotation_data.end_point }; };

  var margin;
  var borderWidth;

  function getMargin() { return margin; };

  var head_h;
  var head_w;

  function getHeadWidth() { return head_w; }
  function getHeadHeight() { return head_h; }

  var text_lines;
  var text_height;

  function getTextHeight() { return text_height; };


  var head_border_color;
  var pointer_border_color;
  var fill_color;
  var text_color;


  function arrowPointer() {

    var head_tip;
    var head_end_1;
    var head_end_2;
    var base;

    var line_width;

    function recalculate(layoutParams, drawingOptions, isCanvasContext) {
      let overallSize = layoutParams.overallSize();
      line_width = isCanvasContext ? margin
        : Math.max(0.08375, margin * 0.375) * 2;

      let start_x = start.x;
      let start_y = start.y;
      let end_x = end.x;
      let end_y = end.y;

      let head_length = !drawingOptions.drawHeadlessArrows ? arrowHeadLength(overallSize, text_height, isCanvasContext)
          : isCanvasContext ? 1 : 0;

      let dx = (start_x - end_x);
      let dy = (start_y - end_y);
      let line_length = Math.sqrt( dx **2 + dy **2);

      
      let origin_shift = isCanvasContext || !drawingOptions.drawHeadlessArrows 
        ? 1.4142 * line_width / 2 // assuming the head forms a right-angle
        : 0; // TODO: fix this for radius instead of point for headless pdf arrows
      let change_scale = origin_shift / line_length;

      // recalculate these
      start_x = start_x + (end_x - start_x) * change_scale;
      start_y = start_y + (end_y - start_y) * change_scale;
      dx = start_x - end_x;
      dy = start_y - end_y;
      line_length = line_length - origin_shift;

      dx = (dx / line_length) * head_length;
      dy = (dy / line_length) * head_length;

      head_tip = {x: start_x, y: start_y};
      head_end_1 = {x: start_x - dx - dy, y: start_y + dx - dy};
      head_end_2 = {x: start_x - dx + dy, y: start_y - dx - dy};

      base = {x: end_x, y: end_y};
  
      function arrowHeadLength (overallSize, textHeight, isCanvasContext) {
        let defValue = 5;
        let min = isCanvasContext ? 2 : 0.5;
        let maxFactor = 0.02;
        let divisor = isCanvasContext ? 2 : 4;
        return _.isNil(overallSize) ? defValue
        : _.clamp(textHeight / divisor, min, maxFactor * overallSize.height);
      };
    }

    function draw (ctx, drawingOptions) {
      let lw = line_width;
      if (drawingOptions.drawBorder) {
        drawArrow(ctx, lw, 1, pointer_border_color);
        lw = line_width / 2;
      }
      drawArrow(ctx, lw, 1, fill_color);
    }
    function highlight(ctx) {
      drawArrow(ctx, line_width * 2, HIGHLIGHT_ALPHA * 0.6, HIGHLIGHT_COLOR);
    }
    function drawArrow(ctx, line_width, alpha, color) {
      ctx.setStrokeColor(color);
      ctx.setGlobalAlpha(alpha);
      ctx.setStrokeWidth(line_width);

      // Arrow body
      ctx.setLineCap('round')
      ctx.beginPath();

      ctx.moveTo(head_tip.x, head_tip.y);
      ctx.lineTo(base.x, base.y);
      ctx.stroke();

      // arrow head
      ctx.setLineJoin('miter')
      ctx.setLineCap('square')
      ctx.beginPath();
  
      ctx.moveTo(head_end_1.x, head_end_1.y);
      ctx.lineTo(head_tip.x, head_tip.y);
      ctx.lineTo(head_end_2.x, head_end_2.y);

      ctx.stroke();
    };

    function isHere (x, y) {
      return utils.isOnPoint(x, y, head_tip, line_width)
      // || utils.isOnPoint(x, y, base, line_width)
      || utils.isOnLine(x, y, head_tip, base, line_width)
      || utils.isOnPoint(x, y, head_end_1, line_width)
      || utils.isOnPoint(x, y, head_end_2, line_width)
      || utils.isOnLine(x, y, head_tip, head_end_1, line_width)
      || utils.isOnLine(x, y, head_tip, head_end_2, line_width);
    }
    return {
      recalculate: recalculate,
      isHere: isHere,
      draw: draw,
      highlight: highlight,
    }
  }
  function trianglePointer() {

    var pointer_tip;
    var pointer_end_1;
    var pointer_end_2;

    var base_point;
  
    function recalculate(layoutParams, drawingOptions) {
      let dx = end.x - start.x;
      let dy = end.y - start.y;
  
      let length = Math.sqrt(dx **2 + dy **2);
      let base_width = text_height * 0.6;
  
      let dxu = (dy * base_width) / ( 2 * length);
      let dyu = (dx * base_width) / ( 2 * length);
  
      pointer_tip = {x: start.x, y: start.y};
      pointer_end_1 = {x: end.x + dxu, y: end.y - dyu};
      pointer_end_2 = {x: end.x - dxu, y: end.y + dyu};

      base_point = {x: end.x, y: end.y};
    }

    function highlight(ctx) {
      ctx.setStrokeColor(HIGHLIGHT_COLOR);
      ctx.setGlobalAlpha(HIGHLIGHT_ALPHA);
      ctx.setStrokeWidth(HIGHLIGHT_WIDTH_FACTORIAL * borderWidth);
      ctx.setLineJoin('round')
      ctx.setLineCap('round')

      ctx.beginPath();
      ctx.moveTo(pointer_end_1.x, pointer_end_1.y);
      ctx.lineTo(pointer_tip.x, pointer_tip.y);
      ctx.lineTo(pointer_end_2.x, pointer_end_2.y);
      ctx.stroke();
    }

    function draw (ctx, drawingOptions) {
      ctx.setStrokeColor(pointer_border_color);
      ctx.setFillColor(annotation_data.fill_color);
      ctx.setGlobalAlpha(1);
      ctx.setStrokeWidth(borderWidth);
      ctx.setLineJoin('round')
      ctx.setLineCap('round')

      ctx.beginPath();
      ctx.moveTo(pointer_tip.x, pointer_tip.y);
      ctx.lineTo(pointer_end_1.x, pointer_end_1.y);
      ctx.lineTo(pointer_end_2.x, pointer_end_2.y);
      ctx.closePath();
      ctx.stroke();
      ctx.fill();  
    };

    function isHere (x, y) {
      let result = utils.isBetweenPoints(x, y, pointer_tip, base_point)
      && isInsideTriangle(pointer_tip, pointer_end_1, pointer_end_2, x, y);
      return result;

      function isInsideTriangle(p1, p2, p3, x, y) {

        let check_point = {x: x, y: y};
        // get total area
        let A = area(p1, p2, p3);

        // Get area of the smaller triangles
        let A1 = area(check_point, p2, p3); // sub triangle 1
        let A2 = area(p1, check_point, p3); // sub triangle 2
        let A3 = area(p1, p2, check_point); // sub triangle 3

        return ((A1 + A2 + A3) < 1.1*A);

        function area(p1, p2, p3) {
          return Math.abs(((p1.x * (p2.y-p3.y)) + (p2.x*(p3.y-p1.y)) + (p3.x*(p1.y-p2.y)))/2.0);
        };
      }
    };
    return {
      recalculate: recalculate,
      isHere: isHere,
      draw: draw,
      highlight: highlight,
    }
  }



  function initDraw(drawCtx, layoutParams, drawingOptions, isForBigPicture) {
    forBigPicture = isForBigPicture;
    ctx = drawCtx;
    viewOffset = layoutParams.viewOffset();
    overallSize = layoutParams.overallSize();
    let balloonOptions = drawingOptions.balloonOptions
    pointer = forBigPicture
      ? arrowPointer()
      : trianglePointer();

    start.x = annotation_data.origin.x * overallSize.width - viewOffset.x;
    start.y = annotation_data.origin.y * overallSize.height - viewOffset.y;
    end.x = annotation_data.end_point.x * overallSize.width - viewOffset.x;
    end.y = annotation_data.end_point.y * overallSize.height - viewOffset.y;
  
    text_lines = getTextLines(annotation_data.full_text ?? annotation_data.text);

    text_height = calculateTextHeight(balloonOptions.textScale, drawingOptions.backgroundNaturalHeight, overallSize.height, forBigPicture);
    margin = calculateMargin(ctx.isCanvasContext, text_height);
    borderWidth = forBigPicture ? Math.max(0.25, margin / 2)
      : calculateBorderWidth(drawingOptions.backgroundNaturalHeight, overallSize.height);
  
    ctx.configureFont(text_height); // do this before measuring the head width
    head_w = measureHeadWidth(ctx, text_lines, margin);
    head_h = measureHeadHeight(text_height, text_lines, margin);

    pointer.recalculate(layoutParams, balloonOptions, ctx.isCanvasContext);

    head_border_color = annotation_data.fill_color == '#000000' ? '#FFFFFF' : '#000000';
    pointer_border_color = annotation_data.fill_color == '#000000' ? '#FFFFFF' : '#000000';
  
    fill_color = forBigPicture
        && balloonOptions.fillColorOverride
      ? balloonOptions.fillColorOverride
      : annotation_data.fill_color;
    text_color = forBigPicture
        && balloonOptions.textColorOverride
      ? balloonOptions.textColorOverride
      : annotation_data.text_color;


    function calculateBorderWidth(backgroundNaturalHeight, canvasActualHeight) {
      let zoomFactor = canvasActualHeight / backgroundNaturalHeight;
      let result = backgroundNaturalHeight * zoomFactor * 0.00375;
      return result;
    }
  }

  function draw(selectedIndices, drawingOptions) {
    if (!ctx) return; // must call initDraw first
    drawingOptions = drawingOptions.balloonOptions;

    if (selectedIndices.length > 0) {
      highlight();
    }

    if (drawingOptions.drawBorder) {
      drawHeadBorder(borderWidth, head_border_color, 1);
    }
    pointer.draw(ctx, drawingOptions);
    fillHead();
    writeText();

    function highlight() {
      if (forBigPicture) {
        let width = text_height;
        drawHeadBorder(width, '#FFFF00', 0.8);
        ctx.setStrokeColor('#FFFF00');
        ctx.setGlobalAlpha(0.6);
        ctx.setStrokeWidth((width + 2) / 2);
        ctx.setLineCap('round')
  
        ctx.beginPath();
  
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();
      } else {
        let headSelected = _.includes(selectedIndices, HEAD_ID);
        let pointerSelected = _.includes(selectedIndices, ORIGIN_ID);
        if (headSelected) {
          drawHeadBorder(borderWidth * HIGHLIGHT_WIDTH_FACTORIAL, HIGHLIGHT_COLOR, HIGHLIGHT_ALPHA);
        } 
        if (pointerSelected) {
          pointer.highlight(ctx);
        }
      }
    }
    function drawHeadBorder(line_width, border_color, border_alpha) {
      ctx.setStrokeColor(border_color);
      ctx.setGlobalAlpha(border_alpha);
      ctx.setStrokeWidth(line_width);
      ctx.setLineJoin('round')
      ctx.setLineCap('round')

      ctx.beginPath();
 
      let width_offset = head_w / 2;
      let height_offset = head_h / 2;
      ctx.moveTo(end.x - width_offset, end.y - height_offset);
      ctx.lineTo(end.x + width_offset, end.y - height_offset);
      ctx.lineTo(end.x + width_offset, end.y + height_offset);
      ctx.lineTo(end.x - width_offset, end.y + height_offset);
      ctx.closePath();
 
      ctx.stroke();
    }
    function fillHead() {
      ctx.setGlobalAlpha(1);
      ctx.setFillColor(fill_color);
      ctx.fillRect(end.x - (head_w / 2), end.y - (head_h / 2), head_w, head_h)
    };
    function writeText() {
      ctx.configureFont(text_height);
      ctx.setTextColor(text_color);

      let x = end.x - head_w / 2 + margin;
      let yFirst = getFirstLineYCenter(end, text_lines, text_height, margin);
      let last = text_lines.length - 1;
      for (let i = 0; i <= last; i++) {
        let y = yFirst + getLineYOffset(i, text_height);
        ctx.writeText(text_lines[i], x, y, {align: 'left', baseline: 'middle'})
      }
    }
  }

  function isHeadHere(x, y) {
    let vertical = ((end.y - head_h / 2) < y) && ((end.y + head_h / 2) > y);
    let horizontal = ((end.x - head_w / 2) < x) && ((end.x + head_w / 2) > x);
    return vertical && horizontal;
  }
  function isPointerHere(x, y) { return pointer?.isHere(x, y); }

  return {
    initDraw: initDraw,
    draw: draw,
    isHeadHere: isHeadHere,
    isPointerHere: isPointerHere,

    getOrigin: getOrigin,
    getEnd: getEnd,

    getMargin: getMargin,
    getHeadWidth: getHeadWidth,
    getHeadHeight: getHeadHeight,

    getTextHeight: getTextHeight,
  };
}


function InternalBalloon(base, annotation) {
  var shape = shapeBase.Shape(annotation);

  var foundPoints = [];
  var selectedPoints = [];
  var currentX = -1;
  var currentY = -1
    
  function findPoints(x, y) {
    let selected = [];
    if (base.isHeadHere(x, y)) {
      selected.push(HEAD_ID);
    } else if (base.isPointerHere(x, y)) {
      selected.push(ORIGIN_ID);
    }
    return selected;
  }
  function allSelectablePoints() {
    return [ORIGIN_ID, HEAD_ID];
  }

  
  shape.draw = function(drawingOptions, highlight) {
    let points = !highlight 
        ? []
        : shape.isSelected() ? selectedPoints
        : allSelectablePoints();
    base.draw(points, drawingOptions);
  }
  shape.selectHere = function(x, y) {
    if (currentX != x || currentY != y) {
      shape.isHere(x, y);
    }
    selectedPoints = foundPoints;  
  }
  shape.selectAll = function() {
    selectedPoints = allSelectablePoints();
  }
  shape.unselect = function() {
    selectedPoints = [];
  }
  shape.isSelected = function() {
    return selectedPoints.length > 0;
  }

  shape.isHere = function(x, y) {
    currentX = x;
    currentY = y;
    foundPoints = findPoints(x, y);
    return foundPoints && foundPoints.length > 0;
  }

  shape.move = function(offsetX, offsetY, layoutParams) {
    let viewOffset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();

    let selected = [];
    if (_.includes(selectedPoints, ORIGIN_ID)) selected.push(base.getOrigin());
    if (_.includes(selectedPoints, HEAD_ID)) selected.push(base.getEnd());

    return utils.move(offsetX + viewOffset.x, offsetY + viewOffset.y, selected, overallSize);
  }

  // remaining functions are specific to balloons - used in the Big Picture
  shape.moveHead = function(offsetX, offsetY, layoutParams) {
    let end = base.getEnd();
    return shape.positionHead(end.x + offsetX, end.y + offsetY, layoutParams);
  }
  shape.positionHead = function (x, y, layoutParams) {
    let end = base.getEnd();
    let viewOffset = layoutParams.viewOffset();
    let overallSize = layoutParams.overallSize();
    let new_x = x + viewOffset.x;
    let new_y = y + viewOffset.y;
    if ((new_x >= 0 && new_x <= overallSize.width)
        && (new_y >= 0 && new_y <= overallSize.height)) {
      end.x = new_x;
      end.y = new_y;
      end.data.x = (new_x / overallSize.width).toFixed(8);
      end.data.y = (new_y / overallSize.height).toFixed(8);
      return true;
    }
    return false;
  }
  shape.canMoveHeadX = function(offsetX, layoutParams) {
    let end = base.getEnd();
    let overallSize = layoutParams.overallSize();
    let viewOffset = layoutParams.viewOffset();
    let new_x = end.x + offsetX + viewOffset.x;
    let result = new_x >= 0 && new_x <= overallSize.width;
    return result;
  }
  shape.canMoveHeadY = function(offsetY, layoutParams) {
    let end = base.getEnd();
    let overallSize = layoutParams.overallSize();
    let viewOffset = layoutParams.viewOffset();
    let new_y = end.y + offsetY + viewOffset.y;
    let result = new_y >= 0 && new_y <= overallSize.height;
    return result;
  }

 
  shape.measureTextHeight = function(textScale, backgroundNaturalHeight, canvasActualHeight, isBigPicture) {
    return calculateTextHeight(textScale, backgroundNaturalHeight, canvasActualHeight, isBigPicture);
  }

  shape.measureHeadWidth = function(context, text_height, full_text) {
    const ctx = shapeBase.DrawingContext(context);
    let margin = calculateMargin(ctx.isCanvasContext, text_height);
    ctx.configureFont(text_height); // do this before measuring the head width
    let width = measureHeadWidth(ctx, getTextLines(full_text), margin);
    return width;
  }

  shape.measureHeadHeight = function(context, text_height, full_text) {
    const ctx = shapeBase.DrawingContext(context);
    let margin = calculateMargin(ctx.isCanvasContext, text_height);
    let height = measureHeadHeight(text_height, getTextLines(full_text), margin);
    return height;
  }

  return shape;
}



export const Balloon = (annotation, immutable) => {
  var annotation_data = immutable ? _.cloneDeep(annotation.annotation_data) : annotation.annotation_data;
  const base = BalloonBase(annotation_data);
  var balloon = InternalBalloon(base, annotation);

  // combine the init and the draw for public consumption right now
  let balloonDraw = balloon.draw;
  balloon.draw = function(context, layoutParams, drawingOptions, highlight) {
    let ctx = shapeBase.DrawingContext(context);
    base.initDraw(ctx, layoutParams, drawingOptions, drawingOptions.balloonOptions.drawBigPicturePointers);
    balloonDraw(drawingOptions, highlight);
  }

  balloon.getAnnotationData = function() {
    return _.cloneDeep(annotation_data);
  }

  return balloon;
}


export const MergeBalloon = (annotation) => {
  const balloons = [...annotation.text_balloons];

  const text_lines = balloons.map(a => a.annotation_data.full_text);
  const text = text_lines.join('\n');
  const first = balloons[0].annotation_data;

  const extremties = getTwoExtremities();
  const originX = extremties.b.x + (extremties.a.x - extremties.b.x) / 2;
  const originY = extremties.b.y + (extremties.a.y - extremties.b.y) / 2;

  const annotation_data = {
    origin: {x: originX, y: originY},
    end_point: {x: first.end_point.x, y: first.end_point.y},
    text: text,
    full_text: text,
    text_color: first.text_color,
    fill_color: first.fill_color,
  };

  // set this so it looks like a proper shape
  annotation.annotation_data = _.cloneDeep(annotation_data);

  var initialised = false;
  
  const base = BalloonBase(annotation_data);
  const balloon = InternalBalloon(base, {
    uuid: annotation.uuid,
    annotation_type: annotation.annotation_type,
    // annotation_data: annotation_data,
  });

  balloon.getAnnotationData = function() {
    return _.cloneDeep(annotation_data);
  }

  let balloonDraw = balloon.draw;
  balloon.draw = function(context, layoutParams, drawingOptions, highlight) {
    let ctx = shapeBase.DrawingContext(context);
    base.initDraw(ctx, layoutParams, drawingOptions, drawingOptions.balloonOptions.drawBigPicturePointers);
    initialised = true;

    let overallSize = layoutParams.overallSize();
    let viewOffset = layoutParams.viewOffset();
    let origin = base.getOrigin();
    let ox = origin.x;
    let oy = origin.y;
    let rx = extremties.a.x * overallSize.width - viewOffset.x;
    let ry = extremties.a.y * overallSize.height - viewOffset.y;
    let radius = Math.sqrt((rx - ox)**2 + (ry - oy)**2);

    let line_width = ctx.isCanvasContext ? 1
      : Math.max(0.08375, base.getMargin() * 0.375) / 2; // NOTE similarity in size between this and arrow linewidth should be maintained

    ctx.setFillColor('#FF0000');
    ctx.setGlobalAlpha(0.1);
    ctx.fillCircle(ox, oy, radius);
    ctx.setStrokeColor('#FF0000');
    ctx.setGlobalAlpha(0.5);
    ctx.setStrokeWidth(line_width);
    ctx.strokeCircle(ox, oy, radius);
    balloonDraw(drawingOptions, highlight);
  }

  balloon.findHeadRow = function(x, y) {
    if (!initialised) return -1; // need to draw first!!!
    let rowIndex = getHeadLine(x, y);
    return rowIndex;
  }
  function getHeadLine(x, y) {
    let end = base.getEnd();
    let text_height = base.getTextHeight();
    let margin = base.getMargin();
    let head_w = base.getHeadWidth();
    let head_h = base.getHeadHeight();
    let horizontal = ((end.x - head_w / 2) < x) && ((end.x + head_w / 2) > x);
    if (horizontal) {
      let yCenterFirst = getFirstLineYCenter(end, text_lines, text_height, margin);
      let lines_length = text_lines.length;
      for (let i = text_lines.length - 1; i >= 0; i--) {
        let yCenter = yCenterFirst + getLineYOffset(i, text_height);
        let top = i === 0 ? end.y - head_h / 2
          : yCenter - text_height / 2;
        let bottom = i === lines_length - 1 ? end.y + head_h / 2
          : yCenter + text_height / 2;
        if (y >= top && y <= bottom) {
          return i;
        }
      }
    }
    return -1;
  }

  function getTwoExtremities() {
    // multiply by 1 to convert strings to numbers
    let points = balloons.map(b => ({x: b.annotation_data.origin.x * 1, y: b.annotation_data.origin.y * 1})
    );
    let dist = 0;
    let start;
    let end;
    let count = points.length;
    for (let i = 0; i < count; i++) {
      for (let j = i + 1; j < count; j++) {
        let a = points[i];
        let b = points[j];
        let l = Math.sqrt((a.x - b.x)**2 + (a.y - b.y)**2);
        if (l >= dist) {
          dist = l;
          start = a;
          end = b;
        }
      }
    }
    return {a: start, b: end};
  }

  return balloon;
}