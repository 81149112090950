<template>
  <div class="date-field control-group" v-if="isRow">
    <div class="controls" :title="label" v-show="isTableCellVisible">
      <input v-bind:name="formId" type="date" v-model="date" :disabled="isReadOnly" v-bind:class="[isValid ? '' : 'field-error', 'inspection-table-field', {dirty: isDirty}]" v-on:keydown.enter.prevent="">
    </div>
    <div class="controls" :title="label" v-show="!isTableCellVisible">
      &nbsp;
    </div>
  </div>
  <div class="date-field control-group" v-show="isVisible" v-else>
    <label v-bind:for="formId" class="control-label">{{ label }} <span v-if="isRequired">*</span></label>
    <div class="controls">
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="showCriteria || showMandatoryText">
        <span v-if="showCriteria">{{ criteriaText }}</span>
        <span v-if="showMandatoryText">This field is mandatory.</span>
      </p>
      <input v-bind:name="formId" type="date" v-model="date" :disabled="isReadOnly" v-on:keydown.enter.prevent="" :class="[{dirty: isDirty}]">
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldVisibilityMixin from '../../mixins/field_visibility_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'
  import { EventBus } from '../../lib/event_bus'
  import { DateField } from '../../lib/fields/_fields.js'

  export default {
    mixins: [fieldMixin, fieldLabelMixin, fieldVisibilityMixin, fieldValidationMixin],
    data: function () {
      let initialDate = ""

      if (!_.isNil(this.data)) {
        initialDate = _.join([
          this.data.year,
          _.padStart(this.data.month, 2, '0'),
          _.padStart(this.data.day, 2, '0')
        ], '-');
      }

      return {
        initialDate: initialDate,
        date: initialDate
      }
    },
    computed: {
      isPresent: function () {
        return !_.isEmpty(this.date)
      },
      formattedDate: function () {
        if (this.isPresent) {
          return (new Date(this.date)).toDateString()
        } else {
          return "";
        }
      },
      isDirty: function () {
        return !(_.isEqual(this.initialDate, this.date));
      }
    },
    watch: {
      date: function (newDate) {
        let val = DateField.create(this.specification).parse(newDate).data;
        this.currentValidity = this.assessValidity();
        this.$emit('update:data', val);
      }
    },
    created: function () {
      let vm = this;
      if (!!vm.underRollup){
        EventBus.$on(vm.underRollup, (eventData) => {
          vm.visibleUnderRollup = eventData['rollup'];
          let thisShowHide = eventData['groupShowHide'];
          if (!!thisShowHide) {
            vm.groupShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
      if (!!vm.controlledField){
        EventBus.$on(vm.controlEvent, (ShowHideData) => {
          let thisShowHide = ShowHideData[vm.reference];
          if (!!thisShowHide) {
            vm.controlShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
    }
  }
</script>

<style scoped lang="scss">
  .inspection-table-field {
    width: 95%;
    padding-right: 0px;
    margin-bottom: 0px;
  }
  .control-group {
    margin-bottom: 0px;  
  }
</style>