<template>
  <div v-show="capable" ref="dropzone" :class="dropzoneClasses" @click.stop="click">
    <span class="drop-files">{{label}}</span>
  </div>
</template>

<script>

  function determineDragAndDropCapable() {
    let div = document.createElement('div');
    return ( 'draggable' in div ) || ( 'ondragstart' in div && 'ondrop' in div );
  }
  export const dragAndDropCapable = determineDragAndDropCapable();

  export default {
    props: {
      label: String,
      clickFunction: Function,
    },
    data: function() {
      return {
        capable: dragAndDropCapable,
        dragEvent: false,
      }
    },
    computed: {
      clickable: function () {
        return !!this.clickFunction;
      },
      dropzoneClasses: function () {
        return ['drop-zone', this.dragEvent ? 'drag-over': '', this.clickable ? 'clickable' : ''];
      },
    },
    mounted() {
      if (this.capable) {
        /* Listen to all of the drag events and bind an event listener to each for the dropzone. */
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
          /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
          */
          this.$refs.dropzone.addEventListener(evt, function(e){
            e.preventDefault();
            e.stopPropagation();
          }.bind(this), false);
        }.bind(this));

        this.$refs.dropzone.addEventListener('drop', function(e){
          this.dragEvent = false;
          this.onFilesDragged(e.dataTransfer.files)
        }.bind(this));
        this.$refs.dropzone.addEventListener('dragover', function(e){
          this.dragEvent = true;
        }.bind(this));
        this.$refs.dropzone.addEventListener('dragenter', function(e){
          this.dragEvent = true;
        }.bind(this));
        this.$refs.dropzone.addEventListener('dragleave', function(e){
          this.dragEvent = false;
        }.bind(this));
        this.$refs.dropzone.addEventListener('dragend', function(e){
          this.dragEvent = false;
        }.bind(this));
      }
    },
    methods: {
      click: function () {
        if (this.clickFunction) {
          this.clickFunction();
        }
      },
      onFilesDragged: function (files) {
        this.$emit('files_selected', files);
      },
    }
  }
</script>

<style scoped>
  .drop-zone {
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    background-color: #d4edf7;
    min-width: 200px;
    padding: 10px;
    height: 100%;
    width: 100%;
  }
  .clickable:hover {
    cursor: pointer;
  }
  .drop-zone > * {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00719c;
    border: 1px dashed #00719c !important;
    border-radius: 4px;
    padding: 6px;
  }
  .drag-over, .clickable:hover {
    background-color: #eaf6fb;
  }
  .drag-over > *, .clickable:hover > * {
    border-style: solid !important;
  }
</style>