import generateUUID from 'uuid/v4'
import axios from 'axios'


export const AssetUploader = (successCallback, failCallback, progressCallback) => {

  var assetId;
  function setAssetId(newAssetId) {
    assetId = newAssetId;
  }

  function progress(message) {
    if (progressCallback) {
      progressCallback(message)
    }
  }
  function succeed(assetId) {
    if (successCallback) {
      successCallback(assetId);
    }
  }
  function fail(message) {
    if (failCallback) {
      failCallback(message);
    }
  }

  function uploadJpeg(assetBlob) {
    upload(assetBlob, '.jpg');
  }
  function uploadPng(assetBlob) {
    upload(assetBlob, '.png');
  }
  function upload(assetBlob, extension) {

    if (!assetBlob || !extension) {
      fail('No asset to upload!');
      return;
    }

    if (!assetId) {
      assetId = generateUUID();
    }

    // Call for upload token
    axios.get(tokenUrl(assetId)).then(response => {
        // make the FormData packet for the submission to S3
      const fdpost = new FormData();
      fdpost.append('utf8', '✓');
      fdpost.append('key',response.data.key.replace('${filename}',assetId + extension)); //subst name of file
      fdpost.append('AWSAccessKeyID',response.data.aws_access_key_id);
      fdpost.append('acl',response.data.acl);
      fdpost.append('success_action_status',response.data.success_action_status);
      fdpost.append('policy',response.data.policy);
      fdpost.append('signature',response.data.signature);
      fdpost.append('Content-Type',assetBlob.type);
      fdpost.append('file',assetBlob,assetId + extension)
      
      axios.post(response.data.url,fdpost, {
        onUploadProgress: uploadEvent => {
          progress('Upload Progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) +'%');
        }
      }).then(response => {
        // update record in Hub
        var oParser = new DOMParser;
        var oDom = oParser.parseFromString(response.data, "application/xml");
        var assetKey = oDom.getElementsByTagName("Key")[0].innerHTML;

        axios.get(updateAssetUrl(assetId), { params: { key: assetKey } })
        .then(() => {
          succeed(assetId);
        })
        .catch(error => {
          fail('Problem updating the Hub: ' + error.message);
        });
      })
      .catch(error => {
        fail('Problem storing the image: ' + error.message);
      })
    })
    .catch(error => {
      fail('Problem getting permission to upload: ' + error.message);
    });
  };

  function tokenUrl(assetId) {
    return assetUrl(assetId) + '/upload_token'
  };
  function updateAssetUrl(assetId) {
    return assetUrl(assetId) + '/update_asset'
  };
  function assetUrl(assetId) {
    return '/o/' + organisationId() + '/assets/' + (assetId)
  };
  function organisationId() {
    const organisationIdRegex = /^\/o\/(\d+)\//g;
    let matches = organisationIdRegex.exec(window.location.pathname)

    if (matches) {
      return matches[1]
    }
  };

  return {
    setAssetId: setAssetId,
    upload: upload,
    uploadPng: uploadPng,
    uploadJpeg: uploadJpeg,
  };
}
