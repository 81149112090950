<template>
  <div class="memberships-container">
    <div class="membership-collection-container">
      <div class="membership-collection-title" v-show="showMemberHeader">
        <h4 class="toggle" v-on:click="membershipsExpanded = !membershipsExpanded">
          <i :class="['icon', membershipsExpanded ? 'icon-chevron-down' : 'icon-chevron-right']" title="Click to toggle"></i>
          Registered Staff ({{membershipItems.length}})
        </h4>
        <div class="search-box">
          <span class="material-icons">search</span>
          <input type="text" v-model="membershipSearchValue" />
          <button class="btn-clear-search" v-on:click.prevent="membershipSearchValue = ''" title="Clear search">
            <span class="material-icons">close</span>
          </button>
        </div>
      </div>
      <div :class="['collapsible', 'membership-toggle-target', {expanded: membershipsExpanded}]" @transitionend="transitionend">
        <div class="membership-collection-content">
          <member-details v-for="membership in membershipItems" :key="membership.record.id"
            v-show="filteredMembershipIds.includes(membership.record.id)"
            :membership="membership.record"
            :readOnly="membership.read_only"
            :canDestroy="membership.can_destroy"
            :isCurrentMember="membership.is_current_member"
            :lastActiveLabel="membership.last_active_label"
            :roles="roles"
            @updated="val => onUpdate(membership.record.id, val)"
            @destroyed="onDestroy(membership.record.id)"
          />
        </div>
      </div>
    </div>
    <div class="membership-collection-container">
      <div class="membership-collection-title" v-show="showMonitorHeader">
        <h4 class="toggle" v-on:click="monitorsExpanded = !monitorsExpanded">
          <i :class="['icon', monitorsExpanded ? 'icon-chevron-down' : 'icon-chevron-right']" title="Click to toggle"></i>
          Monitors ({{monitorItems.length}})
        </h4>
        <div class="search-box">
          <span class="material-icons">search</span>
          <input type="text" v-model="monitorSearchValue" />
          <button class="btn-clear-search" v-on:click.prevent="monitorSearchValue = ''" title="Clear search">
            <span class="material-icons">close</span>
          </button>
        </div>
      </div>
      <div :class="['collapsible', 'monitor-toggle-target', {expanded: monitorsExpanded}]" @transitionend="transitionend">
        <div class="membership-collection-content">
          <member-details v-for="membership in monitorItems" :key="membership.record.id"
            v-show="filteredMonitorIds.includes(membership.record.id)"
            :membership="membership.record"
            :readOnly="membership.read_only"
            :canDestroy="membership.can_destroy"
            :isCurrentMember="membership.is_current_member"
            :lastActiveLabel="membership.last_active_label"
            :roles="roles"
            @destroyed="onDestroy(membership.record.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import memberDetails from './member_details.vue';

export default {
  components: {
    memberDetails,
  },
  props: {
    memberships: Array,
    monitors: Array,
    roles: Array,
  },
  data: function () {
    let membershipItems = _.clone(this.memberships);
    let monitorItems = _.clone(this.monitors);
    return {
      membershipItems: membershipItems,
      membershipSearchValue: '',
      filteredMembershipIds: this.applyFilter(membershipItems, ''),
      membershipsExpanded: true,

      monitorItems: monitorItems,
      monitorSearchValue: '',
      filteredMonitorIds: this.applyFilter(monitorItems, ''),
      monitorsExpanded: true,
    }
  },
  watch: {
    membershipSearchValue: _.debounce(function (newValue) {
        this.filteredMembershipIds = this.applyFilter(this.membershipItems, newValue.trim());
      }, 250),
    monitorSearchValue: _.debounce(function (newValue) {
        this.filteredMonitorIds = this.applyFilter(this.monitorItems, newValue.trim());
    }, 250),
    membershipsExpanded: function(val) {
      this.toggle('membership-toggle-target', val);
    },
    monitorsExpanded: function(val) {
      this.toggle('monitor-toggle-target', val);
    },
  },
  computed: {
    showMemberHeader: function () {
      return this.memberships?.length > 0;
    },
    showMonitorHeader: function () {
      return this.monitors?.length > 0;
    },
  },
  methods: {
    applyFilter(items, criteria) {
      let lowerCriteria = criteria.toLowerCase();
      let filtered = _.isEmpty(criteria) ? items
        : _.filter(items, m => m.record.full_name?.toLowerCase().includes(lowerCriteria)
            || m.record.email?.toLowerCase().includes(lowerCriteria));
      return filtered.map(m => m.record.id);
    },
    onUpdate: function (id, update) {
      let index = _.findIndex(this.membershipItems, i => i.record.id == id);
      if (index >= 0) {
        let current = this.membershipItems[index];
        let updated = _.clone(current);
        _.merge(updated.record, update);
        this.membershipItems.splice(index, 1, updated);
      }
    },
    onDestroy: function (id) {
      let index = _.findIndex(this.membershipItems, i => i.record.id == id);
      if (index >= 0) {
        this.membershipItems.splice(index, 1);
      } else {
        index = _.findIndex(this.monitors, i => i.record.id == id);
        if (index >= 0) {
          this.monitorItems.splice(index, 1);
        }
      }
    },
    toggle: function(targetClass, state) {
      _.forEach(document.getElementsByClassName(targetClass), el => {
        el.style.height=el.scrollHeight+'px';
        if (!state) {
          setTimeout(()=> {el.style.height='';}, 1); // timeout needed to start the collapse transition
        }
      });
    },
    transitionend: function(evt) {
      // remove the height setting to allow browser to dynamically update it for resize etc
      evt.target.style.height='';
    },
  }
}
</script>

<style scoped>

.memberships-container {
  --panel-width: 520px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.membership-collection-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: #ccc;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
}
.membership-collection-title .toggle {
  cursor: pointer;
}

.membership-collection-content {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 30px;
  padding-top: 30px;
}
.search-box {
  --radius: 8px;
  --primary-color: #777;
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-color);
  border-radius: var(--radius);
  padding-left: 2px;
  background-color: white;
  width: var(--panel-width);
}
.search-box input {
  border: 0 solid transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  flex-grow: 1;
  margin-bottom: 0;
}
.search-box span {
  color: var(--primary-color);
}
.search-box .btn-clear-search {
  --color: transparent;
  display: flex;
  align-items: center;
  border-radius: var(--radius);
  border-color: var(--color);
  background-color: var(--color);
  padding: 0;
}
.search-box .btn-clear-search span:hover {
  color: black;
}
.collapsible {
  height:0;
  overflow-y: hidden;
  transition: height 0.5s ease-in-out;
}
.expanded {
  height: auto;
}
</style>